<template>
  <div class="home">
    <div id="root">
      <div class="NYG3ffBi productBg B7NL5wM6">
        <div class="XDxYjHlu">
          <div class="fjiYtnUB1">
            <img alt="logo" class="qbUby_dV" @click="initData" src="@/assets/logo.png" style="cursor: pointer" />
          </div>
          <div class="DDl4VNjw">
            <ul class="ant-menu-overflow ant-menu ant-menu-root ant-menu-horizontal ant-menu-light" role="menu" tabindex="0" data-menu-list="true" style="border: 0px; background: transparent">
              <li class="ant-menu-overflow-item ant-menu-submenu ant-menu-submenu-horizontal" role="none" style="opacity: 1; order: 0; display: flex; flex-direction: row">
                <Dropdown @on-click="exitbtn">
                  <img class="avatar" src="@/assets/logoFav.png" alt="" />
                  <!-- <DropdownMenu slot="list">
                    <DropdownItem name="userinfo">{{ userInfo }}</DropdownItem>
                    <DropdownItem name="logout">退出登录</DropdownItem>
                  </DropdownMenu> -->
                </Dropdown>
              </li>
            </ul>
            <div aria-hidden="true" style="display: none"></div>
          </div>
        </div>
      </div>
      <Feedback ref="feedbackRef"></Feedback>

      <Row class="SyBUiXPj" id="chatContainer">
        <Col :class="getClass()">
          <Col class="defaultList" v-show="showDefault">
            <div class="defaultItem" v-for="(item, index) in defaultChatList" :key="index">
              <img class="defaultItemIcon" :src="item.icon" alt="" />
              <h3 style="text-align: center; margin-top: 40px">
                {{ item.title }}
              </h3>
              <div class="buttonGroup">
                <div class="defaultQBtn" v-for="qItem in item.questions" :key="qItem" @click="askQuestion(qItem)">
                  {{ qItem }}
                </div>
              </div>
            </div>
          </Col>

          <div class="footer-fixed-blur"></div>
          <div class="chatList chatInput" v-show="showDefault">
            <div style="position: relative">
              <Input class="searchInput" v-model="formData.chatInput" type="textarea" :rows="2" :autosize="{ minRows: 2, maxRows: 4 }" size="large" :maxlength="1024" placeholder="请输入问题" @keydown.enter.native="askZhiGong" :disabled="chatObject.timeLimit"> </Input>
              <div class="chatInputButton" @click="askZhiGong">
                <img class="chatInputButtonIcon" src="../../assets/send.png" alt="" />
              </div>
              <div class="chatInputButton1" @click="uploadModal = true">
                <img class="chatInputButtonIcon" src="../../assets/upload.png" alt="" />
              </div>
            </div>
          </div>
          <Col :class="this.showRight ? 'scrollContainer-full' : 'scrollContainer'" ref="scrollDiv" v-show="!showDefault">
            <div class="chatList" v-for="(cItem, cIndex) in currentListHistory" :key="cIndex">
              <Row class="chatList-row">
                <Col class="chatList-avatar"> <img class="avatar" src="../../assets/logoFav2.png" alt="" /></Col>
                <Col class="chatList-question">{{ cItem.question }}</Col>
              </Row>
              <Row class="chatList-row">
                <Col class="chatList-avatar"> <img class="avatar" src="../../assets/logoIcon.png" alt="" /></Col>
                <div class="chatList-answer-box">
                  <Col class="chatList-answer">
                    <MarkdownPreview :initialValue="cItem.answer" :copyCode="true" copyBtnText="复制" theme="dark" />
                    <div class="reloadBox" v-show="cItem.status == -1">
                      <Button type="default" @click="reAskZG" class="reAskZG" icon="ios-refresh">点击重试</Button>
                    </div>

                    <div class="divider" v-show="cItem.loading == false && cItem.status !== -1"></div>
                    <div class="footerButtonGroup" v-show="cItem.loading == false && cItem.status !== -1">
                      <div class="voiceGroup">
                        <div class="speak-footer-button" @click="playTTS(cItem, cIndex, 'speak')" v-show="audioNum !== cIndex" title="语音播放">
                          <img src="@/assets/playSound.png" alt="" />
                        </div>
                        <div class="speak-footer-button" @click="stopTTS(cItem, cIndex, 'cancel')" v-show="audioNum === cIndex" title="取消播放">
                          <img src="@/assets/stopPlay.png" alt="" />
                          <AudioLine />
                        </div>
                      </div>
                      <div class="footer-button-group">
                        <div v-show="cIndex == currentListHistory.length - 1" @click="reAskZG" class="reAskZG_text">
                          <span>重新生成</span>
                        </div>
                        <div @click="clickFooterButton(cItem, cIndex, 'copy')" class="anwser-footer-button" title="复制内容">
                          <!-- <span>复制内容 </span> -->
                          <img src="@/assets/copy.png" alt="" />
                        </div>
                        <div v-show="cItem.status == 3" @click="clickFooterButton(cItem, cIndex, 'export')" class="anwser-footer-button" title="导出内容">
                          <!-- <span>导出内容 </span> -->
                          <img src="@/assets/export.png" alt="" />
                        </div>
                        <div @click="clickFooterButton(cItem, cIndex, 'openWindow')" class="anwser-footer-button gztBtn" title="打开工作台">
                          <span></span>
                          <img class="gztBtnImg" src="@/assets/openWindow.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col class="chatList-footer" v-show="cItem.loading == false && cItem.status !== -1">
                    <div class="footer-time">
                      <span>{{ cItem.time }}</span>
                    </div>
                    <div @click="agreeOrDisagree(cItem, cIndex, 'agree')">
                      <img v-if="cItem.agree" class="agreeImg" src="@/assets/like_blue.png" alt="" />
                      <img v-else class="agreeImg" src="@/assets/like_gray.png" alt="" />
                    </div>
                    <div @click="agreeOrDisagree(cItem, cIndex, 'disagree')">
                      <img v-if="cItem.disagree" class="agreeImg" src="@/assets/unlike_blue.png" alt="" />
                      <img v-else class="agreeImg" src="@/assets/unlike_gray.png" alt="" />
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
            <Divider class="timeLimit" v-show="chatObject.timeLimit">{{ chatObject.timeLimitText }}</Divider>
          </Col>
          <div :class="this.showRight ? 'chatInput chatInput1 chatInput-full' : 'chatInput chatInput1'" v-show="!showDefault">
            <div class="clear-btn">
              <img class="clear-btn-icon" src="@/assets/clear-light.png" @click="clearHistory()" />
            </div>
            <div class="input1Box">
              <Input v-model="formData.chatInput" type="textarea" :rows="2" :autosize="{ minRows: 2, maxRows: 4 }" size="large" :maxlength="1024" placeholder="请输入问题" @keydown.enter.native="askZhiGong" :disabled="chatObject.timeLimit"> </Input>
              <div class="chatInputButton" @click="askZhiGong">
                <img class="chatInputButtonIcon" src="../../assets/send.png" alt="" />
              </div>
              <div class="chatInputButton1" @click="uploadModal = true">
                <img class="chatInputButtonIcon" src="../../assets/upload.png" alt="" />
              </div>
              <div class="chatTypeButton" @click="changeChatType()" v-if="currentPostList.length !== 0" title="取消前文联想">
                <img class="chatInputButtonIcon" src="../../assets/connected.png" alt="" />
              </div>
              <div class="chatTypeButton" v-if="currentPostList.length == 0" title="已取消前文联想">
                <img class="chatInputButtonIcon" src="../../assets/breaked.png" alt="" />
              </div>
            </div>
          </div>
          <div class="watermark"></div>
        </Col>
        <div :class="this.fullPage ? 'chat-right fullRight' : 'chat-right'" v-show="showRight">
          <div class="rightHeader">
            <div class="fullPageKey" v-if="this.fullPage" @click="fullScreen()">
              <img src="@/assets/closeUpWindow.png" />
            </div>
            <div class="fullPageKey" v-else @click="fullScreen()">
              <img src="@/assets/expendWindow.png" />
            </div>
            <div class="closeWindow" @click="closeRight()">
              <img src="@/assets/close.png" />
            </div>
          </div>
          <PreViewOffice ref="preViewOfficeRef" />
        </div>
      </Row>
    </div>
    <Modal v-model="uploadModal" title="图片解析" @on-ok="uploadModal = false" @on-cancel="uploadModal = false">
      <Upload multiple type="drag" action="http://152.136.37.136:6606/upload" :on-success="uploadPic" @success="uploadPic" :default-file-list="uploadList">
        <div style="padding: 20px 0">
          <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
          <p>点击或拖拽图片上传</p>
        </div>
      </Upload>
      <img :src="uploadPicSrc" alt="" style="width: 100%;">
      <p>{{ uploadPicTxt }}</p>
    </Modal>
  </div>
</template>
<script>
  import { Button, Col, Input, Row, } from "view-design";
  import Cookies from "js-cookie";
  import { MarkdownPreview } from "vue-meditor";
  import Feedback from "@/components/feedback/feedback.vue";
  import AudioLine from "@/components/audioLine/index.vue";
  import HistoryList from "@/components/historyList/historyList.vue";
  import PreViewOffice from "@/components/preViewOffice/preViewOffice.vue";
  import ciicDefaultChat from "@/assets/file/ciicDefaultChat";
  import Speech from "speak-tts";
  // import { EventSourcePolyfill } from "event-source-polyfill";
  import "./chatPage.less";
  import { getStore, setStore } from "@/libs/storage";
  import { addOrEditHistory2 } from "@/api/api.js";
  const icon1 = require("@/assets/icon1.png");
  const icon2 = require("@/assets/icon2.png");
  const icon3 = require("@/assets/icon3.png");
  export default {
    components: {
      Row,
      Col,
      Input,
      Button,
      MarkdownPreview,
      Feedback,
      PreViewOffice,
      AudioLine,
      HistoryList,
    },
    data() {
      return {
        showDefault: true,
        speech: null,
        showRight: false,
        fullPage: false,
        ZGloading: false,
        userInfo: "",
        audioNum: null,
        audio: null,
        recording: false,
        scrollContainerHeight: 0,
        formData: {
          chatInput: "",
        },
        chatObject: {
          timeLimit: false,
          timeLimitText: "",
        },
        strBefore: "",

        //   status 状态码
        //     -1：接口 catch err
        //     0: 请求中
        //     2: 正常
        currentId: "",
        currentListHistory: [], //显示的列表
        currentPostList: [], //发送数据的的列表
        defaultChatList: [
          {
            icon: icon1,
            title: "热门提问",
            questions: ["什么是智工·工业大模型", "智工的特点", "创造智工的设计团队是谁"],
          },
          {
            icon: icon2,
            title: "有趣的提问",
            questions: ["数字孪生在工业制造领域的应用", "浅谈工业数字化的必要性", "LK220控制器PLC有哪些特性"],
          },
          {
            icon: icon3,
            title: "应用场景",
            questions: ["LK210和LK220是否支持多种协议", "AutoThink总体有哪些特点", "AutoThink是否容易维护"],
          },
        ],
        uploadModal: false,
        uploadPicSrc:'',
        uploadPicTxt:'',
        uploadList:[],
      };
    },
    methods: {
      // 初始化
      initData() {
        if (this.ZGloading == true) {
          return;
        }
        if (this.audio !== null) {
          this.audio.pause();
          this.audio = null;
        }
        this.currentId = "";
        this.currentListHistory = [];
        this.currentPostList = [];
        this.showRight = false;
        this.fullPage = false;
        this.formData.chatInput = "";
        this.showDefault = true;
        this.chatObject = {
          timeLimit: false,
          timeLimitText: null,
        };
      },
      // 反馈功能
      feedback() {
        if (this.ZGloading == true) {
          return;
        }
        this.$refs.feedbackRef.showModal = true;
      },
      getClass() {
        if (this.fullPage) {
          return "hide";
        }
        if (this.showRight == true) {
          // 给个名字
          return "chat-left fullChat";
        } else {
          return "chat-left";
        }
      },
      clearHistory() {
        if (this.ZGloading == true) {
          return;
        }

        // 初始化
        this.initData();
      },
      // 默认列表回答，大概要删掉
      askQuestion(data) {
        if (this.ZGloading == true) {
          return;
        }
        this.formData.chatInput = data;
        // 执行聊天操作
        this.askZhiGong();
      },
      // 更换聊天模式
      changeChatType() {
        if (this.ZGloading == true) {
          return;
        }
        // 清空携带聊天记录的列表
        this.currentPostList = [];
        this.$Message.info("已取消前文联想");
      },

      // chat功能
      async askZhiGong(event) {
        // this.loading
        // 确定是否是在输入文字
        /**
         * 只能用keyDown模式，keyUp模式不会触发229。
         */

        if (event && event.keyCode == 229) {
          return;
        }
        // 上一条正在加载的时候不允许再次发送
        if (this.ZGloading == true) {
          return;
        }
        this.showRight = false;
        let _this = this;
        let inputText = this.formData.chatInput;

        // 1.不能输入空信息
        function isNull(str) {
          let strNull = str.replace(/\n/g, "");
          if (strNull == "" || strNull == " " || "\n".indexOf(strNull) >= 0) {
            return true;
          }
          var regu = "^[ ]+$";
          var re = new RegExp(regu);
          return re.test(str);
        }
        if (isNull(inputText) === true) {
          this.formData.chatInput = "";
          this.$Message.error("输入内容不能为空");
          return;
        }
        // 2.如果上一个问题还没返回结果，直接return，或者提示正在思考
        const isReturn = this.isReturnAnswer();
        if (isReturn === false) {
          this.$Message.info("智工正在思考中，请稍等。");
          return;
        }

        // 传历史数据对话
        let params = this.getParams(inputText);

        // 3.调取接口前
        this.ZGloading = true;
        this.currentPostList.push({
          question: inputText,
          answer: "智工正在思考中...",
        });
        this.currentListHistory.push({
          question: inputText,
          answer: "智工正在思考中...",
          status: 0,
          agree: false,
          disagree: false,
          speak: false,
          pause: false,
          loading: true,
          time: new Date().toLocaleString(),
        });
        let answerIndex = this.currentListHistory.length - 1;
        let postIndex = this.currentPostList.length - 1;
        this.showDefault = false;
        setTimeout(() => {
          this.scrollToBottom2();
          // this.scrollToBottom();
        }, 500);
        this.recording = false;

        // 定义结束标记
        function endChat() {
          if (_this.currentListHistory[answerIndex] !== undefined) {
            _this.currentListHistory[answerIndex]["loading"] = false;
          }
          _this.ZGloading = false;
          _this.scrollToBottom2();
          setTimeout(_this.scrollToBottom(), 500);
          // 提交历史数据
          // _this.insertOrUpdateHistory();
          return "end";
        }

        // 4. 访问我们的接口
        // 先本地匹配看有没有
        const csvAnswer = this.matchQuestion(inputText);
        if (csvAnswer !== null) {
          setTimeout(() => {
            let prevText = csvAnswer.answer;
            // 识别不到的代码块怎么处理
            if (prevText.indexOf("VAR_INPUT ") == 0 || prevText.indexOf("SELECT") >= 0 || prevText.indexOf("var") >= 0) {
              prevText = "``` \n" + prevText + "\n```";
            }
            this.clickKeyBoard(prevText, answerIndex, postIndex);
          }, 2000);
          // 历史记录不需要这个数据
          this.currentPostList.splice(postIndex, 1);
          this.formData.chatInput = "";
          this.strBefore = "";
          return;
        } else {
          let userInfo = Cookies.get("userInfo");
          setTimeout(() => {
            this.formData.chatInput = "";
          }, 500);
          this.strBefore = "";
          fetch("http://152.136.37.136:6606/query-json", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "text/event-stream",
            },
            mode: "cors",
            body: JSON.stringify(params),
          })
            .then(async (response) => {
              console.log(response, "查看返回值");
              if (response.status !== 200) {
                this.$Message.error(response.statusText);
                this.currentListHistory[answerIndex]["answer"] = response.statusText;
                this.currentListHistory[answerIndex]["status"] = -1;
                this.currentPostList.splice(postIndex, 1);
                endChat();
                return;
              }
              const reader = response.body.getReader();
              const decoder = new TextDecoder("utf-8");
              let data = "";
              let finalData = "";
              let bytesLength = 0;
              // 在 body 下载时，一直为无限循环
              reader.read().then(function processResult(result) {
                // 全部返回结果

                // 全部返回结果
                if (result.done) {
                  // 聊天次数为0后

                  if (result.value == undefined) {
                    _this.currentListHistory[answerIndex]["status"] = 2;
                  }
                  // token失效后
                  if (data.indexOf('"error":"Token') > -1) {
                    _this.$Message.error(JSON.parse(data).error);
                    Cookies.remove("userInfo");
                    Cookies.remove("accessToken");
                    setStore("accessToken", "");
                    setStore("vip", "");
                    _this.$router.push("/");
                  }
                  endChat();
                  // 这儿需要停掉read模式，否则报错
                  return;
                }
                if (result.value !== undefined) {
                  data += decoder.decode(result.value || "", { stream: true });
                }
                let messages = data.split("\n\n");
                // let messages = data;
                if (data.endsWith("\n\n")) {
                  data = "";
                } else {
                  data = messages.pop();
                }
                for (let message of messages) {
                  let newMessage = message.slice(6, message.length);

                  if (message == "</s>" || message == "\n</s>") {
                    // 代码块需要换个行才能完整
                    finalData = finalData + "\n ";
                    finalData = finalData.replace("<LF>", "\n").replace("<tab>", "\t");
                    _this.currentListHistory[answerIndex]["answer"] = finalData;
                    _this.currentPostList[postIndex]["answer"] = finalData;
                    _this.currentListHistory[answerIndex]["status"] = 2;
                    endChat();
                    return;
                  } else {
                    if (finalData == "//" || finalData == "VAR_INPUT" || finalData == "SELECT" || finalData == "var" || finalData == "FUNCTION" || finalData == "#include" || finalData == "void" || finalData == "public" || finalData == "import" || finalData == "using" || finalData == "<?php") {
                      finalData = "```\n" + finalData;
                    }
                    if (newMessage == "```" || newMessage == "\n```") {
                      finalData = finalData + "\n" + newMessage + "\n";
                    } else if (newMessage === "") {
                    } else {
                      console.log(newMessage, "newmesage");
                      finalData = finalData + newMessage;
                    }
                    finalData = finalData.replace("<LF>", "\n").replace("<tab>", "\t");
                    _this.currentListHistory[answerIndex]["answer"] = finalData + "\n";
                    _this.currentPostList[postIndex]["answer"] = finalData;
                    _this.currentListHistory[answerIndex]["status"] = 2;
                    _this.scrollToBottom2();
                    bytesLength = finalData.replace(/[^\x00-\xff]/g, "xx").length;
                    if (bytesLength >= 2000) {
                      endChat();
                      return;
                    }
                  }
                }

                return reader.read().then(processResult);
              });
            })
            .catch((err) => {
              _this.currentListHistory[answerIndex]["answer"] = "请求错误，请重新尝试";
              _this.currentListHistory[answerIndex]["status"] = -1;
              endChat();
            });
          return;
        }
      },
      uploadPic(response, file, fileList) {
        console.log(response, file, fileList,'上传结果');
        this.uploadList = [];
        this.uploadPicSrc = response.imgurl;
        this.uploadPicTxt = response.txt;
      },
      // 重新生成
      reAskZG(event) {
        let postIndex = this.currentPostList.length - 1;
        let answerIndex = this.currentListHistory.length - 1;
        this.formData.chatInput = this.currentListHistory[answerIndex]["question"];
        this.currentListHistory.splice(answerIndex, 1);
        this.currentPostList.splice(postIndex, 1);
        this.askZhiGong();
      },

      insertOrUpdateHistory() {
        let history = [];
        this.currentListHistory.map((item) => {
          history.push({
            question: item.question,
            answer: item.answer,
            time: item.time,
          });
        });
        let params = {
          id: this.currentId,
          historys: JSON.stringify(history),
          classification:1,
        };

        addOrEditHistory2(params).then((res) => {
          if (res.success) {
            this.currentId = res.result.id;
          }
        });
      },
      // 前端打字效果
      clickKeyBoard(textWord, answerIndex, postIndex) {
        let i = 0; // 记录目前的字符数
        this.formData.chatInput = "";
        const intervalId = setInterval(() => {
          this.currentListHistory[answerIndex]["answer"] = textWord.slice(0, i);
          //   // 滑动到底部
          this.scrollToBottom2();
          i++;
          if (i > textWord.length) {
            this.currentListHistory[answerIndex]["loading"] = false;
            this.currentListHistory[answerIndex]["status"] = 2;
            this.ZGloading = false;
            this.scrollToBottom2();
            clearInterval(intervalId);
          }
        }, 60);
      },

      // 是否返回结果
      isReturnAnswer() {
        let chLength = this.currentListHistory.length;
        if (chLength > 0 && this.currentListHistory[chLength - 1]["status"] === 0) {
          return false;
        } else {
          return true;
        }
      },

      // 下滑至底部
      scrollToBottom() {
        let _this = this;
        let scroll_to_bottom = document.getElementsByClassName("scrollContainer");
        if (scroll_to_bottom == null || scroll_to_bottom == undefined) {
          scroll_to_bottom = document.getElementsByClassName("scrollContainer-full");
        }
        let limitEl = document.getElementsByClassName("limit");
        //
        function scrollBottom(element) {
          if (element[0] == undefined) {
            return;
          } else {
            element[0].scrollTo({
              top: element[0].scrollHeight + 1000,
              // top: 9000,
              behavior: "smooth",
            });
            element[0].scrollIntoView(true);
          }
        }
        setTimeout(scrollBottom(scroll_to_bottom), 70);
      },

      // 监听 scroll 高度
      scrollToBottom2() {
        let scroll_to_bottom = document.getElementsByClassName("scrollContainer");
        if (scroll_to_bottom == null || scroll_to_bottom == undefined) {
          scroll_to_bottom = document.getElementsByClassName("scrollContainer-full");
        }
        if (scroll_to_bottom == null || scroll_to_bottom == undefined) {
          return;
        }
        if (scroll_to_bottom[0].scrollHeight !== undefined) {
          this.scrollContainerHeight = scroll_to_bottom[0].scrollHeight;
          // return scroll_to_bottom[0].scrollHeight
        }
      },

      playTTS(data, index, type) {
        if (this.audio !== null) {
          this.audio.pause();
          this.audio = null;
          // this.currentListHistory.forEach((cItem, cIndex) => {
          //     cItem.pause = true;
          //     cItem.speak = false;
          // });
        }

        let _this = this;
        let ttsText = data.answer.toString();
        ttsText = ttsText.replace(/`/g, "");
        ttsText = ttsText.replace(/#/g, "");
        ttsText = ttsText.replace(/\n/g, "");
        ttsText = ttsText.replace(/\r/g, "");
        ttsText = ttsText.replace(/ /g, "");
        ttsText = ttsText.replace(/\//g, "");
        ttsText = ttsText.replace(/&/g, "");
        ttsText = ttsText.replace(/$/g, "");
        _this.audio = null;
        _this.audio = new Audio("http://dict.youdao.com/dictvoice?audio=" + encodeURIComponent(ttsText) + "&le=zh");
        let audioPlay = _this.audio.play();
        if (audioPlay !== undefined) {
          audioPlay.then(() => {
            _this.audio.play();
            _this.audioNum = index;
            let listenPause = setInterval(() => {
              if (_this.audio !== null && _this.audio.paused == true) {
                _this.currentListHistory[index]["speak"] = false;
                _this.audioNum = null;
                clearInterval(listenPause);
              } else if (_this.currentListHistory.length > 0) {
                _this.currentListHistory[index]["speak"] = true;
              } else {
                clearInterval(listenPause);
                this.audioNum = null;
              }
            }, 200);
            listenPause;
          });
        }
        return;
      },
      stopTTS(data, index, type) {
        if (this.audio !== null) {
          this.audio.pause();
        }
        this.currentListHistory[index]["speak"] = false;
      },

      // 语音功能
      speechInit() {
        this.speech = new Speech();
        this.speech.setLanguage("zh-CN");
        this.speech
          .init({
            volume: 1,
            lang: "zh-CN",
            rate: 0,
            pitch: 0,
          })
          .then(() => {});
      },

      //语音播报
      speakText(data, index, type) {
        // 开始朗读
        if (type === "speak") {
          this.currentListHistory.forEach((item) => {
            item.speak = false;
            item.pause = false;
          });
          this.speech.cancel();
          this.speech
            .speak({
              text: data.answer,
              listeners: {
                onstart: () => {},
                onend: () => {},
              },
            })
            .then(() => {
              this.currentListHistory[index]["speak"] = false;
              this.currentListHistory[index]["pause"] = false;
            });

          this.currentListHistory[index]["speak"] = true;
          this.currentListHistory[index]["pause"] = false;
          return;
        }
        if (type === "cancel") {
          // 取消
          this.speech.cancel();
          this.currentListHistory[index]["speak"] = false;
          this.currentListHistory[index]["pause"] = false;
          return;
        }
        // 暂停
        if (type === "pause") {
          this.speech.pause();
          this.currentListHistory[index]["pause"] = true;
          return;
        }
        // 继续
        if (type === "resume") {
          this.speech.resume();
          this.currentListHistory[index]["pause"] = false;
          return;
        }
      },
      // 点赞功能
      agreeOrDisagree(data, index, key) {
        switch (key) {
          case "agree":
            if (this.currentListHistory[index][key] == false) {
              this.currentListHistory[index]["disagree"] = false;
            }
            break;
          default:
            if (this.currentListHistory[index][key] == false) {
              this.currentListHistory[index]["agree"] = false;
            }
            break;
        }
        this.currentListHistory[index][key] = !data[key];
      },
      getParams(inputText) {
        let params = {
          query: inputText,
          historys: [],
        };
        let bytesLens = 0;
        let bytesLenQuery = inputText.replace(/[^\x00-\xff]/g, "xx").length;
        for (let i = this.currentPostList.length; i >= 0; i--) {
          if (i == 0) {
            return params;
          }
          // 处理字节长度的问题
          let bytesLenQ = this.currentPostList[i - 1]["question"].replace(/[^\x00-\xff]/g, "xx").length;
          let bytesLenA = this.currentPostList[i - 1]["answer"].replace(/[^\x00-\xff]/g, "xx").length;
          bytesLens = bytesLenQuery + bytesLens + bytesLenQ + bytesLenA;
          if (bytesLens < 1200) {
            params.historys.unshift({
              question: this.currentPostList[i - 1]["question"],
              response: this.currentPostList[i - 1]["answer"],
            });
          } else {
            return params;
          }
        }
      },

      ///点击复制
      copyData(data) {
        var oInput = document.createElement("input"); //创建一个input标签
        oInput.value = data; //将要复制的值赋值给input
        document.body.appendChild(oInput); //在页面中插入
        oInput.select(); // 模拟鼠标选中
        document.execCommand("Copy"); // 执行浏览器复制命令（相当于ctrl+c）
        oInput.style.display = "none"; //只是用一下input标签的特性，实际并不需要显示，所以这里要隐藏掉
        this.$Message.success("复制成功");
      },
      // footer 功能
      clickFooterButton(data, dItem, key) {
        switch (key) {
          case "openWindow":
            this.$refs.preViewOfficeRef.tabList = [];
            this.preFile(data, dItem);

            break;
          case "copy":
            this.copyData(data.answer);
            break;
          default:
            break;
        }
      },
      // 预览文件
      preFile(data, dItem) {
        this.$refs.preViewOfficeRef.answerData = data.answer;
        let _this = this;
        function pushList(params, type, name) {
          _this.$refs.preViewOfficeRef.tabList.push({
            name: params,
            tabDatatype: type,
            src: params,
            data: params,
          });
        }

        let strMarkdown = this.markdownString(data.answer);
        // 循环给右侧栏展示
        let strHttpArr = this.httpString(data.answer);

        if (strMarkdown !== null || data.answer.indexOf("// ") > 0 || strHttpArr == null) {
          this.$refs.preViewOfficeRef.tabList.push({
            name: "编辑",
            tabDatatype: "markdown",
            src: "",
            data: data.answer,
          });
        }
        if (strHttpArr !== null) {
          strHttpArr.forEach((sItem, sIndex) => {
            // 识别文件类型
            if (sItem.indexOf(".pdf") >= 0) {
              pushList(sItem, "pdf", sItem);
            } else if (sItem.indexOf(".docx") >= 0 || sItem.indexOf(".doc") >= 0) {
              pushList(sItem, "word", sItem);
            } else if (sItem.indexOf(".xlsx") >= 0) {
              pushList(sItem, "excel", sItem);
            } else {
              pushList(sItem, "html", sItem);
            }
          });
        }

        this.showRight = true;
      },
      //
      // 关闭工作台
      closeRight() {
        this.showRight = false;
        this.fullPage = false;
        this.$refs.preViewOfficeRef.tabList = [];
      },
      // 全屏
      fullScreen() {
        this.fullPage = !this.fullPage;
      },
      // 退出登录
      exitbtn(v) {
        if (v === "logout") {
          this.$router.push("/");
        } else if (v === "userinfo") {
        }
      },
      // 格式化字符串
      replaceStr(Str) {
        let b = Str.replace(/。/g, "。<br/><br/>");
        return b;
      },

      // 正则获取 返回地址中的链接
      httpString(s) {
        if (s === undefined || s === null) {
          return [];
        }

        let reg = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]+/g;

        let regCh = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]+[\u4e00-\u9fa5]+/g;
        let regS = s.match(regCh);
        if (regS === null) {
          regS = s.match(reg);
        }
        return regS;
      },
      // 正则获取 返回地址中的代码块
      markdownString(s) {
        if (s === undefined || s === null) {
          return null;
        }
        let reg = /(```)/g;
        s = s.match(reg);
        return s;
      },

      // 正则匹配答案
      matchQuestion(text) {
        let returnData = null;
        let RDType = 0;
        for (let i = 0; i < ciicDefaultChat.length; i++) {
          if (text.indexOf(ciicDefaultChat[i]["ask"]) >= 0) {
            returnData = ciicDefaultChat[i];
            // 显示工作台按钮
            ciicDefaultChat[i]["status"] = 2;
            break;
          }
        }
        return returnData;
      },
      recordInputText() {
        const button = document.getElementById("recordButton");
        const button1 = document.getElementById("recordButton1");
        this.recording = false;

        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (typeof SpeechRecognition !== "undefined") {
          const recognition = new SpeechRecognition();
          const stop = () => {
            recognition.stop();
            this.strBefore = "";
            this.askZhiGong();
          };
          const start = () => {
            recognition.start();
            this.formData.chatInput = "";
            this.strBefore = "";
          };
          const onResult = (event) => {
            if (this.recording == false) {
              recognition.stop();
              this.strBefore = "";
            }
            for (const res of event.results) {
              const text = document.createTextNode(res[0].transcript);
              if (this.recording) {
                this.formData.chatInput = this.strBefore + text.data;
              }
              if (res.isFinal) {
                this.strBefore = text.data;
              }
            }
          };
          recognition.continuous = true;
          recognition.interimResults = true;
          recognition.addEventListener("result", onResult);
          button.addEventListener("click", (event) => {
            this.recording ? stop() : start();
            this.recording = !this.recording;
          });
          button1.addEventListener("click", (event) => {
            this.recording ? stop() : start();
            this.recording = !this.recording;
          });
        } else {
          button.remove();
          button1.remove();
          this.$Message.info("您的设备不支持语音输入");
        }
      },
      startRecord() {},
      _isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        return flag;
      },
      // 添加水印
      addWaterMark() {
        // 创建画布
        function createCanvas(width, height) {
          // 创建一个画布
          let canvas = document.createElement("canvas");
          // 设置画布的宽高
          canvas.width = width;
          canvas.height = height;
          return {
            canvas,
            ctx: canvas.getContext("2d"),
          };
        }

        // 填充水印
        function fillMark(canvas, html) {
          html[0].style.pointerEvents = "none";
          html[0].style.background = "url(" + canvas.toDataURL("image/png") + ") left top repeat";
        }
        // 文字水印生成
        function watermarkText(html, str, options = {}) {
          let {
            fontSize = 14, // 设置字体大小
            fontFamily = "微软雅黑", // 设置字体
            width = str.length * fontSize, // 设置画布的宽
            height = 120, // 设置画布的高
            color = "rgba(0, 0, 0, 0.1)", // 设置填充绘画的颜色/渐变模式
            angle = -20, // 旋转角度
            textAlign = "left", // 设置文本内容的对齐方式
            font, // 设置字体及大小
            textBaseline = "Middle", // 设置文本基线
            start = -120, // 开始绘制文本的X坐标位置
            end = height / 1.2, // 开始绘制文本的Y坐标位置
          } = options;

          // 创建画布
          let cans = createCanvas(width, height);
          let canvas = cans.canvas;
          let ctx = cans.ctx;
          ctx.rotate((angle * Math.PI) / 180);
          ctx.font = font || fontSize + "px " + fontFamily;
          ctx.imageSmoothingEnabled = true;
          ctx.fillStyle = color;
          ctx.textAlign = textAlign;
          ctx.textBaseline = textBaseline;
          // 在画布上绘制填色的文本（输出的文本，开始绘制文本的X坐标位置，开始绘制文本的Y坐标位置）
          ctx.fillText(str, start, end);
          // 填充水印
          fillMark(canvas, html);
        }
        let html = document.getElementsByClassName("watermark");
        watermarkText(html, "智工生成内容仅供参考", { width: 240, start: 0 });
      },
    },
    beforeMount() {
      let userInfo = Cookies.get("userInfo");
    },
    mounted() {
      if (this._isMobile()) {
        this.$router.replace("/7bApp");
      } else {
        this.$router.replace("/7b");
      }
      this.speechInit();
      this.addWaterMark();
    },
    computed: {},
    watch: {
      scrollContainerHeight(newH, oldH) {
        this.scrollToBottom();
      },
    },
  };
</script>
<style lang="less" scoped></style>
