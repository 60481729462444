const defaultQuestions = [
    " LK442模块有超限报警功能吗？",
    " LK441的设备诊断数据为0x02，0x01表示什么故障？",
    " LK441的通道诊断数据为0x80，0x42，0xA6表示什么报警？",
    " LK441进行冷端补偿有几种方法？",
    " LK441绿色RUN运行指示灯闪烁是什么含义？",
    " 和利时LK910电源模块如何安装？",
    " AutoThink主要具有哪些功能和特点？",
    " 如何打开AutoThink软件中已存在的工程或库？",
    " AutoThink硬件配置LK平台支持哪些控制器？控制器支持哪些协议配置？",
    " PLC编程中，什么是POU?",
    " AutoThink中，如何创建POU?",
    " ST是什么的简称？",
    " LK大型可编程控制器具有哪些特点和优势？",
    " LK硬件系统包含哪些组成部分？",
    " 如何正确接线LKA101总线电缆？",
    " LK610的阀值电平是多少？",
    " LK720的用户参数支持在线更改吗？",
    " SOE模块可以保存的最大SOE事件数量是多少？",
    " LK104本地背板由哪些组成结构？",
    " LK系列可编程控制系统的I/O电缆接地有哪些要求？",
    " LK240有几组光纤通讯接口？",
    " LK241是什么类型的控制器？",
    " 如何在LK239终端上设置终端匹配电阻？",
    " LK710通讯故障时模块的状态有哪几种情况？",
    " LK412采用什么作为输入电源？",
    " LKA107的安装步骤是什么",
    " LK系列控制器出现系统异常时，如何进行故障排查？",
    " 请简单介绍MC系列运动控制器。",
    " MC运动控制系统的硬实时任务主要实现哪些功能？",
    " MC运动控制系统HMC指令可以分为哪两类？",
    " LK117控制系统的组成结构有哪些部分",
    " LK系列控制器有哪几种工作模式？",
    " LM小型可编程控制器CPU模块提供了哪些通讯接口？",
    " LM系列小型PLC编程的专用软件是什么？",
    " 和利时Q1系列伺服驱动器对安装场所有哪些要求？",
    " 当HGD100迷你变频器发生故障时，可以通过什么方法退出故障状态？",
    " LK130C主控背板有几槽？",
    " LK130C扩展插槽可安装哪些模块？",
    " LKC系列有几种扩展背板？",
    " LM系列小型PLC具有哪些主要特点？",
    " LM小型可编程控制器包括哪些模块？",
    " LM小型可编程控制器的CPU模块有什么功能和作用？",
    " LM系列PLC提供了哪些通讯方式？",
    " 如何连接LM小型可编程控制器的电源线？",
    " LM系列CPU模块的指示灯有哪些？",
    " LM3106通讯功能的针脚定义与哪个设备相同？",
    " LM3220模块主要完成什么功能？输出的额定负载电压是多少？",
    " 介绍一下LM3311模块的主要功能",
    " LM3313模块是什么类型的模块？",
    " 在安装或拆卸LM系列小型PLC模块及其相关设备时应该注意什么？",
    " 如何打开AutoThink软件中的帮助系统？",
    " 如何在在线帮助中进行模糊搜索关键字？",
    " AutoThink软件中有哪些类型的快捷键？",
    " AutoThink软件是用来做什么的？",
    " 如何启动AutoThink软件？",
    " AutoThink如何执行下装操作？",
    " AutoThink软件的编程界面由哪些组成部分？",
    " AutoThink菜单栏提供了哪些功能选项？"
];
export default defaultQuestions