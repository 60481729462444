<template>
  <div class="home">
    <div id="root" class="chat_rootContainer">
      <div
        class="historyList"
        :style="{
          display: showRight ? 'none' : 'block',
          minWidth: historyListKey ? '300px' : '0px',
          maxWidth: historyListKey ? '300px' : '0px',
          padding: historyListKey ? '60px 20px 20px 20px' : '0px',
        }"
      >
        <HistoryList @setCurrentListHistory="setCurrentListHistory" ref="historyListRefWeb" />
      </div>
      <div class="NYG3ffBi productBg B7NL5wM6">
        <div class="XDxYjHlu">
          <div class="fjiYtnUB1">
            <a href="/#/homeView_1"><img alt="logo" class="qbUby_dV" src="@/assets/logo.png" /></a>
          </div>
          <!-- <div class="router_link">
            <a href="/#/chatWithZG_dmt">多模态模型</a> / <a href="/#/chatWithZG_plc_copilot" style="color: #47b347">智工·PLC Copilot</a> / <a href="/#/chatWithZG_electricity_copilot">智工电力规范 Copilot</a> /
            <a href="/#/chatWithZG_1">智工1.0</a>
          </div> -->
          <div class="DDl4VNjw">
            <ul class="ant-menu-overflow ant-menu ant-menu-root ant-menu-horizontal ant-menu-light" role="menu" tabindex="0" data-menu-list="true" style="border: 0px; background: transparent">
              <li class="ant-menu-overflow-item ant-menu-submenu ant-menu-submenu-horizontal" role="none" style="opacity: 1; order: 0; display: flex; flex-direction: row">
                <div style="display: flex; justify-content: center; align-items: center; cursor: pointer" @mouseover="showCodeSuggestion = true" @mouseout="showCodeSuggestion = false">
                  <img class="avatar" src="@/assets/chat/showCodeSuggestionIcon.png" alt="" />
                  <span style="font-size: 18px">意见反馈</span>
                </div>
                <div style="position: absolute; top: 50px; left: 0px" v-show="showCodeSuggestion">
                  <img src="@/assets/chat/showCodeSuggestion.png" alt="" style="width: 100%" />
                </div>
              </li>
              <li class="ant-menu-overflow-item ant-menu-submenu ant-menu-submenu-horizontal" role="none" style="opacity: 1; order: 0; display: flex; flex-direction: row">
                <div style="display: flex; justify-content: center; align-items: center; cursor: pointer" @mouseover="showCode = true" @mouseout="showCode = false">
                  <img class="avatar" src="@/assets/home/home_mini_program_icon.png" alt="" />
                  <span style="font-size: 18px">智工小程序</span>
                </div>
                <div style="position: absolute; top: 50px; right: 0px" v-show="showCode">
                  <img src="@/assets/home/home_mini_program.png" alt="" style="border-radius: 50%" />
                </div>
              </li>
            </ul>
            <div aria-hidden="true" style="display: none"></div>
          </div>
        </div>
      </div>
      <!-- <Feedback ref="feedbackRef"></Feedback> -->

      <Row id="chatContainer">
        <Col :class="getClass()">
          <div key="" class="defaultList1" v-show="showDefault">
            <Col class="chatList-avatar"> <img class="avatar" src="../../assets/logoIcon.png" alt="" /></Col>
            <Col class="default-chatList-answer">
              <div>
                <!-- <h1>你好，我是智工·PLC Copilot</h1> -->
                <h1>你好，我是智工</h1>
                <p style="max-width: 600px">为工控PLC问题提供精准解答。一键提问，即可获取高效解决方案。</p>
              </div>
              <div class="default-middle-driver">
                <span style="font-weight: bold">您可以尝试这样问我：</span>
                <span @click="getRandomElements" style="cursor: pointer; color: #4687c5"> <img src="@/assets/chat/reload1.png" alt="" style="width: 14px; margin-right: 5px" />再来一批</span>
              </div>
              <div class="default-questions-list" v-if="defaultHotChatList.length > 0" style="font-weight: bold">
                <div>
                  热门提问：
                  <div>
                    <div class="defaultQBtn" @click="askQuestion(defaultHotChatList[0])">
                      <img src="@/assets/chat/hotcup.png" alt="" class="default-reload" />
                      <span> {{ defaultHotChatList[0] }} </span>
                    </div>
                    <div class="defaultQBtn" @click="askQuestion(defaultHotChatList[1])">
                      <img src="@/assets/chat/rocket.png" alt="" class="default-reload" />
                      <span> {{ defaultHotChatList[1] }} </span>
                    </div>
                    <div class="defaultQBtn" @click="askQuestion(defaultHotChatList[2])">
                      <img src="@/assets/chat/rocket.png" alt="" class="default-reload" />
                      <span> {{ defaultHotChatList[2] }} </span>
                    </div>
                  </div>
                </div>
                <div>
                  有趣的提问：
                  <div>
                    <div class="defaultQBtn" @click="askQuestion(defaultHotChatList[3])">
                      <img src="@/assets/chat/hotcup.png" alt="" class="default-reload" />
                      <span> {{ defaultHotChatList[3] }} </span>
                    </div>
                    <div class="defaultQBtn" @click="askQuestion(defaultHotChatList[4])">
                      <img src="@/assets/chat/rocket.png" alt="" class="default-reload" />
                      <span> {{ defaultHotChatList[4] }} </span>
                    </div>
                    <div class="defaultQBtn" @click="askQuestion(defaultHotChatList[5])">
                      <img src="@/assets/chat/rocket.png" alt="" class="default-reload" />
                      <span> {{ defaultHotChatList[5] }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>

          <Col class="chatInputBox">
            <div class="footer-fixed-blur"></div>
            <div class="chatList chatInput chatInput1" v-show="showDefault">
              <div style="position: relative; width: 100%">
                <Input
                  :class="'searchInput ' + inputRowsClass"
                  v-model="formData.chatInput"
                  type="textarea"
                  size="large"
                  :maxlength="1024"
                  placeholder="请在此输入您要提问的内容"
                  @keydown.enter.native="askZhiGong"
                  :disabled="chatObject.timeLimit"
                  @on-focus="changeInputRowsHeight('on')"
                  @on-blur="changeInputRowsHeight('off')"
                ></Input>
                <div class="chatInputButton" @click="askZhiGong">
                  <img class="chatInputButtonIcon" src="../../assets/send.png" alt="" />
                </div>
              </div>
            </div>
          </Col>

          <Col :class="this.showRight ? 'scrollContainer-full' : 'scrollContainer'" ref="scrollDiv" v-show="!showDefault">
            <div class="chatList" v-for="(cItem, cIndex) in currentListHistory" :key="cIndex">
              <Row class="chatList-row-zszq">
                <Col class="chatList-avatar"> <img class="avatar" src="../../assets/logoFav2.png" alt="" /></Col>
                <Col class="chatList-question">{{ cItem.question }}</Col>
              </Row>
              <Row class="chatList-row">
                <Col :class="getAvatarClass(cItem)"> <img class="avatar" src="../../assets/logoIcon.png" alt="" /></Col>
                <AnswerBox ref="AnswerBoxRef" :cItem="cItem" :currentListHistory="currentListHistory" :cIndex="cIndex" :audioNum="audioNum" @reAskZG="reAskZG" @clickFooterButton="clickFooterButton" @playTTS="playTTS" @stopTTS="stopTTS" @agreeOrDisagree="agreeOrDisagree" :currentId="currentId" />
              </Row>
            </div>
            <Divider class="timeLimit" v-show="chatObject.timeLimit">{{ chatObject.timeLimitText }}</Divider>
          </Col>
          <div class="stopButton" v-show="pauseAnswer === false">
            <Button @click="pauseAnswer = true"
              ><div class="stopBtn"><img class="stopIcon" src="../../assets/stopPlay_blue.png" alt="" /> 停止生成</div></Button
            >
          </div>
          <div :class="this.showRight ? 'chatInput chatInput1 chatInput-full' : 'chatInput chatInput1'" v-show="!showDefault">
            <div class="clear-btn">
              <img class="clear-btn-icon" src="@/assets/clear-light.png" @click="clearHistory()" />
            </div>
            <div class="input1Box">
              <Input
                v-model="formData.chatInput"
                type="textarea"
                :class="'searchInput ' + inputRowsClass"
                size="large"
                :maxlength="1024"
                placeholder="请在此输入您要提问的内容"
                @keydown.enter.native="askZhiGong"
                :disabled="chatObject.timeLimit"
                @on-focus="changeInputRowsHeight('on')"
                @on-blur="changeInputRowsHeight('off')"
              >
              </Input>
              <div class="chatInputButton" @click="askZhiGong">
                <img class="chatInputButtonIcon" src="../../assets/send.png" alt="" />
              </div>
            </div>
          </div>
          <div class="watermark"></div>
        </Col>
        <div :class="this.fullPage ? 'chat-right fullRight' : 'chat-right'" v-show="showRight">
          <div class="rightHeader">
            <div class="fullPageKey" v-if="this.fullPage" @click="fullScreen()">
              <img src="@/assets/closeUpWindow.png" />
            </div>
            <div class="fullPageKey" v-else @click="fullScreen()">
              <img src="@/assets/expendWindow.png" />
            </div>
            <div class="closeWindow" @click="closeRight()">
              <img src="@/assets/close.png" />
            </div>
          </div>
          <PreViewOffice ref="preViewOfficeRef" />
        </div>
      </Row>
    </div>
  </div>
</template>
<script>
  import { Button, Col, Input, Row, Divider } from "view-design";
  import Cookies from "js-cookie";
  import { MarkdownPreview } from "vue-meditor";
  import AnswerBox from "@/components/AnswerBox_file/AnswerBox.vue";
  import Feedback from "@/components/feedback/feedback.vue";
  import AudioLine from "@/components/audioLine/index.vue";
  import HistoryList from "@/components/historyListVersion/historyList_smartWaterSys/historyList.vue";
  import PreViewOffice from "@/components/preViewOffice/preViewOffice.vue";
  import ciicDefaultChat from "@/assets/file/ciicDefaultChat";
  import ciicFileContent from "@/assets/file/kimiFileContent";

  import Speech from "speak-tts";
  import "./chatPage.less";
  import { getStore, setStore } from "@/libs/storage";
  import { addOrEditHistory2, chatWithZG, checkKeyToken, checkOutToken } from "@/api/api_1.js";

  const icon1 = require("@/assets/icon1.png");
  const icon2 = require("@/assets/icon2.png");
  const icon3 = require("@/assets/icon3.png");
  export default {
    components: {
      Row,
      Col,
      Input,
      Button,
      MarkdownPreview,
      Feedback,
      PreViewOffice,
      AudioLine,
      HistoryList,
      AnswerBox,
    },
    data() {
      return {
        inputRowsHeight: 100,
        inputRowsClass: "",
        showCodeSuggestion: false,
        showCode: false,
        showDefault: true,
        speech: null,
        showRight: false,
        fullPage: false,
        ZGloading: false,
        historyListKey: true,
        userInfo: "",
        audioNum: null,
        audio: null,
        recording: false,
        scrollContainerHeight: 0,
        formData: {
          chatInput: "",
        },
        chatObject: {
          timeLimit: false,
          timeLimitText: "",
        },
        strBefore: "",
        pauseAnswer: true,
        //   status 状态码
        //     -1：接口 catch err
        //     0: 请求中
        //     2: 正常
        currentId: "",
        currentListHistory: [], //显示的列表
        currentPostList: [], //发送数据的的列表
        defaultChatList: [],
        defaultHotChatList: [],
      };
    },
    methods: {
      // 初始化
      initData() {
        if (this.audio !== null) {
          this.audio.pause();
          this.audio = null;
        }
        this.currentId = "";
        this.currentListHistory = [];
        this.currentPostList = [];
        this.showRight = false;
        this.fullPage = false;
        this.formData.chatInput = "";
        this.showDefault = true;
        this.chatObject = {
          timeLimit: false,
          timeLimitText: null,
        };
      },
      // 添加水印
      addWaterMark() {
        // 创建画布
        function createCanvas(width, height) {
          // 创建一个画布
          let canvas = document.createElement("canvas");
          // 设置画布的宽高
          canvas.width = width;
          canvas.height = height;
          return {
            canvas,
            ctx: canvas.getContext("2d"),
          };
        }

        // 填充水印
        function fillMark(canvas, html) {
          html[0].style.pointerEvents = "none";
          html[0].style.background = "url(" + canvas.toDataURL("image/png") + ") left top repeat";
        }
        // 文字水印生成
        function watermarkText(html, str, options = {}) {
          let {
            fontSize = 14, // 设置字体大小
            fontFamily = "微软雅黑", // 设置字体
            width = str.length * fontSize, // 设置画布的宽
            height = 120, // 设置画布的高
            color = "rgba(0, 0, 0, 0.1)", // 设置填充绘画的颜色/渐变模式
            angle = -20, // 旋转角度
            textAlign = "left", // 设置文本内容的对齐方式
            font, // 设置字体及大小
            textBaseline = "Middle", // 设置文本基线
            start = -120, // 开始绘制文本的X坐标位置
            end = height / 1.2, // 开始绘制文本的Y坐标位置
          } = options;

          // 创建画布
          let cans = createCanvas(width, height);
          let canvas = cans.canvas;
          let ctx = cans.ctx;
          ctx.rotate((angle * Math.PI) / 180);
          ctx.font = font || fontSize + "px " + fontFamily;
          ctx.fillStyle = color;
          ctx.textAlign = textAlign;
          ctx.textBaseline = textBaseline;
          // 在画布上绘制填色的文本（输出的文本，开始绘制文本的X坐标位置，开始绘制文本的Y坐标位置）
          ctx.fillText(str, start, end);
          // 填充水印
          fillMark(canvas, html);
        }
        let html = document.getElementsByClassName("watermark");
        watermarkText(html, "智工生成内容仅供参考", { width: 240, start: 0 });
      },
      // 反馈功能
      feedback() {
        if (this.ZGloading == true) {
          return;
        }
        this.$refs.feedbackRef.showModal = true;
      },
      getAvatarClass(cItem) {
        if (cItem.answerList.length <= 1) {
          return "chatList-avatar";
        } else {
          return "chatList-avatar-zszq";
        }
      },
      getClass() {
        if (this.fullPage) {
          return "hide";
        }
        if (this.showRight == true) {
          // 给个名字
          return "chat-left fullChat";
        } else {
          return "chat-left";
        }
      },
      clearHistory() {
        if (this.ZGloading == true) {
          return;
        }
        // 初始化
        this.initData();
        // this.$refs.historyListRefWeb.current.index = -1;
      },
      // 默认列表回答，大概要删掉
      askQuestion(data) {
        if (this.ZGloading == true) {
          return;
        }
        this.formData.chatInput = data;
        // 执行聊天操作
        this.askZhiGong();
      },
      // 更换聊天模式
      changeChatType() {
        if (this.ZGloading == true) {
          return;
        }
        // 清空携带聊天记录的列表
        this.currentPostList = [];
        this.$Message.info("已取消前文联想");
      },

      // chat功能
      async askZhiGong(event) {
        // this.loading
        // 确定是否是在输入文字
        /**
         * 只能用keyDown模式，keyUp模式不会触发229。
         */

        if (event && event.keyCode == 229) {
          return;
        }
        // 上一条正在加载的时候不允许再次发送
        if (this.ZGloading == true) {
          return;
        }
        this.showRight = false;
        let _this = this;
        let inputText = this.formData.chatInput;

        // 1.不能输入空信息
        function isNull(str) {
          let strNull = str.replace(/\n/g, "");
          if (strNull == "" || strNull == " " || "\n".indexOf(strNull) >= 0) {
            return true;
          }
          var regu = "^[ ]+$";
          var re = new RegExp(regu);
          return re.test(str);
        }
        if (isNull(inputText) === true) {
          this.formData.chatInput = "";
          this.$Message.error("输入内容不能为空");
          return;
        }
        // 2.如果上一个问题还没返回结果，直接return，或者提示正在思考
        const isReturn = this.isReturnAnswer();
        if (isReturn === false) {
          this.$Message.info("智工正在思考中，请稍等。");
          return;
        }

        // 传历史数据对话
        let params = this.getParams(inputText);

        // 3.调取接口前
        this.ZGloading = true;
        this.currentPostList.push({
          question: inputText,
          answer: "智工正在思考中...",
        });
        this.currentListHistory.push({
          question: inputText,
          answer: "智工正在思考中...", // <div class='ball-pulse' ><div></div></div> 闪烁图标
          answerList: [],
          pathList: [],
          keywordList: [],
          status: 0,
          agree: false,
          disagree: false,
          speak: false,
          pause: false,
          loading: true,
          time: new Date().toLocaleString(),
        });
        let answerIndex = this.currentListHistory.length - 1;
        let postIndex = this.currentPostList.length - 1;
        this.showDefault = false;
        setTimeout(() => {
          this.scrollToBottom2();
          this.scrollToBottom();
        }, 500);
        this.recording = false;

        // 定义结束标记
        function endChat() {
          if (_this.currentListHistory[answerIndex] !== undefined) {
            _this.currentListHistory[answerIndex]["loading"] = false;
          }
          _this.ZGloading = false;
          _this.pauseAnswer = true;
          _this.scrollToBottom2();
          setTimeout(_this.scrollToBottom(), 500);
          // 提交历史数据
          _this.insertOrUpdateHistory();
          return "end";
        }

        // 4. 访问我们的接口
        // 先本地匹配看有没有
        const csvAnswer = this.matchQuestion(inputText);
        if (csvAnswer !== null) {
          setTimeout(() => {
            let prevText = csvAnswer.answer;
            // 识别不到的代码块怎么处理
            if (prevText.indexOf("VAR_INPUT ") == 0 || prevText.indexOf("SELECT") >= 0 || prevText.indexOf("var") >= 0) {
              prevText = "``` \n" + prevText + "\n```";
            }
            this.clickKeyBoard(prevText, answerIndex, postIndex);
          }, 2000);
          // 历史记录不需要这个数据
          this.currentPostList.splice(postIndex, 1);
          this.formData.chatInput = "";
          this.strBefore = "";
          return;
        } else {
          let userInfo = Cookies.get("userInfo");
          setTimeout(() => {
            this.formData.chatInput = "";
          }, 500);
          this.strBefore = "";

          // kimi接口
          async function zqFetchDataKimi() {
            try {
              // 识别 内容 LM 或者  LK
              let ciicFileContentObj = "";
              let kimiArray =[];

              //  智慧水务
              kimiArray =["UserManual_for_ZWG_Stable_Voltage_Compensation_Non_negative_Pressure_Water_Supply_Equipment.pdf"];
              ciicFileContentObj = ciicFileContent.smartWater.content;

              // if (inputText.includes("LK") == true || inputText.includes("和利时") == true) {
              //   kimiArray = ['LKS_Security_Control_Production_Manual(V2).pdf'];
              //   ciicFileContentObj = ciicFileContent.LK.content;
              // } else if (inputText.includes("LM") == true) {
              //   kimiArray = ['LM_PLC_Programmable_Controler_Hardware_Manual.pdf'];
              //   ciicFileContentObj = ciicFileContent.LM.content;
              // } else if (inputText.includes("NX") == true) {
              //   ciicFileContentObj = ciicFileContent.NX.content;
              //   kimiArray = ['csm_nx-NX.pdf']
              // } 
              let messageskimi = []
              if(ciicFileContentObj.length===0){
                messageskimi=[
                { role: "user", content: inputText },
                ]
              }else{
                messageskimi = [
                  {
                    role: "system",
                    content: ciicFileContentObj,
                  },
                  { role: "user", content: inputText },
                ]
              }

              const testParams = {
                model: "moonshot-v1-128k",
                messages: messageskimi,
                temperature: 0.3,
                stream: true,
              };

              fetch("https://api.moonshot.cn/v1/chat/completions", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "sk-schNtx2Mx42uoeKr8THEaEVA8wRxnnMijA3WnEg3fLqcIH1w",
                },
                // mode: "cors",
                body: JSON.stringify(testParams),
              })
                .then(async (response) => {
                  // debugger;
                  console.log(response, "streamResult");
                  if (response.status !== 200) {
                    _this.$Message.error(response.statusText);
                    _this.currentListHistory[answerIndex]["answer"] = response.statusText;
                    _this.currentListHistory[answerIndex]["status"] = -1;
                    _this.currentPostList.splice(postIndex, 1);
                    endChat();
                    return;
                  }
                  const reader = response.body.getReader();
                  const decoder = new TextDecoder("utf-8");
                  let data = "";
                  let finalData = "";
                  let bytesLength = 0;
                  // 在 body 下载时，一直为无限循环
                  _this.pauseAnswer = false;
                  reader.read().then(function processResult(result) {
                    // console.log(result, "流式结果");
                    // 全部返回结果
                    if (result.done) {
                      // 聊天次数为0后
                      // token失效后
                      if (data.indexOf('"error":"Token') > -1) {
                        _this.$Message.error(JSON.parse(data).error);
                        Cookies.remove("userInfo");
                        Cookies.remove("accessToken");
                        setStore("accessToken", "");
                        setStore("vip", "");
                        _this.$router.push("/homeView_1");
                      }

                      _this.currentListHistory[answerIndex]["pathList"] = [];
                      _this.currentListHistory[answerIndex]["keywordList"] = kimiArray;
                      _this.currentListHistory[answerIndex]["status"] = 2;
                      _this.currentListHistory[answerIndex]["loading"] = false;

                      console.log("结束了");
                      endChat();
                      // 这儿需要停掉read模式，否则报错
                      return;
                    }
                    if (result.value !== undefined) {
                      data += decoder.decode(result.value || "", { stream: true });
                    }
                    let messages = data.split("\n\n");
                    // let messages = data;
                    if (data.endsWith("\n\n")) {
                      data = "";
                    } else {
                      data = messages.pop();
                    }
                    for (let message of messages) {
                      if (_this.pauseAnswer === true) {
                        _this.currentListHistory[answerIndex]["answerList"] = [finalData.replace(/\\n/g, "\n")];
                       

                        _this.currentListHistory[answerIndex]["answer"] = finalData;
                        _this.currentPostList[postIndex]["answer"] = finalData;
                        _this.currentListHistory[answerIndex]["status"] = 2;
                        _this.currentListHistory[answerIndex]["loading"] = false;
                        endChat();
                        return;
                      }

                      // 使用正则表达式匹配出符合要求的部分
                      const match = message.match(/"content":"([^"]+)"/);

                      if (match) {
                        // 提取出 content 的值
                        const content = match[1];
                        // console.log(content, "回答");
                        finalData += content;
                      } else {
                        console.log("未找到 content 数据");
                      }
                      // console.log([finalData], "最终结果");
                      _this.currentListHistory[answerIndex]["answerList"] = [finalData.replace(/\\n/g, "\n")];
                      

                      // _this.currentListHistory[answerIndex]["islocal"] = 2;
                      // _this.currentPostList[postIndex]["answer"] = finalData;
                      // _this.currentPostList[postIndex]["islocal"] = 2;
                      _this.currentListHistory[answerIndex]["status"] = -1;
                      _this.currentListHistory[answerIndex]["loading"] = true;
                      _this.scrollToBottom2();
                      bytesLength = finalData.replace(/[^\x00-\xff]/g, "xx").length;
                      if (bytesLength >= 2000) {
                        endChat();
                        return;
                      }
                      if (_this.currentListHistory[answerIndex] !== undefined) {
                        _this.currentListHistory[answerIndex]["loading"] = false;
                      }
                      _this.ZGloading = false;
                      _this.pauseAnswer = false;
                    }
                    return reader.read().then(processResult);
                  });
                })
                .catch((err) => {
                  console.log(err, "什么错误");
                  _this.currentListHistory[answerIndex]["answer"] = "请求错误，请重新尝试";
                  _this.currentListHistory[answerIndex]["status"] = -1;
                  endChat();
                });

              return;
            } catch (error) {
              console.log(error, "shenme");
              _this.currentListHistory[answerIndex]["answer"] = "智工无法回复此问题";
              _this.currentListHistory[answerIndex]["status"] = -1;
              _this.currentPostList.splice(postIndex, 1);
              endChat(0);
            }
          }
          setTimeout(() => {
            _this.$refs.AnswerBoxRef[answerIndex].renderLoadingText();
          }, 500);
          zqFetchDataKimi();
          return;

          // // 我们自己的接口
          // async function zqFetchData() {
          //   try {
          //     const response = await fetch(`https://qa.ciigpt.com:5080/query-example?query=${inputText}`);
          //     const resData = await response.json();

          //     // console.log(response,_this.currentListHistory[answerIndex], "回答的结果")
          //     if (response.status === 200) {
          //       if (_this.currentListHistory !== undefined) {
          //         // console.log(resData, "获得的结果");
          //         // 将答案根据"<#>"分成数组，并去掉空的
          //         const answer = resData.output_all;
          //         const answerArray = answer.split("<#>").filter((item) => item !== "");

          //         const path = resData.path_all;
          //         const pathArray = path.split("<#>").filter((item) => item !== "");

          //         const keyword = resData.seek_all;
          //         const keywordArray = keyword.split("<#>").filter((item) => item !== "");
                  

          //         _this.currentListHistory[answerIndex]["answerList"] = answerArray;
          //         _this.currentListHistory[answerIndex]["pathList"] = pathArray;
          //         _this.currentListHistory[answerIndex]["keywordList"] = keywordArray;
          //         _this.currentListHistory[answerIndex]["status"] = 2;

          //         _this.currentListHistory[answerIndex]["islocal"] = 2;
          //         _this.currentListHistory[answerIndex]["loading"] = false;
          //         _this.currentPostList[postIndex]["islocal"] = 2;
          //         _this.currentPostList[postIndex]["status"] = 2;
                 
          //         // 处理文件结果
          //         if (resData.query && resData.query.length > 0) {
          //           let filesArray = [];
          //           resData.query.forEach((item) => {
          //             filesArray.push(item.metadata.source);
          //           });
          //           _this.currentListHistory[answerIndex]["fileList"] = [];
          //           _this.currentPostList[postIndex]["fileList"] = [];
          //         }
          //       }
          //       endChat(1);
          //     } else {
          //       // 这儿错误信息还没定义
          //       if (_this.currentListHistory !== undefined) {
          //         _this.currentListHistory[answerIndex]["answer"] = response.statusText;
          //         _this.currentListHistory[answerIndex]["status"] = -1;
          //         _this.currentPostList.splice(postIndex, 1);
          //       }
          //       _this.$message.error(response.statusText);
          //       endChat(0);
          //     }
          //   } catch (error) {
          //     console.log(error.message, error.code, "错误")

          //     // if (_this.currentListHistory !== undefined) {
          //     _this.currentListHistory[answerIndex]["answer"] = "智工无法回复此问题";
          //     _this.currentListHistory[answerIndex]["status"] = -1;
          //     _this.currentPostList.splice(postIndex, 1);
          //     // }
          //     endChat(0);
          //   }
          // }
          // setTimeout(() => {
          //   _this.$refs.AnswerBoxRef[answerIndex].renderLoadingText();
          // }, 500);
          // zqFetchData();
          // return;
        }
      },
      // 重新生成
      reAskZG(event) {
        let postIndex = this.currentPostList.length - 1;
        let answerIndex = this.currentListHistory.length - 1;
        this.formData.chatInput = this.currentListHistory[answerIndex]["question"];
        this.currentListHistory.splice(answerIndex, 1);
        this.currentPostList.splice(postIndex, 1);
        this.askZhiGong();
      },

      insertOrUpdateHistory() {
        let history = [];
        this.currentListHistory.map((item) => {
          history.push({
            question: item.question,
            answer: item.answer,
            answerList: item.answerList,
            time: item.time,
            pathList: item.pathList,
            keywordList: item.keywordList,
          });
        });
        //classification : 1: 1.0.0, 2:电力， 3：plc , 4: 多模态
        let params = {
          id: this.currentId,
          historys: JSON.stringify(history),
          classification: 5,
        };

        addOrEditHistory2(params).then((res) => {
          if (res.success) {
            this.currentId = res.result.id;
          }
          if (res.message == "用户未登录") {
            this.$Message.error(res.message);
            setTimeout(() => {
              this.$router.push("/homeView_1");
            }, 2000);
          }
          this.$refs.historyListRefWeb.initHistory();
        });
      },
      // 前端打字效果
      clickKeyBoard(textWord, answerIndex, postIndex) {
        let i = 0; // 记录目前的字符数
        this.formData.chatInput = "";
        const intervalId = setInterval(() => {
          this.currentListHistory[answerIndex]["answer"] = textWord.slice(0, i);
          this.currentListHistory[answerIndex]["answerList"] = [textWord.slice(0, i)];
          // console.log(this.currentListHistory[answerIndex]["answer"], "当前回答");
          this.ZGloading = false;
          this.currentListHistory[answerIndex]["loading"] = false;
          // 滑动到底部
          this.scrollToBottom2();
          i++;
          if (i > textWord.length) {
            this.currentListHistory[answerIndex]["loading"] = false;
            this.currentListHistory[answerIndex]["status"] = 2;
            this.ZGloading = false;
            this.scrollToBottom2();
            clearInterval(intervalId);
          }
        }, 60);
      },

      // 是否返回结果
      isReturnAnswer() {
        let chLength = this.currentListHistory.length;
        if (chLength > 0 && this.currentListHistory[chLength - 1]["status"] === 0) {
          return false;
        } else {
          return true;
        }
      },

      // 下滑至底部
      scrollToBottom() {
        let _this = this;
        let scroll_to_bottom = document.getElementsByClassName("scrollContainer");
        if (scroll_to_bottom == null || scroll_to_bottom == undefined) {
          scroll_to_bottom = document.getElementsByClassName("scrollContainer-full");
        }
        let chatBottom = document.getElementById("chatBottom");
        function scrollBottom(element) {
          if (element[0] == null || element[0] == undefined) {
            return;
          } else {
            if (element[0].scrollTo) {
              element[0].scrollTo({
                top: element[0].scrollHeight + 1000,
                // top: 9000,
                behavior: "smooth",
              });
              element[0].scrollIntoView(true);
            } else {
            }
          }
        }
        setTimeout(scrollBottom(scroll_to_bottom), 70);
        // setTimeout(scrollBottom(chatBottom,scroll_to_bottom), 70);
      },

      // 监听 scroll 高度
      scrollToBottom2() {
        let scroll_to_bottom = document.getElementsByClassName("scrollContainer");
        if (scroll_to_bottom == null || scroll_to_bottom == undefined) {
          scroll_to_bottom = document.getElementsByClassName("scrollContainer-full");
        }
        if (scroll_to_bottom == null || scroll_to_bottom == undefined) {
          return;
        }
        if (scroll_to_bottom[0].scrollHeight !== undefined) {
          this.scrollContainerHeight = scroll_to_bottom[0].scrollHeight;
          // return scroll_to_bottom[0].scrollHeight
        }
      },

      playTTS(data, index, type) {
        if (this.audio !== null) {
          this.audio.pause();
          this.audio = null;
          // this.currentListHistory.forEach((cItem, cIndex) => {
          //     cItem.pause = true;
          //     cItem.speak = false;
          // });
        }

        let _this = this;
        let ttsText = data.toString();
        ttsText = ttsText.replace(/`/g, "");
        ttsText = ttsText.replace(/#/g, "");
        ttsText = ttsText.replace(/\n/g, "");
        ttsText = ttsText.replace(/\r/g, "");
        ttsText = ttsText.replace(/ /g, "");
        ttsText = ttsText.replace(/\//g, "");
        ttsText = ttsText.replace(/&/g, "");
        ttsText = ttsText.replace(/$/g, "");
        _this.audio = null;
        _this.audio = new Audio("http://dict.youdao.com/dictvoice?audio=" + encodeURIComponent(ttsText) + "&le=zh");
        let audioPlay = _this.audio.play();
        if (audioPlay !== undefined) {
          audioPlay.then(() => {
            _this.audio.play();
            _this.audioNum = index;
            let listenPause = setInterval(() => {
              if (_this.audio !== null && _this.audio.paused == true) {
                _this.currentListHistory[index]["speak"] = false;
                _this.audioNum = null;
                clearInterval(listenPause);
              } else if (_this.currentListHistory.length > 0 && _this.currentListHistory[index] !== undefined) {
                _this.currentListHistory[index]["speak"] = true;
              } else {
                clearInterval(listenPause);
                this.audioNum = null;
              }
            }, 200);
            listenPause;
          });
        }
        return;
      },
      stopTTS(data, index, type) {
        if (this.audio !== null) {
          this.audio.pause();
        }
        this.currentListHistory[index]["speak"] = false;
      },

      // 语音功能
      speechInit() {
        this.speech = new Speech();
        this.speech.setLanguage("zh-CN");
        this.speech
          .init({
            volume: 1,
            lang: "zh-CN",
            rate: 0,
            pitch: 0,
          })
          .then(() => {});
      },

      //语音播报
      speakText(data, index, type) {
        // 开始朗读
        if (type === "speak") {
          this.currentListHistory.forEach((item) => {
            item.speak = false;
            item.pause = false;
          });
          this.speech.cancel();
          this.speech
            .speak({
              text: data.answer,
              listeners: {
                onstart: () => {},
                onend: () => {},
              },
            })
            .then(() => {
              this.currentListHistory[index]["speak"] = false;
              this.currentListHistory[index]["pause"] = false;
            });

          this.currentListHistory[index]["speak"] = true;
          this.currentListHistory[index]["pause"] = false;
          return;
        }
        if (type === "cancel") {
          // 取消
          this.speech.cancel();
          this.currentListHistory[index]["speak"] = false;
          this.currentListHistory[index]["pause"] = false;
          return;
        }
        // 暂停
        if (type === "pause") {
          this.speech.pause();
          this.currentListHistory[index]["pause"] = true;
          return;
        }
        // 继续
        if (type === "resume") {
          this.speech.resume();
          this.currentListHistory[index]["pause"] = false;
          return;
        }
      },
      // 点赞功能
      agreeOrDisagree(data, index, key) {
        switch (key) {
          case "agree":
            if (this.currentListHistory[index][key] == false) {
              this.currentListHistory[index]["disagree"] = false;
            }
            break;
          default:
            if (this.currentListHistory[index][key] == false) {
              this.currentListHistory[index]["agree"] = false;
            }
            break;
        }
        this.currentListHistory[index][key] = !data[key];
      },
      getParams(inputText) {
        let params = {
          query: inputText,
          historys: [],
        };
        let bytesLens = 0;
        let bytesLenQuery = inputText.replace(/[^\x00-\xff]/g, "xx").length;
        for (let i = this.currentPostList.length; i >= 0; i--) {
          if (i == 0) {
            return params;
          }
          // 处理字节长度的问题
          let bytesLenQ = this.currentPostList[i - 1]["question"].replace(/[^\x00-\xff]/g, "xx").length;
          let bytesLenA = this.currentPostList[i - 1]["answer"].replace(/[^\x00-\xff]/g, "xx").length;
          bytesLens = bytesLenQuery + bytesLens + bytesLenQ + bytesLenA;
          if (bytesLens < 1200) {
            params.historys.unshift({
              question: this.currentPostList[i - 1]["question"],
              response: this.currentPostList[i - 1]["answer"],
            });
          } else {
            return params;
          }
        }
      },

      ///点击复制
      copyData(data) {
        var oInput = document.createElement("input"); //创建一个input标签
        oInput.value = data; //将要复制的值赋值给input
        document.body.appendChild(oInput); //在页面中插入
        oInput.select(); // 模拟鼠标选中
        document.execCommand("Copy"); // 执行浏览器复制命令（相当于ctrl+c）
        oInput.style.display = "none"; //只是用一下input标签的特性，实际并不需要显示，所以这里要隐藏掉
        this.$Message.success("复制成功");
      },
      // footer 功能
      clickFooterButton(data, dItem, key, answer) {
        switch (key) {
          case "openWindow":
            this.$refs.preViewOfficeRef.tabList = [];
            this.preFile(data, dItem);

            break;
          case "copy":
            this.copyData(answer);
            break;
          default:
            break;
        }
      },
      // 预览文件
      preFile(data, dItem) {
        this.$refs.preViewOfficeRef.answerData = data.answer;
        let _this = this;
        function pushList(params, type, name) {
          _this.$refs.preViewOfficeRef.tabList.push({
            name: params,
            tabDatatype: type,
            src: params,
            data: params,
          });
        }

        let strMarkdown = this.markdownString(data.answer);
        // 循环给右侧栏展示
        let strHttpArr = this.httpString(data.answer);

        if (strMarkdown !== null || data.answer.indexOf("// ") > 0 || strHttpArr == null) {
          this.$refs.preViewOfficeRef.tabList.push({
            name: "编辑",
            tabDatatype: "markdown",
            src: "",
            data: data.answer,
          });
        }
        if (strHttpArr !== null) {
          strHttpArr.forEach((sItem, sIndex) => {
            // 识别文件类型
            if (sItem.indexOf(".pdf") >= 0) {
              pushList(sItem, "pdf", sItem);
            } else if (sItem.indexOf(".docx") >= 0 || sItem.indexOf(".doc") >= 0) {
              pushList(sItem, "word", sItem);
            } else if (sItem.indexOf(".xlsx") >= 0) {
              pushList(sItem, "excel", sItem);
            } else {
              pushList(sItem, "html", sItem);
            }
          });
        }

        this.showRight = true;
      },
      //
      // 关闭工作台
      closeRight() {
        this.showRight = false;
        this.fullPage = false;
        this.$refs.preViewOfficeRef.tabList = [];
      },
      // 全屏
      fullScreen() {
        this.fullPage = !this.fullPage;
      },
      // 退出登录
      exitbtn(v) {
        if (v === "logout") {
          let userInfo = Cookies.get("userInfo");
          checkOutToken(`${JSON.parse(userInfo).accessToken}/${getStore("cur")}`)
            // fetch(`http://101.200.140.188:38892/check_out/${JSON.parse(userInfo).accessToken}/${getStore("cur")}`)
            .then((response) => {
              // infoRes.json().then((response) => {
              this.flag = !this.flag;
              this.vip = "";
              this.isId = "";
              Cookies.remove("userInfo");
              Cookies.set("accessToken", "");
              setStore("vip", "");
              setStore("uid", "");
              setStore("accessToken", "");
              this.$router.push("/homeView_1");
              // });
            });
        } else if (v === "userinfo") {
        }
      },
      // 格式化字符串
      replaceStr(Str) {
        let b = Str.replace(/。/g, "。<br/><br/>");
        return b;
      },

      // 正则获取 返回地址中的链接
      httpString(s) {
        if (s === undefined || s === null) {
          return [];
        }

        let reg = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]+/g;

        let regCh = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]+[\u4e00-\u9fa5]+/g;
        let regS = s.match(regCh);
        if (regS === null) {
          regS = s.match(reg);
        }
        return regS;
      },
      // 正则获取 返回地址中的代码块
      markdownString(s) {
        if (s === undefined || s === null) {
          return null;
        }
        let reg = /(```)/g;
        s = s.match(reg);
        return s;
      },

      // 正则匹配答案
      matchQuestion(text) {
        // console.log(text,"wenti ")
        let returnData = null;
        let RDType = 0;
        for (let i = 0; i < ciicDefaultChat.length; i++) {
          // console.log(ciicDefaultChat[i]["ask"],ciicDefaultChat[i]["ask"].includes(text) ,"是否有值")
          if (ciicDefaultChat[i]["ask"].includes(text) == true) {
            returnData = ciicDefaultChat[i];
            // 显示工作台按钮
            ciicDefaultChat[i]["status"] = 2;
            break;
          }
        }
        // console.log(returnData,"wenti")
        return returnData;
      },
      // 插入历史记录
      setCurrentListHistory(v) {
        if (this.ZGloading) {
          this.$Message("请稍等");
          return;
        }
        // 初始化掉所有功能

        this.scrollContainerHeight = 0;
        if (this.audio !== null) {
          this.audio.pause();
          this.audio = null;
          this.audioNum = null;
        }
        switch (v.name) {
          case "currentId":
            this.currentId = v.value;
            break;
          case "historyList":
            if (v.value.length > 0) {
              this.showDefault = false;
            } else {
              this.showDefault = true;
            }
            this.currentListHistory = v.value;
            break;
          default:
            break;
        }
        this.currentPostList = [];
        setTimeout(() => {
          this.scrollToBottom();
          this.scrollToBottom2();
        }, 500);
      },

      // 语音输入文本
      recordInputText() {
        const button = document.getElementById("recordButton");
        const button1 = document.getElementById("recordButton1");
        this.recording = false;

        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (typeof SpeechRecognition !== "undefined") {
          const recognition = new SpeechRecognition();
          const stop = () => {
            recognition.stop();
            this.strBefore = "";
            this.askZhiGong();
          };
          const start = () => {
            recognition.start();
            this.formData.chatInput = "";
            this.strBefore = "";
          };
          const onResult = (event) => {
            if (this.recording == false) {
              recognition.stop();
              this.strBefore = "";
            }
            for (const res of event.results) {
              const text = document.createTextNode(res[0].transcript);
              if (this.recording) {
                this.formData.chatInput = this.strBefore + text.data;
              }
              if (res.isFinal) {
                this.strBefore = text.data;
              }
            }
          };
          recognition.continuous = true;
          recognition.interimResults = true;
          recognition.addEventListener("result", onResult);
          button.addEventListener("click", (event) => {
            this.recording ? stop() : start();
            this.recording = !this.recording;
          });
          button1.addEventListener("click", (event) => {
            this.recording ? stop() : start();
            this.recording = !this.recording;
          });
        } else {
          button.remove();
          button1.remove();
          this.$Message.info("您的设备不支持语音输入");
        }
      },
      startRecord() {},
      // 是否移动端
      _isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        return flag;
      },
      // 随机默认问题
      getRandomElements() {
        let defaultQuestions = require("@/assets/file/defaultPLCQuestions");
        let arr = defaultQuestions.default;
        const result = [];
        const len = arr.length;
        for (let i = 0; i < 6; i++) {
          const randomIndex = Math.floor(Math.random() * len);
          result.push(arr[randomIndex]);
        }
        this.defaultHotChatList = result;
      },
      changeInputRowsHeight(type) {
        switch (type) {
          case "on":
            this.inputRowsClass = "searchInputFocus";
            break;
          case "off":
            this.inputRowsClass = "";
            break;
        }
      },
    },
    beforeMount() {
      let userInfo = Cookies.get("userInfo");
      checkKeyToken(JSON.parse(userInfo).accessToken)
        // fetch(`http://101.200.140.188:38892/check_key/${JSON.parse(userInfo).accessToken}`)
        .then((res) => {
          // infoRes.json().then((res) => {
          if (res.exists !== undefined) {
            setStore("vip", res.vip);
            setStore("uid", res.username);
            setStore("id", res.id);
            setStore("cur", res.cur);
            if (res.vip !== 1 || res.id.length == 0) {
              this.$Message.error("暂无权限，请耐心等待排队通过");
              setTimeout(() => {
                this.$router.push("/homeView_1");
              }, 200);
              return;
            }
          } else {
            this.$Message.error("登录过期，请重新登录");
            this.vip = "";
            this.isId = "";
            Cookies.remove("userInfo");
            Cookies.remove("accessToken");
            setStore("accessToken", "");
            setStore("vip", "");
            this.$router.push("/homeView_1");
          }
          // });
        });
    },
    mounted() {
      this.addWaterMark();
      this.getRandomElements();
      if (this._isMobile()) {
        this.$router.replace("/chatWithZGApp_zszq");
      } else {
        // this.$router.replace("/chatWithZG");
      }
      // 长按
      document.documentElement.addEventListener(
        "touchstart",
        function (event) {
          if (event.touches.length > 1) {
            event.preventDefault();
          }
        },
        { passive: false }
      );
      // 禁止双击放大
      let lastTouchEnd = 0;
      document.documentElement.addEventListener(
        "touchend",
        function (event) {
          var now = Date.now();
          if (now - lastTouchEnd <= 300) {
            event.preventDefault();
          }
          lastTouchEnd = now;
        },
        { passive: false }
      );
      let userInfo = Cookies.get("userInfo");
      if (!userInfo) {
        this.$Message.error("请先登录");
        setTimeout(() => {
          this.$router.push("/homeView_1");
        }, 1000);
        return;
      } else {
        let scd = JSON.parse(userInfo).mobile;
        const start = scd.substr(0, 3);
        const middle = "*****";
        const end = scd.substr(8, 3);
        this.userInfo = `${start}${middle}${end}`;
        this.accToken = JSON.parse(userInfo).accessToken;
      }
      this.speechInit();
      // 监听语音输入事件
      // this.recordInputText();

      // let scroll_to_bottom = document.getElementsByClassName("scrollContainer");
      // if (scroll_to_bottom == null || scroll_to_bottom == undefined) {
      //   scroll_to_bottom = document.getElementsByClassName(
      //     "scrollContainer-full"
      //   );
      // }
    },
    computed: {},
    watch: {
      scrollContainerHeight(newH, oldH) {
        this.scrollToBottom();
      },
    },
  };
</script>
<style lang="less" scoped></style>
