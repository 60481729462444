import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HomeView_1 from '../views/HomeView_1.vue'
import ChatView from '../views/chatPage/chatPage.vue'
import ChatView_1 from '../views/chatPage_1/chatPage.vue'
import ChatView6606 from '../views/chatPage6606/chatPage.vue'
import ChatView6606_7b from '../views/chatPage6606_7b/chatPage.vue'
import ChatView_dmt from '../views/chatPage_dmt/chatPage.vue'
import ChatView_zjxt from '../views/chatPage_zjxt/chatPage.vue'
import ChatView_zszq from '../views/chatPage_zszq/chatPage.vue'
import ChatView_smartWaterSys from '../views/chatPage_smartWaterSys/chatPage.vue'
import ChatView_smartCopilotSys from '../views/chatPage_smartCopilot/chatPage.vue'

import HomeViewApp from '../views/webapp/HomeView.vue'
import HomeViewApp_1 from '../views/webapp/HomeView_1.vue'
import ChatViewApp from '../views/webapp/chatPage/chatPage.vue'
import ChatViewApp_1 from '../views/webapp/chatPage_1/chatPage.vue'
import ChatViewApp_dmt from '../views/webapp/chatPage_dmt/chatPage.vue'
import ChatViewApp_zjxt from '../views/webapp/chatPage_zjxt/chatPage.vue'
import ChatViewApp_zszq from '../views/webapp/chatPage_zszq/chatPage.vue'
import ChatView6606App from '../views/webapp/chatPage6606/chatPage.vue'
import ChatView6606App_7b from '../views/webapp/chatPage6606_7b/chatPage.vue'
import UserInfo from '../views/webapp/userInfo'
import UserInfo_1 from '../views/webapp/userInfo_1'
// import loginin from '../components/loginpage.vue'
// import dialogue from '@/components/dialogue'
// import loginstatic from '@/components/loginstatic'
import registers from '@/components/register/registers'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/homeView_1',
    name: 'home',
    component: HomeView_1
  },
  {
    path: '/register',
    name: 'register',
    component:registers
  },
  {
    path: '/chatWithZG',
    name: 'chatWithZG',
    component:ChatView
  },
  {
    path: '/chatWithZG_dmt',
    name: 'chatWithZG_dmt',
    component:ChatView_dmt
  },
  // {
  //   path: '/chatWithZG_plc_copilot',
  //   name: 'chatWithZG_plc_copilot',
  //   component:ChatView_zszq
  // },
   
  {
    path: '/chat_smartWaterSys',
    name: 'chat_smartWaterSys',
    component:ChatView_smartWaterSys
  },
  {
    path: '/chat_smartCopilot',
    name: 'chat_smartCopilot',
    component:ChatView_smartCopilotSys
  },
  
  {
    path: '/chatWithZG_electricity_copilot',
    name: 'chatWithZG_electricity_copilot',
    component:ChatView_zjxt
  },
  {
    path: '/chatWithZG_1',
    name: 'chatWithZG_1',
    component:ChatView_1
  },
  {
    path: '/13B',
    name: '13B',
    component:ChatView6606
  },
  {
    path: '/7B',
    name: '7B',
    component:ChatView6606_7b
  },
  {
    path: '/webapp',
    name: 'webapp',
    component: HomeViewApp,
  },
  {
    path: '/webapp_1',
    name: 'webapp',
    component: HomeViewApp_1,
  },
  {
    path: '/chatWithZGApp',
    name: 'chatWithZGApp',
    component: ChatViewApp,
  },
  {
    path: '/chatWithZGApp_1',
    name: 'chatWithZGApp_1',
    component: ChatViewApp_1,
  },
  {
    path: '/chatWithZGApp_dmt',
    name: 'chatWithZGApp_dmt',
    component: ChatViewApp_dmt,
  },
  {
    path: '/chatWithZGApp_zjxt',
    name: 'chatWithZGApp_zjxt',
    component: ChatViewApp_zjxt,
  },
  // {
  //   path: '/chatWithZGApp_zszq',
  //   name: 'chatWithZGApp_zszq',
  //   component: ChatViewApp_zszq,
  // },
  {
    path: '/13bApp',
    name: 'ChatView6606App',
    component: ChatView6606App,
  },
  {
    path: '/7bApp',
    name: 'ChatView6606App',
    component: ChatView6606App_7b,
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    component: UserInfo,
  },
  {
    path: '/userInfo_1',
    name: 'userInfo_1',
    component: UserInfo_1,
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
