<template>
  <div id="rootApp">
    <div class="appHeader">
      <div>
        <a href="/#/homeView_1"><img alt="logo" class="qbUby_dV" src="@/assets/logo.png" /></a>
      </div>

      <div class="userAvatar">
        <Select v-model="defaultVersion" style="width: 120px; margin-right: 10px" @on-change="changeVersion">
          <Option v-for="item in versionList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
        <img class="avatar" @click="openHistoryDrawer" src="@/assets/logoFav.png" alt="" />
      </div>
    </div>
    <div class="appContent">
      <Row class="appContentRow">
        <Col class="defaultListApp" v-show="showDefault">
          <div class="defaultListBox" style="width: 80%">
            <div class="defaultItemTitle">
              <div class="defaultItemIcon1">
                <img src="http://test.ciigpt.com/img/defaultQIcon.png" alt="" />
              </div>
              <div class="defaultItemTitleTextBorder">
                <span class="defaultItemTitleText">热门提问</span>
                <div class="title-line"></div>
              </div>
              <div class="defaultItemIcon2" @click="getRandomElements">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAYtJREFUWEfFl22SgyAMhokX2/Ze4lbxXmUvZnbiyE6gAQPqyr9OkTxvPkgAc/OCm+2bZoC+7x8cfp5nnxMT9kp71AB0SNd1D0T8MsZExplhDwA/0zS9OIy19k3fIOIzhVABDMPwQsRvQSFXHUEBwBhAmgFINQAQfVgeEUf6IbmTQOk/DkuqAYDg6zyQqpbcV0pgyWvqECTKvXPu2VItGhFiDlhrkQzyOGoBwrfSfpUHOLVzTpWkScav8NKSzvswcES91kt8XwQQ1Le4vsX4GmbpwrgTYI1fbcm1qo88wEuvJflaIf5CwACa674F4l8BpK4oAZirQiA1pbQKLk3CcMdwgWIZXlEF7IaNcky8iIwxpydi7pKLAHgpnumFUol/9IKjzUgqxVJ/KbbjM0KxJ0gE4C470he48VxIs/0+nWZqQDYB6xy4N9gUB450KCUIOnBZFp8OpeGWCwNoyIW9ZFZNPIqxXHonqEpZBRDUbCDFh8nWzsfSSyk7EdV0s5qnWencKg/UAGr33g7wC4V2TzACbkSTAAAAAElFTkSuQmCC"
                  alt=""
                />
                <span>换一批</span>
              </div>
            </div>
            <div class="defaultItem">
              <div @click="askQuestion(qItem)" v-for="(qItem, index) in defaultChatList" :key="index">{{ qItem }}</div>
            </div>
          </div>
          <!-- <div class="defaultItem" v-for="(item, index) in defaultChatList" :key="index">
            <img class="defaultItemIcon" :src="item.icon" alt="" />
            <h3 style="text-align: center; margin-top: 40px">
              {{ item.title }}
            </h3>
            <div class="buttonGroup">
              <div class="defaultQBtn" v-for="qItem in item.questions" :key="qItem" @click="askQuestion(qItem)">
                {{ qItem }}
              </div>
            </div>
          </div> -->
        </Col>
        <Col v-show="!showDefault">
          <div class="chatList" v-for="(cItem, cIndex) in currentListHistory" :key="cIndex">
            <Row class="chatList-row">
              <Col class="chatList-avatar"> <img class="avatar" src="../../../assets/logoFav2.png" alt="" /></Col>
              <Col class="chatList-question">{{ cItem.question }}</Col>
            </Row>
            <Row class="chatList-row">
              <Col class="chatList-avatar"> <img class="avatar" src="../../../assets/logoIcon.png" alt="" /></Col>
              <div class="chatList-answer-box">
                <Col class="chatList-answer">
                  <MarkdownPreview :initialValue="cItem.answer" :copyCode="true" copyBtnText="复制" theme="dark" />
                  <div v-if="cItem.fileList !== undefined && cItem.fileList !== null && cItem.fileList.length !== 0 && cItem.islocal == 1" class="chatFileSource">
                    <p style="font-size: 18px; font-weight: bolder">相关来源：</p>
                    <div :class="'chatFileList chatFileList' + cIndex">
                      <Button @click="previewFile(fileItem, cIndex)" plain v-for="(fileItem, fileIndex) in cItem.fileList" :key="fileIndex">
                        <div class="previewFileLink" :title="fileItem.data">
                          {{ fileItem.data }}
                        </div>
                      </Button>
                    </div>
                  </div>
                  <div v-if="cItem.islocal == 0" class="chatFileSourceZG">
                    <p>--以上内容来自智工·工业大模型</p>
                  </div>
                  <div class="reloadBox" v-show="cItem.status == -1">
                    <Button type="default" @click="reAskZG" class="reAskZG" icon="ios-refresh">点击重试</Button>
                  </div>

                  <div class="divider" v-show="cItem.loading == false && cItem.status !== -1"></div>
                  <div class="footerButtonGroup" v-show="cItem.loading == false && cItem.status !== -1">
                    <div class="voiceGroup">
                      <div class="speak-footer-button" @click="playTTS(cItem, cIndex, 'speak')" v-show="audioNum !== cIndex && cItem.speak !== undefined" title="语音播放">
                        <img src="@/assets/playSound.png" alt="" />
                      </div>
                      <div class="speak-footer-button" @click="stopTTS(cItem, cIndex, 'cancel')" v-show="audioNum === cIndex" title="取消播放">
                        <img src="@/assets/stopPlay.png" alt="" />
                        <AudioLine />
                      </div>
                    </div>
                    <div class="footer-button-group">
                      <div v-show="cIndex == currentListHistory.length - 1" @click="reAskZG" class="reAskZG_text">
                        <span>重新生成</span>
                      </div>
                      <div @click="clickFooterButton(cItem, cIndex, 'copy')" class="anwser-footer-button" title="复制内容">
                        <!-- <span>复制内容 </span> -->
                        <img src="@/assets/copy.png" alt="" />
                      </div>
                      <div v-show="cItem.status == 3" @click="clickFooterButton(cItem, cIndex, 'export')" class="anwser-footer-button" title="导出内容">
                        <!-- <span>导出内容 </span> -->
                        <img src="@/assets/export.png" alt="" />
                      </div>

                      <!-- v-show="
                            cItem.status == 2 ||
                            cItem.answer.indexOf('// ') >  0 
                          " -->

                      <div @click="clickFooterButton(cItem, cIndex, 'openWindow')" class="anwser-footer-button gztBtn" title="打开工作台">
                        <span></span>
                        <img class="gztBtnImg" src="@/assets/openWindow.png" alt="" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col class="chatList-footer" v-show="cItem.loading == false && cItem.status !== -1">
                  <div class="footer-time">
                    <span>{{ cItem.time }}</span>
                  </div>
                  <div @click="agreeOrDisagree(cItem, cIndex, 'agree')">
                    <img v-if="cItem.agree" class="agreeImg" src="@/assets/like_blue.png" alt="" />
                    <img v-else class="agreeImg" src="@/assets/like_gray.png" alt="" />
                  </div>
                  <div @click="agreeOrDisagree(cItem, cIndex, 'disagree')">
                    <img v-if="cItem.disagree" class="agreeImg" src="@/assets/unlike_blue.png" alt="" />
                    <img v-else class="agreeImg" src="@/assets/unlike_gray.png" alt="" />
                  </div>
                </Col>
              </div>
            </Row>
          </div>
          <Divider class="timeLimit" v-show="chatObject.timeLimit">{{ chatObject.timeLimitText }}</Divider>
        </Col>
      </Row>
      <!-- <div id="chatBottom" ref="scrollTarget"></div> -->
      <div class="watermark"></div>
    </div>
    <div class="appFooter">
      <div class="footer-fixed-blur"></div>
      <div class="chatList chatInput" v-show="showDefault">
        <div style="position: relative">
          <Input class="searchInput" v-model="formData.chatInput" type="textarea" :rows="2" :autosize="{ minRows: 2, maxRows: 4 }" size="large" :maxlength="1024" placeholder="请在此输入您要提问的内容" @keydown.enter.native="askZhiGong" :disabled="chatObject.timeLimit"> </Input>
          <div class="chatInputButton" @click="askZhiGong">
            <img class="chatInputButtonIcon" src="../../../assets/send.png" alt="" />
          </div>
        </div>

        <!-- <div id="recordButton" @click="startRecord">
                <img
                  class="chatInputButtonIcon"
                  src="../../../assets/record.png"
                  alt=""
                  v-show="recording == false"
                />
                <img
                  class="chatInputButtonIcon"
                  src="../../../assets/stopRecord-1.png"
                  alt=""
                  title="语音输入"
                  v-show="recording == true"
                />
              </div> -->
      </div>

      <div class="chatInput chatInput1" v-show="!showDefault">
        <!-- <div
          class="chatTypeButton"
          @click="changeChatType()"
          v-if="currentPostList.length !== 0"
          title="取消前文联想"
          style="margin-right: 5px"
        >
          <img
            class="chatInputButtonIcon"
            src="../../../assets/connected.png"
            alt=""
          />
        </div>
        <div
          class="chatTypeButton"
          v-if="currentPostList.length == 0"
          title="已取消前文联想"
          style="margin-right: 5px"
        >
          <img
            class="chatInputButtonIcon"
            src="../../../assets/breaked.png"
            alt=""
          />
        </div> -->
        <div class="input1Box">
          <Input v-model="formData.chatInput" type="textarea" :rows="2" :autosize="{ minRows: 2, maxRows: 4 }" size="large" :maxlength="1024" placeholder="请在此输入您要提问的内容" @keydown.enter.native="askZhiGong" :disabled="chatObject.timeLimit"> </Input>
          <div class="chatInputButton" @click="askZhiGong">
            <img class="chatInputButtonIcon" src="../../../assets/send.png" alt="" />
          </div>
        </div>
      </div>
      <HistoryList @closeHistory="closeHistory" ref="historyListRef" />
    </div>
    <Modal v-model="previewPdfModal" fullscreen title="文档预览" :footer-hide="true">
      <bookLoading v-show="srcdoc.length == 0" style="width: 100%; height: 100%" />
      <iframe width="100%" height="100%" frameborder="0" id="knowledgeIframe1" :srcdoc="srcdoc" v-show="srcdoc.length > 0"></iframe>
    </Modal>
  </div>
</template>
<script>
  import { Button, Col, Input, Row, Divider, Drawer, Modal } from "view-design";
  import Cookies from "js-cookie";
  import { MarkdownPreview } from "vue-meditor";
  import Feedback from "@/components/feedback/feedback.vue";
  import bookLoading from "./bookLoading.vue";
  import AudioLine from "@/components/audioLine/index.vue";
  import HistoryList from "../userInfoVersion/userInfo_zjxt/index.vue";
  import PreViewOffice from "@/components/preViewOffice/preViewOffice.vue";
  import ciicDefaultChat from "@/assets/file/ciicDefaultChat";
  import Speech from "speak-tts";
  import "./chatPage.less";
  import { getStore, setStore } from "@/libs/storage";
  import { addOrEditHistory2, checkKeyToken, checkOutToken, PreviewFile2 } from "@/api/api_1.js";
  let defaultQuestions = require("../../../assets/file/defaultQuestions.js");
  const icon1 = require("@/assets/icon1.png");
  const icon2 = require("@/assets/icon2.png");
  const icon3 = require("@/assets/icon3.png");
  export default {
    components: {
      Row,
      Col,
      Input,
      Button,
      Modal,
      MarkdownPreview,
      bookLoading,
      Feedback,
      PreViewOffice,
      AudioLine,
      HistoryList,
    },
    data() {
      return {
        showDefault: true,
        speech: null,
        fullPage: false,
        ZGloading: false,
        userInfo: "",
        audioNum: null,
        audio: null,
        recording: false,
        scrollContainerHeight: 0,
        formData: {
          chatInput: "",
        },
        chatObject: {
          timeLimit: false,
          timeLimitText: "",
        },
        strBefore: "",

        //   status 状态码
        //     -1：接口 catch err
        //     0: 请求中
        //     2: 正常
        currentId: "",
        currentListHistory: [], //显示的列表
        currentPostList: [], //发送数据的的列表
        defaultChatList: [],
        versionList: [
          {
            value: "/chatWithZGApp_dmt",
            label: "多模态模型",
          },
          // {
          //   value: "/chatWithZGApp_zszq",
          //   label: "智工·PLC Copilot",
          // },
          {
            value: "/chatWithZGApp_zjxt",
            label: "智工电力规范  Copilot",
          },
          {
            value: "/chatWithZGApp_1",
            label: "智工1.0",
          },
        ],
        defaultVersion: "/chatWithZGApp_zjxt",
        knowledgeListPicked: "chanpinshouce",

        previewPdfModal: false,
        srcdoc: "",
        numberOfPages: 0,
        pageNumber: 0,
        pdfLoading: false,
        keyword: "",
        fileName: "",
      };
    },
    methods: {
      // 初始化
      initData() {
        if (this.audio !== null) {
          this.audio.pause();
          this.audio = null;
        }
        this.currentId = "";
        this.currentListHistory = [];
        this.currentPostList = [];
        this.fullPage = false;
        this.formData.chatInput = "";
        this.showDefault = true;
        this.chatObject = {
          timeLimit: false,
          timeLimitText: null,
        };
      },
      // 选择版本
      changeVersion(value) {
        console.log(value, "版本值");
        this.$router.push(value);
      },
      getRandomElements() {
        let arr = defaultQuestions.default;
        const result = [];
        const len = arr.length;
        for (let i = 0; i < 3; i++) {
          const randomIndex = Math.floor(Math.random() * len);
          result.push(arr[randomIndex]);
        }
        this.defaultChatList = result;
        return result;
      },
      // 反馈功能
      feedback() {
        if (this.ZGloading == true) {
          return;
        }
        this.$refs.feedbackRef.showModal = true;
      },

      clearHistory() {
        if (this.ZGloading == true) {
          return;
        }

        // 初始化
        this.initData();
      },
      // 默认列表回答，大概要删掉
      askQuestion(data) {
        if (this.ZGloading == true) {
          return;
        }
        this.formData.chatInput = data;
        // 执行聊天操作
        this.askZhiGong();
      },
      // 更换聊天模式
      changeChatType() {
        if (this.ZGloading == true) {
          return;
        }
        // 清空携带聊天记录的列表
        this.currentPostList = [];
        this.$Message.info("已取消前文联想");
      },

      // chat功能
      async askZhiGong(event) {
        // this.loading
        // 确定是否是在输入文字
        /**
         * 只能用keyDown模式，keyUp模式不会触发229。
         */

        if (event && event.keyCode == 229) {
          return;
        }
        // 上一条正在加载的时候不允许再次发送
        if (this.ZGloading == true) {
          return;
        }
        let _this = this;
        let inputText = this.formData.chatInput;

        // 1.不能输入空信息
        function isNull(str) {
          let strNull = str.replace(/\n/g, "");
          if (strNull == "" || strNull == " " || "\n".indexOf(strNull) >= 0) {
            return true;
          }
          var regu = "^[ ]+$";
          var re = new RegExp(regu);
          return re.test(str);
        }
        if (isNull(inputText) === true) {
          this.formData.chatInput = "";
          this.$Message.error("输入内容不能为空");
          return;
        }
        // 2.如果上一个问题还没返回结果，直接return，或者提示正在思考
        const isReturn = this.isReturnAnswer();
        if (isReturn === false) {
          this.$Message.info("智工正在思考中，请稍等。");
          return;
        }

        // 传历史数据对话
        let params = this.getParams(inputText);

        // 3.调取接口前
        this.ZGloading = true;
        this.currentPostList.push({
          question: inputText,
          answer: "智工正在思考中...",
        });
        this.currentListHistory.push({
          question: inputText,
          answer: "智工正在思考中...",
          status: 0,
          agree: false,
          disagree: false,
          speak: false,
          pause: false,
          loading: true,
          time: new Date().toLocaleString(),
        });
        let answerIndex = this.currentListHistory.length - 1;
        let postIndex = this.currentPostList.length - 1;
        this.showDefault = false;
        setTimeout(() => {
          this.scrollToBottom2();
          this.scrollToBottom();
        }, 500);
        this.recording = false;

        // 定义结束标记
        function endChat() {
          if (_this.currentListHistory[answerIndex] !== undefined) {
            _this.currentListHistory[answerIndex]["loading"] = false;
          }
          _this.ZGloading = false;
          _this.scrollToBottom2();
          setTimeout(_this.scrollToBottom(), 500);
          // 提交历史数据
          console.log(_this.currentListHistory[answerIndex]["status"]);
          if (_this.currentListHistory[answerIndex]["status"] == 2) {
            _this.insertOrUpdateHistory();
          }
          return "end";
        }

        // 4. 访问我们的接口
        // 先本地匹配看有没有
        const csvAnswer = this.matchQuestion(inputText);
        if (csvAnswer !== null) {
          setTimeout(() => {
            let prevText = csvAnswer.answer;
            // 识别不到的代码块怎么处理
            if (prevText.indexOf("VAR_INPUT ") == 0 || prevText.indexOf("SELECT") >= 0 || prevText.indexOf("var") >= 0) {
              prevText = "``` \n" + prevText + "\n```";
            }
            this.clickKeyBoard(prevText, answerIndex, postIndex);
          }, 2000);
          // 历史记录不需要这个数据
          this.currentPostList.splice(postIndex, 1);
          this.formData.chatInput = "";
          this.strBefore = "";
          return;
        } else {
          let userInfo = Cookies.get("userInfo");
          setTimeout(() => {
            this.formData.chatInput = "";
          }, 500);
          this.strBefore = "";

          // 知识库接口 （智工电力规范  Copilot）
          // collection 需要通过选择知识库目录
          let tttttext = inputText;
          let collection = _this.knowledgeListPicked;

          async function fetchData() {
            try {
              // 113.207.49.230
              const response = await fetch(`http://152.136.37.136:6606/searchr1?query=${tttttext}&collection=${collection}`);
              // const response = await fetch(`https://qa.ciigpt.com:6606/searchr1?query=${tttttext}&collection=${collection}`);
              const resData = await response.json();
              // console.log(response, "回答的结果")
              if (response.status === 200) {
                if (_this.currentListHistory !== undefined) {
                  _this.currentListHistory[answerIndex]["answer"] = resData.answer + "\n";
                  _this.currentListHistory[answerIndex]["islocal"] = resData.islocal;
                  _this.currentPostList[postIndex]["answer"] = resData.answer;
                  _this.currentPostList[postIndex]["islocal"] = resData.islocal;
                  _this.currentListHistory[answerIndex]["status"] = 2;
                  // 处理文件结果
                  if (resData.query && resData.query.length > 0) {
                    let filesArray = [];
                    resData.query.forEach((item) => {
                      filesArray.push(item.metadata.source);
                    });
                    _this.currentListHistory[answerIndex]["fileList"] = resData.query;
                    _this.currentPostList[postIndex]["fileList"] = resData.query;
                  }
                }
                endChat(1);
              } else {
                if (_this.currentListHistory !== undefined) {
                  _this.currentListHistory[answerIndex]["answer"] = response.statusText;
                  _this.currentListHistory[answerIndex]["status"] = -1;
                  _this.currentPostList.splice(postIndex, 1);
                }
                _this.$Message.error(response.statusText);
                endChat(0);
              }
            } catch (error) {
              // console.log(error.message, error.code, "错误")

              // if (_this.currentListHistory !== undefined) {
              _this.currentListHistory[answerIndex]["answer"] = "智工无法回复此问题";
              _this.currentListHistory[answerIndex]["status"] = -1;
              _this.currentPostList.splice(postIndex, 1);
              // }
              endChat(0);
            }
          }
          fetchData();
          return;
        }
      },
      listenPDFScroll() {
        let _this = this;
        // let reviewPDF = this.$store.state.chat.reviewPDF;
        if(this.pdfLoading==true){
          return;
        }
        var iframe = document.getElementById("knowledgeIframe1");
        if (iframe == null) {
          return;
        } else {
          // if(iframe.)
          iframe.onload = function () {
            iframe.contentDocument.documentElement.scrollTop = 10;
            // console.log(reviewPDF, iframe.contentWindow.pageYOffset, iframe.contentDocument.documentElement.scrollHeight, 'iframe loaded');
            iframe.contentDocument.addEventListener("scroll", () => {
              // console.log('iframe scrolled');
              var scrollTop = iframe.contentWindow.pageYOffset || iframe.contentDocument.documentElement.scrollTop;
              var scrollHeight = iframe.contentDocument.documentElement.scrollHeight;
              var clientHeight = iframe.contentDocument.documentElement.clientHeight;
              // 滚动到顶部判断条件
              if (scrollTop === 0) {
                // console.log('已滚动到顶部', reviewPDF);
                if (_this.pageNumber <= 1) {
                  console.log("已经是第一页了");
                  _this.Message.info("已经是第一页了");
                  return;
                }
                if (_this.loading == true) {
                  return;
                }
                let params = {
                  fileName: _this.fileName,
                  keyWord: "",
                  pageNumber: parseInt(_this.pageNumber) - 1,
                };
                _this.previewPDF(params);
              }

              // 滚动到底部判断条件（考虑到可能有小范围的误差，这里使用 1px 的容差）
              if (scrollTop + clientHeight >= scrollHeight - 1) {
                // console.log('已滚动到底部', reviewPDF);
                if (_this.pageNumber >= _this.numberOfPages) {
                  console.log("已经是最后一页了");
                  _this.$Message.info("已经是最后一页了");
                  return;
                }
                if (_this.loading == true) {
                  return;
                }
                let params = {
                  fileName: _this.fileName,
                  keyWord: "",
                  pageNumber: parseInt(_this.pageNumber) + 1,
                };
                _this.previewPDF(params);
              }
            });
          };
        }
      },
      previewPDF(params2) {
        if (this.pdfLoading == true) {
          return;
        }
        PreviewFile2(params2)
          .then((res) => {
            console.log(res, " 文档预览接口 ");
            this.pdfLoading = false;
            if (res.code == 200) {
              this.pageNumber = res.result.pageNumber;
              this.numberOfPages = res.result.numberOfPages;
              this.srcdoc = res.result.html;
            } else {
              this.srcdoc = "";
              this.$Message.warning(res.message);
            }
          })
          .catch((err) => {
            this.$Message.warning(err.message);
          });
      },
      // 预览文件
      previewFile(fileData, cIndex) {
        this.previewPdfModal = true;
        this.fileName = fileData.metadata.source;
        let keyword = fileData.data.replace(/\s/g, "");

        let params2 = {
          fileName: fileData.metadata.source,
          keyWord: keyword,
          pageNumber: "",
        };
        // let params2 = {
        //   fileName: "657a972a83f008488030791a.pdf",
        //   keyWord: keyword,
        //   pageNumber: "",
        // };
        // debugger;
        PreviewFile2(params2)
          .then((res) => {
            // console.log(res, "获取html")
            if (res.code == 200) {
              this.srcdoc = res.result.html;
              this.numberOfPages = res.result.numberOfPages;
              this.pageNumber = res.result.pageNumber;
              this.pdfLoading = false;
              this.keyword = fileData.data;
            } else {
              this.srcdoc = "";
              this.pdfLoading = false;
              this.$Message.warning(res.message);
            }
          })
          .catch((err) => {
            console.log(err, " 文档预览接口err ");
            this.pdfLoading = false;
          });
      },

      // 重新生成
      reAskZG(event) {
        let postIndex = this.currentPostList.length - 1;
        let answerIndex = this.currentListHistory.length - 1;
        this.formData.chatInput = this.currentListHistory[answerIndex]["question"];
        this.currentListHistory.splice(answerIndex, 1);
        this.currentPostList.splice(postIndex, 1);
        this.askZhiGong();
      },

      insertOrUpdateHistory() {
        let history = [];
        this.currentListHistory.map((item) => {
          history.push({
            question: item.question,
            answer: item.answer,
            fileList: item.fileList,
            time: item.time,
            islocal: item.islocal,
          });
        });
        //classification : 1: 1.0.0, 2:电力， 3：plc , 4: 多模态
        let params = {
          id: this.currentId,
          historys: JSON.stringify(history),
          classification: 2,
        };

        addOrEditHistory2(params).then((res) => {
          if (res.success) {
            this.currentId = res.result.id;
          }
        });
      },
      // 前端打字效果
      clickKeyBoard(textWord, answerIndex, postIndex) {
        let i = 0; // 记录目前的字符数
        this.formData.chatInput = "";
        const intervalId = setInterval(() => {
          this.currentListHistory[answerIndex]["answer"] = textWord.slice(0, i);
          //   // 滑动到底部
          this.scrollToBottom2();
          i++;
          if (i > textWord.length) {
            this.currentListHistory[answerIndex]["loading"] = false;
            this.currentListHistory[answerIndex]["status"] = 2;
            this.ZGloading = false;
            this.scrollToBottom2();
            clearInterval(intervalId);
          }
        }, 60);
      },

      // 是否返回结果
      isReturnAnswer() {
        let chLength = this.currentListHistory.length;
        if (chLength > 0 && this.currentListHistory[chLength - 1]["status"] === 0) {
          return false;
        } else {
          return true;
        }
      },

      // 下滑至底部
      scrollToBottom() {
        let _this = this;
        let scroll_to_bottom = document.getElementsByClassName("appContent");
        if (scroll_to_bottom == null || scroll_to_bottom == undefined) {
          scroll_to_bottom = document.getElementsByClassName("appContent");
        }
        // this.$ScrollIntoView(this.$refs.scrollTarget, {
        //   time: 1000,
        //   align: {
        //     top: 0, // 视图比例 0 to 1, 默认 0.5 (center)
        //     topOffset: 0, // 视图位移 pixels to offset top alignment
        //   },
        // });

        function scrollBottom(element) {
          if (element[0] == null || element[0] == undefined) {
            return;
          } else {
            if (element[0].scrollTo) {
              element[0].scrollTo({
                top: element[0].scrollHeight + 1000,
                // top: 9000,
                behavior: "smooth",
              });
              element[0].scrollIntoView(true);
            } else {
            }
          }
        }
        setTimeout(scrollBottom(scroll_to_bottom), 70);
        // setTimeout(scrollBottom(chatBottom,scroll_to_bottom), 70);
      },

      // 监听 scroll 高度
      scrollToBottom2() {
        let scroll_to_bottom = document.getElementsByClassName("appContent");
        if (scroll_to_bottom == null || scroll_to_bottom == undefined) {
          scroll_to_bottom = document.getElementsByClassName("appContent");
        }
        if (scroll_to_bottom == null || scroll_to_bottom == undefined) {
          return;
        }
        if (scroll_to_bottom[0].scrollHeight !== undefined) {
          this.scrollContainerHeight = scroll_to_bottom[0].scrollHeight;
          // return scroll_to_bottom[0].scrollHeight
        }
      },

      playTTS(data, index, type) {
        if (this.audio !== null) {
          this.audio.pause();
          this.audio = null;
          // this.currentListHistory.forEach((cItem, cIndex) => {
          //     cItem.pause = true;
          //     cItem.speak = false;
          // });
        }

        let _this = this;
        let ttsText = data.answer.toString();
        ttsText = ttsText.replace(/`/g, "");
        ttsText = ttsText.replace(/#/g, "");
        ttsText = ttsText.replace(/\n/g, "");
        ttsText = ttsText.replace(/\r/g, "");
        ttsText = ttsText.replace(/ /g, "");
        ttsText = ttsText.replace(/\//g, "");
        ttsText = ttsText.replace(/&/g, "");
        ttsText = ttsText.replace(/$/g, "");
        _this.audio = null;
        _this.audio = new Audio("http://dict.youdao.com/dictvoice?audio=" + encodeURIComponent(ttsText) + "&le=zh");
        let audioPlay = _this.audio.play();
        if (audioPlay !== undefined) {
          audioPlay.then(() => {
            _this.audio.play();
            _this.audioNum = index;
            let listenPause = setInterval(() => {
              if (_this.audio !== null && _this.audio.paused == true) {
                _this.currentListHistory[index]["speak"] = false;
                _this.audioNum = null;
                clearInterval(listenPause);
              } else if (_this.currentListHistory.length > 0 && _this.currentListHistory[index] !== undefined) {
                _this.currentListHistory[index]["speak"] = true;
              } else {
                clearInterval(listenPause);
                this.audioNum = null;
              }
            }, 200);
            listenPause;
          });
        }
        return;
      },
      stopTTS(data, index, type) {
        if (this.audio !== null) {
          this.audio.pause();
        }
        this.currentListHistory[index]["speak"] = false;
      },

      // 语音功能
      speechInit() {
        this.speech = new Speech();
        this.speech.setLanguage("zh-CN");
        this.speech
          .init({
            volume: 1,
            lang: "zh-CN",
            rate: 0,
            pitch: 0,
          })
          .then(() => {});
      },

      //语音播报
      speakText(data, index, type) {
        // 开始朗读
        if (type === "speak") {
          this.currentListHistory.forEach((item) => {
            item.speak = false;
            item.pause = false;
          });
          this.speech.cancel();
          this.speech
            .speak({
              text: data.answer,
              listeners: {
                onstart: () => {},
                onend: () => {},
              },
            })
            .then(() => {
              this.currentListHistory[index]["speak"] = false;
              this.currentListHistory[index]["pause"] = false;
            });

          this.currentListHistory[index]["speak"] = true;
          this.currentListHistory[index]["pause"] = false;
          return;
        }
        if (type === "cancel") {
          // 取消
          this.speech.cancel();
          this.currentListHistory[index]["speak"] = false;
          this.currentListHistory[index]["pause"] = false;
          return;
        }
        // 暂停
        if (type === "pause") {
          this.speech.pause();
          this.currentListHistory[index]["pause"] = true;
          return;
        }
        // 继续
        if (type === "resume") {
          this.speech.resume();
          this.currentListHistory[index]["pause"] = false;
          return;
        }
      },
      // 点赞功能
      agreeOrDisagree(data, index, key) {
        switch (key) {
          case "agree":
            if (this.currentListHistory[index][key] == false) {
              this.currentListHistory[index]["disagree"] = false;
            }
            break;
          default:
            if (this.currentListHistory[index][key] == false) {
              this.currentListHistory[index]["agree"] = false;
            }
            break;
        }
        this.currentListHistory[index][key] = !data[key];
      },
      getParams(inputText) {
        let params = {
          query: inputText,
          historys: [],
        };
        let bytesLens = 0;
        let bytesLenQuery = inputText.replace(/[^\x00-\xff]/g, "xx").length;
        for (let i = this.currentPostList.length; i >= 0; i--) {
          if (i == 0) {
            return params;
          }
          // 处理字节长度的问题
          let bytesLenQ = this.currentPostList[i - 1]["question"].replace(/[^\x00-\xff]/g, "xx").length;
          let bytesLenA = this.currentPostList[i - 1]["answer"].replace(/[^\x00-\xff]/g, "xx").length;
          bytesLens = bytesLenQuery + bytesLens + bytesLenQ + bytesLenA;
          if (bytesLens < 1200) {
            params.historys.unshift({
              question: this.currentPostList[i - 1]["question"],
              response: this.currentPostList[i - 1]["answer"],
            });
          } else {
            return params;
          }
        }
      },

      ///点击复制
      copyData(data) {
        var oInput = document.createElement("input"); //创建一个input标签
        oInput.value = data; //将要复制的值赋值给input
        document.body.appendChild(oInput); //在页面中插入
        oInput.select(); // 模拟鼠标选中
        document.execCommand("Copy"); // 执行浏览器复制命令（相当于ctrl+c）
        oInput.style.display = "none"; //只是用一下input标签的特性，实际并不需要显示，所以这里要隐藏掉
        this.$Message.success("复制成功");
      },
      // footer 功能
      clickFooterButton(data, dItem, key) {
        switch (key) {
          case "openWindow":
            this.$refs.preViewOfficeRef.tabList = [];
            this.preFile(data, dItem);

            break;
          case "copy":
            this.copyData(data.answer);
            break;
          default:
            break;
        }
      },
      // 预览文件
      preFile(data, dItem) {
        this.$refs.preViewOfficeRef.answerData = data.answer;
        let _this = this;
        function pushList(params, type, name) {
          _this.$refs.preViewOfficeRef.tabList.push({
            name: params,
            tabDatatype: type,
            src: params,
            data: params,
          });
        }

        let strMarkdown = this.markdownString(data.answer);
        // 循环给右侧栏展示
        let strHttpArr = this.httpString(data.answer);

        if (strMarkdown !== null || data.answer.indexOf("// ") > 0 || strHttpArr == null) {
          this.$refs.preViewOfficeRef.tabList.push({
            name: "编辑",
            tabDatatype: "markdown",
            src: "",
            data: data.answer,
          });
        }
        if (strHttpArr !== null) {
          strHttpArr.forEach((sItem, sIndex) => {
            // 识别文件类型
            if (sItem.indexOf(".pdf") >= 0) {
              pushList(sItem, "pdf", sItem);
            } else if (sItem.indexOf(".docx") >= 0 || sItem.indexOf(".doc") >= 0) {
              pushList(sItem, "word", sItem);
            } else if (sItem.indexOf(".xlsx") >= 0) {
              pushList(sItem, "excel", sItem);
            } else {
              pushList(sItem, "html", sItem);
            }
          });
        }
      },
      //
      // 关闭工作台
      closeRight() {
        this.fullPage = false;
        this.$refs.preViewOfficeRef.tabList = [];
      },
      // 全屏
      fullScreen() {
        this.fullPage = !this.fullPage;
      },
      // 退出登录
      // 退出登录
      exitbtn(v) {
        let userInfo = Cookies.get("userInfo");
        // fetch(
        //   `http://101.200.140.188:38892/check_out/${
        //     JSON.parse(userInfo).accessToken
        //   }/${getStore("cur")}`
        // )
        checkOutToken(`${JSON.parse(userInfo).accessToken}/${getStore("cur")}`).then((response) => {
          // infoRes.json().then((response) => {
          console.log("退出登录");
          this.flag = !this.flag;
          this.vip = "";
          this.isId = "";
          Cookies.remove("userInfo");
          Cookies.set("accessToken", "");
          setStore("vip", "");
          setStore("uid", "");
          setStore("accessToken", "");
          this.$router.push("/homeView_1");
          // });
        });
      },
      // 格式化字符串
      replaceStr(Str) {
        let b = Str.replace(/。/g, "。<br/><br/>");
        return b;
      },

      // 正则获取 返回地址中的链接
      httpString(s) {
        if (s === undefined || s === null) {
          return [];
        }

        let reg = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]+/g;

        let regCh = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]+[\u4e00-\u9fa5]+/g;
        let regS = s.match(regCh);
        if (regS === null) {
          regS = s.match(reg);
        }
        return regS;
      },
      // 正则获取 返回地址中的代码块
      markdownString(s) {
        if (s === undefined || s === null) {
          return null;
        }
        let reg = /(```)/g;
        s = s.match(reg);
        return s;
      },

      // 正则匹配答案
      matchQuestion(text) {
        let returnData = null;
        let RDType = 0;
        for (let i = 0; i < ciicDefaultChat.length; i++) {
          if (text.indexOf(ciicDefaultChat[i]["ask"]) >= 0) {
            returnData = ciicDefaultChat[i];
            // 显示工作台按钮
            ciicDefaultChat[i]["status"] = 2;
            break;
          }
        }
        return returnData;
      },
      // 插入历史记录
      setCurrentListHistory(value) {
        // console.log(value, "传归来的历史数据");
        // 初始化掉所有功能
        this.scrollContainerHeight = 0;
        if (this.audio !== null) {
          this.audio.pause();
          this.audio = null;
          this.audioNum = null;
        }

        this.currentListHistory = value;
        this.currentPostList = [];
        if (value.length > 0) {
          this.showDefault = false;
        } else {
          this.showDefault = true;
        }

        setTimeout(() => {
          this.scrollToBottom();
          this.scrollToBottom2();
        }, 500);
      },
      openHistoryDrawer() {
        this.$refs.historyListRef.initHistory();
        this.$refs.historyListRef.showHistoryList = true;
      },
      // 语音输入文本
      recordInputText() {
        const button = document.getElementById("recordButton");
        const button1 = document.getElementById("recordButton1");
        this.recording = false;

        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (typeof SpeechRecognition !== "undefined") {
          const recognition = new SpeechRecognition();
          const stop = () => {
            recognition.stop();
            this.strBefore = "";
            this.askZhiGong();
          };
          const start = () => {
            recognition.start();
            this.formData.chatInput = "";
            this.strBefore = "";
          };
          const onResult = (event) => {
            if (this.recording == false) {
              recognition.stop();
              this.strBefore = "";
            }
            for (const res of event.results) {
              const text = document.createTextNode(res[0].transcript);
              if (this.recording) {
                this.formData.chatInput = this.strBefore + text.data;
              }
              if (res.isFinal) {
                this.strBefore = text.data;
              }
            }
          };
          recognition.continuous = true;
          recognition.interimResults = true;
          recognition.addEventListener("result", onResult);
          button.addEventListener("click", (event) => {
            this.recording ? stop() : start();
            this.recording = !this.recording;
          });
          button1.addEventListener("click", (event) => {
            this.recording ? stop() : start();
            this.recording = !this.recording;
          });
        } else {
          button.remove();
          button1.remove();
          this.$Message.info("您的设备不支持语音输入");
        }
      },
      startRecord() {},
      // 是否移动端
      _isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        return flag;
      },
      closeHistory(v) {
        switch (v.name) {
          case "historyList":
            this.setCurrentListHistory(v.value);
            break;
          case "currentId":
            this.currentId = v.value;
            break;
          default:
            break;
        }
      },
      // 添加水印
      addWaterMark() {
        // 创建画布
        function createCanvas(width, height) {
          // 创建一个画布
          let canvas = document.createElement("canvas");
          // 设置画布的宽高
          canvas.width = width;
          canvas.height = height;
          return {
            canvas,
            ctx: canvas.getContext("2d"),
          };
        }

        // 填充水印
        function fillMark(canvas, html) {
          html[0].style.pointerEvents = "none";
          html[0].style.background = "url(" + canvas.toDataURL("image/png") + ") left top repeat";
        }
        // 文字水印生成
        function watermarkText(html, str, options = {}) {
          let {
            fontSize = 14, // 设置字体大小
            fontFamily = "微软雅黑", // 设置字体
            width = str.length * fontSize, // 设置画布的宽
            height = 120, // 设置画布的高
            color = "rgba(0, 0, 0, 0.1)", // 设置填充绘画的颜色/渐变模式
            angle = -20, // 旋转角度
            textAlign = "left", // 设置文本内容的对齐方式
            font, // 设置字体及大小
            textBaseline = "Middle", // 设置文本基线
            start = -120, // 开始绘制文本的X坐标位置
            end = height / 1.2, // 开始绘制文本的Y坐标位置
          } = options;

          // 创建画布
          let cans = createCanvas(width, height);
          let canvas = cans.canvas;
          let ctx = cans.ctx;
          ctx.rotate((angle * Math.PI) / 180);
          ctx.font = font || fontSize + "px " + fontFamily;
          ctx.imageSmoothingEnabled = true;
          ctx.fillStyle = color;
          ctx.textAlign = textAlign;
          ctx.textBaseline = textBaseline;
          // 在画布上绘制填色的文本（输出的文本，开始绘制文本的X坐标位置，开始绘制文本的Y坐标位置）
          ctx.fillText(str, start, end);
          // 填充水印
          fillMark(canvas, html);
        }
        let html = document.getElementsByClassName("watermark");
        watermarkText(html, "智工生成内容仅供参考", { width: 240, start: 0 });
      },
    },
    beforeMount() {
      let userInfo = Cookies.get("userInfo");
      // fetch(
      //   `http://101.200.140.188:38892/check_key/${
      //     JSON.parse(userInfo).accessToken
      //   }`
      // )
      checkKeyToken(JSON.parse(userInfo).accessToken).then((res) => {
        // infoRes.json().then((res) => {
        if (res.exists !== undefined) {
          setStore("vip", res.vip);
          setStore("uid", res.username);
          setStore("id", res.id);
          setStore("cur", res.cur);
          if (res.vip !== 1 || res.id.length == 0) {
            this.$Message.error("暂无权限，请耐心等待排队通过");
            setTimeout(() => {
              this.$router.push("/homeView_1");
            }, 200);
            return;
          }
        } else {
          this.$Message.error("登录过期，请重新登录");
          this.vip = "";
          this.isId = "";
          Cookies.remove("userInfo");
          Cookies.remove("accessToken");
          setStore("accessToken", "");
          setStore("vip", "");
          this.$router.push("/homeView_1");
        }
        // });
      });
    },
    mounted() {
      if (this._isMobile()) {
        // this.$router.go(0);
        this.getRandomElements();
      } else {
        // this.$router.replace("/chatWithZG");
      }
      // 长按
      document.documentElement.addEventListener(
        "touchstart",
        function (event) {
          if (event.touches.length > 1) {
            event.preventDefault();
          }
        },
        { passive: false }
      );
      // 禁止双击放大
      let lastTouchEnd = 0;
      document.documentElement.addEventListener(
        "touchend",
        function (event) {
          var now = Date.now();
          if (now - lastTouchEnd <= 300) {
            event.preventDefault();
          }
          lastTouchEnd = now;
        },
        { passive: false }
      );
      let userInfo = Cookies.get("userInfo");
      if (!userInfo) {
        this.$Message.error("请先登录");
        setTimeout(() => {
          this.$router.push("/homeView_1");
        }, 1000);
        return;
      } else {
        let scd = JSON.parse(userInfo).mobile;
        const start = scd.substr(0, 3);
        const middle = "*****";
        const end = scd.substr(8, 3);
        this.userInfo = scd;
        this.accToken = JSON.parse(userInfo).accessToken;
      }
      this.speechInit();
      this.addWaterMark();
      // 监听语音输入事件
      // this.recordInputText();

      // let scroll_to_bottom = document.getElementsByClassName("scrollContainer");
      // if (scroll_to_bottom == null || scroll_to_bottom == undefined) {
      //   scroll_to_bottom = document.getElementsByClassName(
      //     "scrollContainer-full"
      //   );
      // }
    },
    computed: {},
    watch: {
      scrollContainerHeight(newH, oldH) {
        this.scrollToBottom();
      },
      srcdoc: {
        handler(nval, oval) {
          if (nval.length > 0) {
            this.listenPDFScroll();
          }
        },
      },
      previewPdfModal: {
        handler(nval, oval) {
          if (nval == false) {
            this.srcdoc = "";
          }
        },
      },
    },
  };
</script>
<style lang="less" scoped></style>
