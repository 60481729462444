<template>
  <div>
    <div class="login">
      <div class-name="login_modal" :v-model="showModal" :mask-closable="false" width="360" :footer-hide="true">
        <!-- <Modal
        class-name="login_modal"
        v-model="showModal"
        :mask-closable="false"
        width="360"
        :footer-hide="true"
      > -->
        <Tabs :animated="false" v-show="activeTabName == 'login'">
          <TabPane label="手机号快捷登录">
            <Form :model="formSmsLogin" ref="formSmsLogin" :rules="ruleSmsValidate" label-position="top">
              <FormItem prop="phone">
                <Input v-model="formSmsLogin.phone" clearable placeholder="请输入手机号码"></Input>
              </FormItem>
              <Row :gutter="5" style="height: 60px">
                <Col span="16" style="height: 60px">
                  <FormItem prop="smsCode">
                    <Input v-model="formSmsLogin.smsCode" clearable maxlength="6" placeholder="请输入验证码"></Input>
                  </FormItem>
                </Col>
                <Col span="8" style="line-height: 100%">
                  <Button :disabled="!canGetSmsCode" :loading="msgLoadingBtn" @click="getSmsCode" style="width: 100%; margin-top: 1.38px; padding: 0px; overflow: hidden"
                    ><span class="btn-sms-code">{{ smsCodeText }}</span></Button
                  >
                </Col>
              </Row>
            </Form>
            <div class="login-tip">
              <span>
                <Checkbox v-model="agreeRegister">勾选即代表您同意并接受<a href="javascript:;" @click="userToKnow('yinsi')">《智工隐私政策》</a> 与<a href="javascript:;" @click="userToKnow('yonghuxieyi')">《智工用户协议》</a></Checkbox>
              </span>
            </div>
            <Button class="loginButton" size="large" :loading="loadingbtn" style="width: 100%" type="primary" @click="sumCodeLogin">登录</Button>
          </TabPane>
          <TabPane label="账号密码登录">
            <Form :model="formLogin" ref="formLogin" :rules="ruleSmsValidate" label-position="top">
              <FormItem prop="phone">
                <Input v-model="formLogin.phone" clearable placeholder="请输入账号"></Input>
              </FormItem>

              <FormItem prop="password">
                <Input v-model="formLogin.password" :password="true" type="password" placeholder="请输入密码"></Input>
              </FormItem>
              <span class="forgetPassword"><span @click="returnForget">忘记密码？</span> </span>
            </Form>
            <div class="login-tip">
              <span>
                <Checkbox v-model="agreeRegister">勾选即代表您同意并接受<a href="javascript:;" @click="userToKnow('yinsi')">《智工隐私政策》</a>与<a href="javascript:;" @click="userToKnow('yonghuxieyi')">《智工用户协议》</a></Checkbox>
              </span>
            </div>
            <Button class="loginButton" size="large" :loading="loadingbtn" style="width: 100%" type="primary" @click="sumPasswordLogin">登录</Button>
            <div style="text-align: center; border-radius: 50px; border: 1px solid #ccc; margin-top: 10px; cursor: pointer" @click="returnRegister">
              <span class="register">注册账号</span>
            </div>
          </TabPane>
        </Tabs>
        <div v-show="activeTabName == 'register'">
          <div class="login_title">注册账号</div>
          <Form :model="formRegister" ref="formRegister" :rules="ruleSmsValidate" label-position="top">
            <FormItem prop="phone" label="手机号">
              <Input v-model="formRegister.phone" clearable placeholder="请输入手机号码"></Input>
            </FormItem>
            <FormItem prop="password" label="密码">
              <Input v-model="formRegister.password" :password="true" type="password" placeholder="请输入密码"></Input>
            </FormItem>
            <FormItem prop="confirmRegisPassword" label="确认密码">
              <Input v-model="formRegister.confirmRegisPassword" :password="true" type="password" placeholder="请确认密码"></Input>
            </FormItem>
            <Row :gutter="5" style="height: 80px">
              <Col span="16" style="height: 80px">
                <FormItem prop="smsCode" label="验证码">
                  <Input v-model="formRegister.smsCode" maxlength="6" placeholder="请输入验证码"></Input>
                </FormItem>
              </Col>
              <Col span="8" style="line-height: 100%">
                <Button :disabled="!canGetSmsCode" :loading="msgLoadingBtn" @click="getRegisSmsCode" style="width: 100%; margin-top: 25.38px; padding: 0px; overflow: hidden"
                  ><span class="btn-sms-code">{{ smsCodeTextRegis }}</span></Button
                >
              </Col>
            </Row>
          </Form>
          <Button class="loginButton" size="large" :loading="loadingbtn" style="width: 100%" type="primary" @click="registerBtn">确定注册</Button>
          <div style="text-align: center; border-radius: 50px; border: 1px solid #ccc; margin-top: 10px; cursor: pointer" @click="returnLogin">
            <span class="register">已有账号，去登录</span>
          </div>
        </div>
        <div v-show="activeTabName == 'resetPassword'">
          <div class="login_title">重置密码</div>
          <Form :model="formResetPassword" ref="formResetPassword" :rules="ruleSmsValidate" label-position="top">
            <FormItem prop="phone">
              <Input v-model="formResetPassword.phone" clearable placeholder="请输入手机号码"></Input>
            </FormItem>
            <FormItem prop="password">
              <Input v-model="formResetPassword.password" :password="true" type="password" placeholder="请输入密码"></Input>
            </FormItem>
            <FormItem prop="confirmPassword">
              <Input v-model="formResetPassword.confirmPassword" :password="true" type="password" placeholder="请确认密码"></Input>
            </FormItem>
            <Row :gutter="5" style="height: 60px">
              <Col span="16" style="height: 60px">
                <FormItem prop="smsCode">
                  <Input v-model="formResetPassword.smsCode" clearable maxlength="6" placeholder="请输入验证码"></Input>
                </FormItem>
              </Col>
              <Col span="8" style="line-height: 100%">
                <Button :disabled="!canGetSmsCode" :loading="msgLoadingBtn" @click="getResetSmsCode()" style="width: 100%; margin-top: 1.38px; padding: 0px; overflow: hidden"
                  ><span class="btn-sms-code">{{ smsCodeTextReset }}</span></Button
                >
              </Col>
            </Row>
          </Form>
          <Button class="loginButton" size="large" :loading="loadingbtn" style="width: 100%" type="primary" @click="resetPasswordBtn">重置密码</Button>
          <div style="text-align: center">
            <span class="register" @click="returnLogin">返回登录</span>
          </div>
        </div>
        <!-- </Modal> -->
      </div>
    </div>

    <div class="agreementBackground" v-show="showAgreement">
      <div class="agreementBox" v-if="showAgreementType == 'yinsi'">
        <div class="agreementTitle">
          <span>智工·工业大模型智工隐私政策</span>
        </div>
        <div class="agreementContent">
          <p>更新日期：2023年6月10日</p>
          <p>发布日期：2023年6月10日</p>
          <p>生效日期：2023年6月10日</p>
          <p>
            《智工隐私政策》（以下简称“本政策”）仅适用于中工互联（北京）科技集团有限公司（以下简称“中工互联”或“我们”）推出的人工智能技术服务。您在使用本软件前，请仔细阅读本政策，以了解我们处理您个人信息的详情。其中有关个人敏感信息以及与您个人信息权益相关的重要内容我们已用加粗形式提示，请特别关注。
          </p>
          <p>本政策将帮助您了解以下内容：</p>
          <p>一、定义与解释</p>
          <p>二、我们如何收集和使用您的个人信息</p>
          <p>三、我们如何使用Cookie和同类技术</p>
          <p>四、我们如何委托处理、共享、转让、公开披露您的个人信息</p>
          <p>五、我们如何保护您的个人信息</p>
          <p>六、我们如何存储您的个人信息</p>
          <p>七、您如何实现管理您个人信息的权利</p>
          <p>八、我们如何保护未成年人的个人信息</p>
          <p>九、您的个人信息如何在全球范围内转移</p>
          <p>十、我们如何更新本政策</p>
          <p>十一、如何联系我们</p>
          <p>十二、其他</p>
          <h1>一、定义与解释</h1>
          <p>1.智工网站产品与/或服务</p>
          <p>包括智工网站所提供的产品与/或服务。</p>
          <p>2.智工网站服务</p>
          <p>是指智工的经营者中工互联提供的人工智能技术服务，以下简称“本服务”。</p>
          <p>3.中工互联（北京）科技集团有限公司</p>
          <p>智工的经营者，是一家工业大模型、工业软件、工业互联网平台及工业数字化解决方案提供商。联系电话：400-606-9889；如您想要更详细的了解我们公司、平台，您可以通过本政策第十一章提供的联系方式与我们联系。</p>
          <p>4.用户</p>
          <p>是指注册、登录以及使用本软件并通过启用、浏览或上传数据至智工服务的用户，在本政策中更多地称为“您”。</p>
          <p>5.关联公司</p>
          <p>指任何直接或间接控制中工互联、受中工互联控制或与中工互联受共同控制的任何一方。此处的“控制”和“受控制”分别指通过投票权、合同或其他方式而督导某一方的管理政策的权利。</p>
          <p>6.账号</p>
          <p>指您完成真实信息注册后，享有的用户账号，该用户账号由您负责保管；您应当对以您账号下进行的所有行为负法律责任。</p>
          <p>7.个人信息</p>
          <p>个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。为免疑义，个人信息包括但不限于敏感个人信息。</p>
          <p>8.敏感个人信息</p>
          <p>敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账号、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。</p>
          <p>9.个人信息的处理</p>
          <p>个人信息的处理包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等。</p>
          <p>10.个人信息处理者</p>
          <p>个人信息处理者是指在个人信息处理活动中自主决定处理目的、处理方式的组织、个人。</p>
          <p>11.个人信息主体</p>
          <p>个人信息主体指个人信息所标识或者关联的自然人。</p>
          <p>12.自动化决策</p>
          <p>自动化决策是指通过计算机程序自动分析、评估个人的行为习惯、兴趣爱好或者经济、健康、信用状况等，并进行决策的活动。</p>
          <p>13.去标识化</p>
          <p>去标识化指个人信息经过处理，使其在不借助额外信息的情况下，无法识别特定自然人的过程。</p>
          <p>14.匿名化</p>
          <p>匿名化指个人信息经过处理无法识别特定自然人且不能复原的过程。</p>
          <p>15.设备</p>
          <p>设备是指可用于访问智工服务的装置，例如台式计算机、笔记本电脑、平板电脑或智能手机。</p>
          <p>16.IP地址</p>
          <p>每台上网的设备都会指定一个编号，称为互联网协议 (IP) 地址。这些编号通常都是根据地理区域指定的。IP 地址通常可用于识别设备连接至互联网时所在的位置。</p>
          <p>17.SSL</p>
          <p>SSL（Secure Socket Layer）又称为安全套接层，是在传输通信协议（TCP/IP）上实现的一种安全协议。SSL支持各种类型的网络，同时提供三种基本的安全服务，均通过使用公开密钥和对称密钥技术以达到信息保密的效果。</p>
          <p>18.信息收集技术</p>
          <p>中工互联在本政策中所述的“自动收集”包括以下方式：</p>
          <p>(1)Cookie（网络饼干）：Cookie是您浏览网页时，网站服务器放在客户端（您的计算机、移动电话或其他智能终端内）里面的一个小小的文本文件，可用于改善您的使用体验。</p>
          <p>(2)Log files（日志文件）：Log files储存自动收集的若干数据。该等数据包括互联网协议（IP）地址、浏览器类型、互联网服务提供商（ISP）、引用/退出页面、操作系统、日期/时间戳和点击流数据等。</p>
          <p>(3)ET Tag（实体标签）：ET Tag是在互联网浏览器与互联网服务器之间背后传送的HTTP协议标头，可代替Cookie，用以追踪个别使用者，使我们可更深入地了解和改善我们的服务。</p>
          <p>(4)JavaScript（脚本语言）：JavaScript是一种编程语言，用于制作更具互动性和动态的网页。JavaScript可以设定Cookie、阅读Cookie及删除Cookie。</p>
          <h1>二、我们如何收集和使用您的个人信息</h1>
          <p>中工互联作为个人信息的处理者，会遵循合法、正当、必要和诚信的原则，出于如下所述的目的收集和使用您的个人信息。</p>
          <p>1.向您提供产品与或服务过程中您主动提供的个人信息</p>
          <p>
            智工的基本功能服务包括人机交互对话。此外，为向您提供问答历史查看、意见反馈等其他功能服务，您仅需要向我们提供该功能所对应的基本必要的个人信息，相关法律法规和规范性文件有特殊要求的除外。若您拒绝提供，我们将无法向您提供相应的功能服务。提示您注意：如果您提供的是非本人的个人信息，请您确保已取得相关主体的同意。智工完整功能及所需处理个人信息详情如下：
          </p>
          <p>1.1.账号注册/登录</p>
          <p>为保障您的个人空间使用安全，您需要注册并登录“智工问答平台账号”后，才可以正常使用在注册并登录后的其他服务。您在注册和登录时需要向我们提供您本人的手机号码并设置登录密码，并且通过输入短信验证码，以便我们对您的用户身份进行核验。</p>
          <p>1.2.人机交互对话</p>
          <p>(1)信息范围</p>
          <p>
            在您使用人机交互对话功能服务时，我们将收集您与智工进行交互过程中所输入的文本对话信息，使用这些信息向您输出内容，以提供人机交互服务。在您使用语音输入与图片输入时，我们将收集您的音频内容与图片内容。同时，我们会使用这些转写后的文本对话信息向您输出内容，以提供人机交互服务。您的文本对话信息还会被用来提高智工的响应速度和质量。
            在对话过程中，您可以对智工所输出的内容进行反馈评价。我们将收集您的反馈信息，包括您主动提交的内容，以便不断改进智工的输出内容质量。如您拒绝我们收集和处理前述个人信息，请您谨慎输入前述信息，但因此您可能会影响您正常使用智工提供的部分或全部功能。
          </p>
          <p>(2)调用的权限范围</p>
          <p>在您使用人机交互对话功能服务时，我们需要调用您的存储权限以进行图片内容的输入，此为图片输入服务所必须的系统权限，若您拒绝，您将无法使用人机交互对话过程中的图片输入功能。</p>
          <p>1.3.问答历史</p>
          <p>
            在您使用本服务过程中，我们会收集您的问答历史，包括您输入的文本对话信息以及基于上述信息形成的对话主题(即理解您在问答过程中的主要意图)，以为向您提供连续性、高质量的使用体验。您可以查找并管理您尚未删除的问答历史。为履行网络信息安全义务，我们会在法律规定的时间内保留您的问答历史及对话主题。
          </p>
          <p>1.4.意见反馈</p>
          <p>
            如您使用意见反馈功能服务时，我们会收集您的问题描述信息、证明材料附件、手机号码、电子邮箱、联系人姓名、以了解您反馈的内容和需求。此类信息属于意见反馈功能服务的必要信息。为了核实您的身份，明确您的需求和相关问题的处理方案，或帮助您解决其他问题，我们的客服人员可能会使用您的手机号码、智工问答平台账号信息。为了向您提供与您使用过程信息相关的客服服务时，我们将会查询您的使用信息，包括但不限于人机对话记录、点赞记录、问题反馈记录等。
          </p>
          <p>1.5.助手</p>
          <p>如您在创建助手时，需要使用数据集功能，我们会收集您的文档内容、以读取您的文档内容，并进行知识构建，用于您后续的针对该助手的问答使用。为履行网络信息安全义务，我们会在法律规定的时间内保留您的文档内容。</p>
          <p>
            如您直接通过本政策第十一条所述的方式与我们联系，提出意见反馈，进行投诉、申诉或咨询智工服务的相关问题时，为了确保您的账号与系统的安全，您需要向我们提供您的智工问答平台账号信息，以便我们对您的用户身份进行核验，此类信息属于用户反馈中进行身份核验的必要信息。在反馈、投诉或咨询过程中，为了帮助您解决问题或记录相关问题的处理方案及结果，您可能需要向我们提供相应的证明材料附件，系统可能会记录您与客服之间的沟通记录，以便能够更好地帮您解决所咨询的问题和改善客服质量。如您不提供上述信息，我们可能无法向您及时反馈咨询的结果，但不影响您正常使用智工或服务。
          </p>
          <p>1.6.头像上传</p>
          <p>当您上传头像时，经您同意存储权限后，我们将获取您相册（图片库）中上传的图片信息。此为头像上传服务所必须的系统权限，若您拒绝，您将无法使用头像上传功能。</p>
          <p>2.向您提供产品与或服务过程中我们主动收集的个人信息</p>
          <p>在符合法律法规前提下，为了保障您的账号安全和更好地为您提供优质的服务，我们会收集和使用您在使用智工网站服务过程中产生的如下信息：</p>
          <p>2.1.问题排查与安全维护</p>
          <p>当您使用智工网站服务时，我们可能会根据您在软件安装及使用中的具体权限，接收并记录您的相关设备信息，进行问题排查与定位、以保障您的账号与使用安全，相关必要信息包括浏览器信息（浏览器类型、插件、语言、字体、域名下的缓存）、操作终端信息（操作系统、地域、通信商）。</p>
          <p>2.2.运营统计与分析</p>
          <p>
            当您使用智工网站服务时，我们会自动收集您在使用智工网站服务时的详细情况并作为网络日志进行保存，相关必要信息包括使用情况、IP地址、所访问服务的URL、浏览器的类型和使用的语言、访问服务的日期、时间、时长信息、浏览信息（浏览记录、下载记录和点击记录），以便我们统计智工服务的使用情况，进行问题排查，保障服务稳定和网络安全，并通过数据统计进行运营分析、改进和优化产品交互体验。
          </p>
          <p>3.个人信息处理说明</p>
          <p>(1)为提高您使用我们产品与服务的安全性，我们可能使用您的信息用于身份验证、客户服务、安全防范、诈骗监测等，以预防、发现、调查欺诈、危害安全、非法或违反与我们的协议、政策或规则的行为，以保护您、智工网站及其服务的其他使用者、我们及社会公众的合法权益。</p>
          <p>
            (2)根据适用的法律法规，我们可能会对您的个人信息进行技术处理，使得根据该信息无法精确识别到用户个人，并对技术处理后的信息进行匿名化或去标识化的学术研究或统计分析（包括使用匿名化或去标识化后的语音信息进行模型算法的训练；使用您在使用会写功能时输入的对话信息），以便不断改进算法模型的识别和响应的速度和质量、提高会服务的智能性和对您输入内容的理解能力，但我们不会根据您输入的对话内容对您个人进行特定身份的识别。
          </p>
          <p>(3)根据相关法律法规规定，以下情形中处理您的个人信息无需征得您的同意：</p>
          <p>·为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；</p>
          <p>·为履行法定职责或者法定义务所必需；</p>
          <p>·为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</p>
          <p>·为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</p>
          <p>·依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</p>
          <p>·法律、行政法规规定的其他情形。</p>
          <p>(4)您在注册时提供的信息，不得包含以下违反法律法规、政治相关、侵害他人合法权益的内容。</p>
          <p>·反对宪法所确定的基本原则的；</p>
          <p>·危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
          <p>·损害国家荣誉和利益的；</p>
          <p>·歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；</p>
          <p>·宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</p>
          <p>·煽动民族仇恨、民族歧视，破坏民族团结的；</p>
          <p>·破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
          <p>·散布谣言，扰乱经济秩序和社会秩序的；</p>
          <p>·散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
          <p>·侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；</p>
          <p>·含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵或其他道德上令人反感的内容；</p>
          <p>·中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其他内容。</p>
          <h1>三、我们如何使用Cookie和同类技术</h1>
          <p>1.Cookie技术的使用</p>
          <p>我们会通过cookies收集和使用您的信息，并将该等信息储存为日志信息。我们使用自己的cookies，目的是为您提供更个性化的用户体验和服务，并用于以下用途：</p>
          <p>(1)记住您的身份。例如：cookies有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供的有关您的喜好或其他信息；</p>
          <p>(2)分析您使用我们服务的情况。例如，我们可利用cookies来了解您使用我们的服务进行什么活动，或哪些网页或服务最受您的欢迎； 我们为上述目的使用cookies的同时，可能将通过cookies收集的非个人身份信息，经统计加工后提供给广告商或其他合作伙伴，用于分析用户如何使用我们的服务，并用于广告服务。</p>
          <p>2.Cookie同类技术的使用</p>
          <p>
            我们会使用的Cookie同类技术包括：网站信标和像素标签。我们使用上述同类技术的目的是了解您的产品或服务偏好并改善客户服务。
            您可以通过浏览器设置拒绝或管理cookies及同类技术。具体地，您可以依据本政策第七章所述方式进行设置。但请注意，如果停用cookies或同类技术，您有可能无法享受最佳的服务体验，某些服务也可能无法正常使用。
          </p>
          <h1>四、我们如何委托处理、共享、转让、公开披露您的个人信息</h1>
          <p>1.委托处理</p>
          <p>
            为了向您提供更完善、更优质的产品和服务，我们可能会委托第三方（包括公司、组织和个人）处理您的某些个人信息。对我们委托处理您个人信息的第三方，我们会与其签署严格的数据处理协议，明确约定委托处理的目的、期限、处理方式、个人信息的种类、保护措施以及双方的权利和义务等，并对第三方的个人信息处理活动进行监督，要求第三方仅按照我们的要求、本政策的规定以及其他任何相关的保密和安全措施来处理您的个人信息，不得超出约定的处理目的、处理方式等处理个人信息。如果您拒绝我们的第三方在提供服务时收集为提供服务所必需的个人信息，将可能导致您在使用智工或服务的过程中无法享受该第三方提供的服务。
          </p>
          <p>2.共享</p>
          <p>我们不会与第三方（包括公司、组织和个人）共享您的个人信息，但以下情况除外：</p>
          <p>(1)在获取明确同意的情况下共享：获得您的明确同意后，我们会与第三方共享您的个人信息。</p>
          <p>(2)在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对第三方共享您的个人信息。</p>
          <p>3.转让</p>
          <p>我们不会将您的个人信息转让给任何第三方（包括公司、组织和个人），但以下情况除外：</p>
          <p>(1)在获取明确同意的情况下转让；</p>
          <p>(2)根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行转让；</p>
          <p>
            (3)在本产品服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会告知您接收方的名称或者姓名和联系方式，并要求新的持有您个人信息的接收方继续受本政策的约束，对于接收方变更原先的处理目的、处理方式的，我们将要求该接收方重新向您征求授权同意。
          </p>
          <p>4.公开披露</p>
          <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
          <p>(1)获得您单独同意或基于您的主动选择，我们可能会公开披露您的个人信息；</p>
          <p>(2)在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</p>
          <p>(3)如果我们确定您出现违反法律法规或严重违反本产品相关协议规则的情况，或为保护本产品及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或本产品相关协议规则披露关于您的个人信息，包括相关违规行为以及本产品已对您采取的措施。</p>
          <p>5.委托处理、共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
          <p>以下情形中，委托处理、共享、转让、公开披露您的个人信息无需事先征得您的授权同意。</p>
          <p>(1)与我们履行法律法规规定的义务相关的；</p>
          <p>(2)与国家安全、国防安全直接相关的；</p>
          <p>(3)与公共安全、公共卫生、重大公共利益直接相关的；</p>
          <p>(4)与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
          <p>(5)出于维护您或其他个人生命、财产等重大合法权益但又很难得到本人同意的；</p>
          <p>(6)您自行向社会公众公开的个人信息；</p>
          <p>(7)从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
          <p>请您知悉：根据法律规定，委托处理、共享、转让及公开披露经匿名化处理的个人信息的，不属于个人信息的对外委托处理、共享、转让及公开披露的处理行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</p>
          <h1>五、我们如何保护您的个人信息</h1>
          <p>1.用户的隐私和个人信息保护</p>
          <p>
            我们非常重视用户的隐私和个人信息保护，也会采取合理的措施保护用户的个人信息。除法律法规规定或本政策另有约定的情形外，我们将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。未经用户许可我们不会向第三方公开、透露用户个人信息，我们会对相关信息采用专业加密存储与传输方式，保障用户个人信息的安全。
          </p>
          <p>2.数据安全措施</p>
          <p>
            (1)我们已采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。例如，我们的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户数据在传输过程中的安全；我们采取加密技术（TLS、SSL）、匿名化或去标识化处理和保护机制对用户个人信息进行加密保存，并通过隔离技术进行隔离；我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用；我们通过信息接触者保密协议等方式，仅允许有必要知悉这些信息的我们及我们关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制，同时要求接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，会被追究法律责任或被中止与我们的合作关系；我们已举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识；我们已制定并组织实施个人信息安全事件应急预案等。
          </p>
          <p>(2)我们已采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</p>
          <p>(3)互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。</p>
          <p>(4)互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>
          <p>
            (5)在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件发生的原因、可能造成的危害、涉及个人信息的种类、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议和可采取的措施等。我们将及时将事件相关情况以邮件、短信、电话、发送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。请您知悉，根据可适用法律法规的规定，如采取措施能够有效避免信息泄露、篡改、丢失造成危害的，我们可以不向您进行告知。
          </p>
          <h1>六、我们如何存储您的个人信息</h1>
          <p>本产品与/或服务仅面向于中国大陆地区（仅为本政策之目的，不包含香港特别行政区、澳门特别行政区和台湾地区）提供。原则上，我们在中华人民共和国境内收集产生的个人信息，存储在中华人民共和国境内，不会进行跨境传输。</p>
          <p>1.存储信息的地点</p>
          <p>中工互联遵守法律法规的规定，收集的有关您的信息和资料将保存在中华人民共和国境内大陆地区中工互联的服务器上。以下情形除外：</p>
          <p>·法律法规有明确规定；</p>
          <p>·获得您的明确授权；</p>
          <p>·您通过互联网进行跨境交易等个人主动行为。</p>
          <p>2.存储信息的期限</p>
          <p>一般而言，我们将在您使用智工服务期间为实现本政策所述处理目的所必需的时间内保留您的个人信息，除非可适用的法律法规或国家规范性文件另有规定。具体参考法规包括：</p>
          <p>(1)《网络安全法》第三章第二十一条规定：采取监测、记录网络运行状态、网络安全事件的技术措施，并按照规定留存相关的网络日志不少于六个月。</p>
          <p>
            (2)《电子商务法》第二章第三十一条规定：商品和服务信息、交易信息保存时间自交易完成之日起不少于三年；法律、行政法规另有规定的，依照其规定。
            在超过上述期限，或您主动要求删除您的个人信息或注销您的账号后，我们将根据适用法律法规的要求尽快删除您的个人信息，除非可适用的法律法规或国家规范性文件要求在更长的期间内保留这些信息。
          </p>
          <p>但在下列情况下，中工互联因需符合法律要求，更改个人信息的存储时间：</p>
          <p>·为遵守适用的法律法规等有关规定；</p>
          <p>·为遵守法院判决、裁定或其他法律程序的规定；</p>
          <p>·为遵守相关政府机关或法定授权组织的要求；</p>
          <p>·中工互联有理由确信需要遵守法律法规等有关规定；</p>
          <p>·为执行相关服务协议或本政策、维护社会公共利益，为保护中工互联的客户、中工互联、其他用户或雇员的人身财产安全或其他合法权益所合理必需的用途。</p>
          <p>在您终止使用智工后，中工互联会停止对您的信息的收集和使用，法律法规或监管部门另有规定的除外。</p>
          <p>当智工的相关服务发生停止运营的情形时，中工互联将采取包括发送通知和公告形式通知您，并在法律法规要求的期限内删除服务器中留存的您的个人信息。</p>
          <h1>七、您如何实现管理您个人信息的权利</h1>
          <p>1.按照中国相关的法律、法规、标准，您在个人信息处理活动中享有若干权利，具体包括：</p>
          <p>·您对个人信息处理享有知情权、决定权，有权限制或者拒绝他人对您个人信息进行处理，法律、行政法规另有规定的除外；</p>
          <p>·您有权向个人信息处理者查阅、复制您的个人信息，法律另有规定情形的除外；</p>
          <p>·您有权请求将您的个人信息转移至您指定的个人信息处理者；</p>
          <p>·您发现您的个人信息不准确或不完整的，有权请求个人信息处理者更正、补充；</p>
          <p>·您有权请求删除您的个人信息；</p>
          <p>·您有权要求个人信息处理者对其个人信息处理规则进行解释说明。</p>
          <p>2.我们保障您对自己的个人信息行使前述权利，并为您提供相关操作路径，具体地，您可以参考以下步骤进行相关操作：</p>
          <p>2.1.查阅、更正、补充、删除您的个人信息：</p>
          <p>①查阅您的个人信息：</p>
          <p>您可以通过以下步骤查阅您登录的帐号信息：</p>
          <p>·进入智工问答平台网站，登录您的平台账号；</p>
          <p>·点击已登录的账号名称；</p>
          <p>·查看所需信息。</p>
          <p>具体操作可能随着版本迭代略有差异。如果您无法通过上述操作、页面访问这些个人信息，您可以随时与我们联系。或根据本政策所述的方式联系我们的客服，我们将在15个工作日内对您的请求进行处理。</p>
          <p>②更正、补充您的个人信息</p>
          <p>您可以通过以下步骤更正、补充昵称、手机号、登录密码、邮箱等基本信息：</p>
          <p>·进入智工·工业大模型，登录您的平台账号；</p>
          <p>·点击已登录的账号名称，点击基本资料菜单；</p>
          <p>·点击基本资料或安全设置栏；</p>
          <p>·更改所需信息。</p>
          <p>不同版本的智工具体操作可能略有差异。当您发现我们处理的关于您的个人信息有错误时，您有权要求我们作出更正。如果您无法通过上述操作、页面更正这些个人信息，您可以随时与我们联系。或根据本政策所述的方式联系我们的客服，我们将在15个工作日内对您的请求进行处理。</p>
          <p>③删除您的个人信息</p>
          <p>您可以通过以下步骤删除问答历史：</p>
          <p>人机互动主窗口-新建聊天的右侧删除按钮或清空全部窗口按钮。</p>
          <p>您也有权请求删除问答历史之外的其他个人信息，您可以通过联系我们的客服，我们将在15个工作日内对您的请求进行处理。</p>
          <p>若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。</p>
          <p>2.2.获取您的个人信息副本</p>
          <p>您有权获取您的个人信息副本，您可以通过根据本政策第十一章所述的方式联系我们的客服，我们将在15个工作日内对您的请求进行处理。</p>
          <p>2.3.转移您的个人信息</p>
          <p>您有权请求将您的个人信息转移至您指定的个人信息处理者，符合国家网信部门规定条件的，我们会为您提供转移的途径。您可以与我们联系。或根据本政策第十一章所述的方式联系我们的客服，我们将在15个工作日内对您的请求进行处理。</p>
          <p>2.4.改变您授权同意的范围或撤回授权</p>
          <p>
            您可以通过注销账号的方式改变您授权我们继续收集个人信息的范围或撤回您的授权，您可以通过以下步骤进行：请提交注销账号申请。当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。
          </p>
          <p>2.5.注销账号</p>
          <p>
            您有权注销您的智工平台账号，您可以根据本政策第十一章所述的方式联系我们的客服协助您注销账号。我们收到您的请求后需要您提供账号（手机号码）以及短信验证码，以核对身份，核验成功后我们将在15个工作日内为您完成注销。为了保障安全，我们可能需要您提供相应方式证明您的身份和请求的正当性。您注销成功后，我们将根据法律法规的要求尽快删除您的个人信息。
          </p>
          <p>智工平台提供的产品服务需通过平台网站账号使用和管理，注销账号会导致该账号下全部服务停止。</p>
          <p>2.6.限制自动化决策和关闭Cookie及同类技术</p>
          <p>
            我们的产品与/或服务不涉及自动化决策和基于自动化决策的消息通知（包括广告营销）。我们的产品仅涉及非定向通知和Cookie及同类技术的使用，并且我们的产品和服务上可能会有广告商或其他第三方服务提供商放置的Cookie及同类技术，您有权关闭这些Cookie及同类技术。具体地，您可以参考以下步骤进行相关操作：
          </p>
          <p>关闭网站端的Cookie及同类技术：</p>
          <p>进入浏览器“设置”页面，找到隐私和安全设置相关页面，关闭允许使用网站保存和读取Cookie的相关设置。请您知悉，具体的关闭方式取决于您选择的浏览器类型。以谷歌浏览器为例，您可以参考以下步骤进行相关操作：</p>
          <p>·进入浏览器自带的“设置”页面；</p>
          <p>·点击“高级”，展开设置列表；</p>
          <p>·点击“隐私设置和安全性”；</p>
          <p>·点击“Cookie 及其他网站数据”；</p>
          <p>·点击“阻止所有Cookie”。</p>
          <p>2.7.响应您的上述请求</p>
          <p>
            中工互联将采取适当的技术手段，保证您对于自己的个人资料可进行查阅、补充、更正、删除，或通过SparkDesk产品服务发布的反馈或投诉渠道申请中工互联对相关信息进行补充、更正、删除、复制、转移；中工互联收到您的申请后将按流程予以处理，为保障安全，按照流程，中工互联可能会先要求您验证自己的身份，我们需要您提供账号（手机号码）以及短信验证码，待核验成功后再处理您的请求。中工互联将在15个工作日内做出答复，对于您合理的请求，中工互联原则上不收取费用，但对多次重复、超出合理限度的请求，中工互联将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，中工互联可能会予以拒绝。
          </p>
          <p>
            如果您无法查阅、补充、更正、删除、复制、转移您的个人信息，或改变您授权同意的范围或撤回授权、注销账号、限制自动化决策和关闭消息通知，或您需要查阅、补充、更正、删除、复制、转移您在使用SparkDesk时所产生的其他个人信息，或您认为中工互联存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您可通过中工互联对外公布的相关产品或服务的官方反馈渠道与中工互联联系。
            请您知悉：在以下情形中，我们将无法响应您的请求：
          </p>
          <p>·与我们履行法律法规规定的义务相关的；</p>
          <p>·与国家安全、国防安全直接相关的；</p>
          <p>·与公共安全、公共卫生、重大公共利益直接相关的；</p>
          <p>·与犯罪侦查、起诉、审判和执行判决等直接相关的；</p>
          <p>·我们有充分证据表明您存在主观恶意或滥用权利的；</p>
          <p>·出于您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
          <p>·响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
          <p>·涉及商业秘密的。</p>
          <h1>八、我们如何保护未成年人的个人信息</h1>
          <p>1.中工互联非常重视对未成年人个人信息的保护，我们将根据国家相关法律法规的规定保护未成年人的个人信息。</p>
          <p>2.我们将不满18周岁的任何人均视为未成年人。根据相关法律法规的规定，若您是年满14周岁的未成年人，我们在收集您的个人信息之前，会征得您或您的监护人的同意，并且当您、父母或者其他监护人要求我们更正、删除未成年人个人信息的，我们将及时采取措施予以更正、删除，但法律、行政法规另有规定的除外。</p>
          <p>3.若您是不满14周岁的未成年人（下称儿童），我们在收集您的个人信息之前，会征得您的父母或其他监护人的同意。</p>
          <p>
            4.我们在处理儿童个人信息时，将采取以下措施予以保障：对于收集到的儿童个人信息，我们除遵守本政策关于用户个人信息的约定外，还会秉持正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除。
          </p>
          <p>5.若您是未成年人的父母或监护人，如您对本政策所述相关事宜有任何疑问、意见、建议或投诉、举报需求，请通过第十一章所述方式与我们进行反馈。</p>
          <h1>九、您的个人信息如何在全球范围转移</h1>
          <p>
            原则上，我们在中华人民共和国境内收集产生的个人信息，会存储在中华人民共和国境内，不会进行跨境传输。如因业务等需要，确需向境外提供您的个人信息的，我们会向您告知境外接收方的名称或者姓名、联系方式、处理目的、处理方式、个人信息的种类以及您向境外接收方行使本法规定权利的方式和程序等事项，并在获得您的单独同意和法律法规规定的跨境提供应具备的条件后，才会将您的个人信息转移到您使用产品与/或服务所在国家/地区的境外管辖区，或者使您的个人信息受到来自这些管辖区的访问。
          </p>
          <p>请您知悉，此类管辖区可能设有不同的个人信息保护相关法律法规，我们会遵守管辖区内相关法律法规的要求确保您的个人信息得到充分保护。如此类管辖区未设立个人信息保护相关的法律法规，我们会尽力保障您的个人信息得到在中华人民共和国境内足够同等的保护。</p>
          <h1>十、我们如何更新本政策</h1>
          <p>1.为给您提供更好的服务，智工服务将不时更新与变化，我们会适时对本政策进行修订，这些修订构成本政策的一部分。但是未经您明确同意，我们不会削减您按照本政策所应享有的权利。</p>
          <p>
            2.本政策更新后，我们会在智工发出更新版本，通过新版本公告等方式再次提醒您阅读更新后的隐私政策，以便您及时了解本政策的最新版本。如果您不同意本政策，或对本政策修改、更新的内容有异议，您可以选择停止使用智工服务或者注销智工问答平台账号。但请您知悉，您账号注销之前、停止使用本服务之前的行为和活动仍受本政策的约束。
          </p>
          <p>3.对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明本政策的具体变更部分）。 本政策所指的重大变更包括但不限于：</p>
          <p>(1)我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息的类型、个人信息的使用方式等；</p>
          <p>(2)我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有权变更等；</p>
          <p>(3)个人信息共享、转让或公开披露的主要对象发生变化；</p>
          <p>(4)您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
          <p>(5)我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
          <p>(6)个人信息安全影响评估报告表明存在高风险时；</p>
          <p>(7)伴随政策或法律发布实施，为响应国家政策或者法律规范，做出变更。</p>
          <p>4.我们鼓励您在使用我们产品与/或服务的过程中查阅本政策。具体地，您可以通过“人机交互主窗口-下方智工隐私政策”中查看本政策。</p>
          <h1>十一、如何联系我们</h1>
          <p>为更好地保护您的个人信息，我们设立有专门的网络安全与个人信息保护部门。如您在使用智工的过程中对本政策或对您的个人信息有任何疑问或建议，请通过以下联系方式与我们联系：</p>
          <p>联系电话：4006069889</p>
          <p>联系邮箱（个人信息保护负责人）：huangxiaohui@ciictec.com或15611444734@163.com</p>
          <p>联系地址：北京市昌平区龙域北街8号院1号楼4层401</p>
          <p>如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，且在您向我们反馈后我们无法提供让您满意的回复且拒绝改进的，您可以向相关监管机构进行投诉和举报。</p>
          <h1>十二、其他</h1>
          <p>与本政策相关的任何纠纷，双方应经友好协商解决；若不能协商解决，您在此同意向北京市丰台区人民法院提起诉讼。</p>
          <p>如果有管辖权的任何法院裁定或判决本政策的任何条款无效，则该条款将从本政策中移除，但该条款的无效不影响本政策其余条款的效力。本政策的其余条款将继续执行。</p>
        </div>
        <div class="agreementBtn">
          <Button @click="disagreementBtn()" style="margin-right: 20px; height: 40px">否，拒绝</Button>
          <Button type="primary" @click="agreementBtn()" style="min-width: 150px; height: 40px">是，同意协议</Button>
        </div>
      </div>
      <div class="agreementBox" v-else>
        <div class="agreementTitle">
          <span>智工·工业大模型智工用户协议</span>
        </div>
        <div class="agreementContent">
          <p>更新日期：2023年9月4日</p>
          <p>亲爱的用户，欢迎来到智工·工业大模型的官方平台</p>
          <p>在使用本服务前，请您务必仔细阅读并理解本《用户协议》（以下简称“本协议”）。</p>
          <p>
            请您知悉，无论您是否已实际阅读本协议，当您通过网络页面点击确认同意本协议或实际使用智工·工业大模型（以下称“
            本服务”）时，均表示您与中工互联（北京）科技集团有限公司（以下称“我们”或“中工互联”）已就本协议达成一致，本协议具有合同效力。如果您不同意本协议的任一内容，或者无法准确理解我方对本协议条款的解释，请点击不同意或停止使用本服务。否则，即表示您已接受本协议所述的所有条款及其适用条件，同意受本协议约束。
          </p>
          <p>
            特别提示：本服务提供的所有内容均由人工智能模型答复，可能出现错误或遗漏，本服务对其答复内容的准确性、完整性和功能性不作任何保证，并且其输出的内容均不代表本服务提供者的态度或观点。特别提示，您在使用本服务咨询医疗、法律等相关专业问题时，本服务仅提供建议，不代表医疗诊断或法律服务等专业意见。若您需要医疗诊断、法律服务等专业意见，必须咨询医生和相关法律专业人士，并在专业人士的指导下作出决策。总之，本服务提供的内容，应仅供您参考，不构成任何建议或承诺，我们不承担您因使用本服务给您或任何第三方产生的任何损害或责任。
          </p>
          <h1>第一条 账号注册与管理</h1>
          <p>1.1 本平台可以为您提供以下服务：</p>
          <p>（1）内容回复能力：可以进行多风格多任务长文本回复，例如邮件、文案、公文、作文、对话等；</p>
          <p>（2）语言理解能力：可以进行多层次跨语种语言理解，实现语法检查、要素抽取、语篇归整、文本摘要、情感分析、多语言翻译等；</p>
          <p>（3）知识问答能力：可以回答各种各样的问题，包括生活知识、工作技能等；</p>
          <p>（4）推理能力：拥有基于思维链的推理能力，能够进行科学推理、常识推理等；</p>
          <p>（5）多题型步骤级数学能力：具备数学思维，能理解数学问题，覆盖多种题型，并能给出解题步骤；</p>
          <p>（6）代码理解与编写能力：可以进行代码理解、代码修改以及代码编写等工作；此外，还具备对话游戏、角色扮演等特色能力；</p>
          <p>（7）多模态理解与多模态生成能力：多模态理解包含包含图片问答、图片理解；多模态生成。</p>
          <p>1.2 本服务主要面向成年人，因此，在您开始注册程序或以其他被允许的形式实际使用本服务时，您应当具备完全民事行为能力。如果您未满 18 周岁，或者不具备完全民事行为能力，请在监护人陪同下阅读本协议，并在征得监护人的同意后使用本服务。</p>
          <p>
            1.3
            您在使用本服务前，应根据页面提示实名注册账号，并按要求填写真实、准确、合法、有效的相关信息，并确认同意本协议、《智工隐私政策》等相关规则、政策。若你提交的材料或提供的信息不准确、不真实、不规范或公司有理由怀疑为错误、不实、不合法的资料，则我们有权拒绝为您提供相关功能，您可能无法使用本软件及相关服务或在使用过程中部分功能受到限制。您的账号是您登录及使用本服务的凭证，该账号不可转让、不可赠与、不可继承。
          </p>
          <p>1.4 在您注册和使用账号时，您须承诺和保证：</p>
          <p>（1）对注册信息的真实性、合法性、有效性承担全部责任，及时更新注册信息，不得冒用他人的名义注册账号或使用本服务；</p>
          <p>（2）妥善保管账号及密码，并对该账号项下的所有行为承担法律责任；</p>
          <p>（3）不得恶意注册账号，包括但不限于频繁注册、批量注册等行为。</p>
          <p>（4）仅限于您本人使用账号，不得以任何形式将账户转让、出借、出租或提供给他人使用。</p>
          <p>1.5 如我们发现您存在以虚假信息骗取账号注册等违法违规以及违反本协议约定的行为，我们有权不经通知单方采取限期改正、暂停使用、注销账号等措施。</p>
          <p>1.6 用户发现任何非法使用用户账号的情况，应立即通知我们，我们将给予最大限度地配合和处理。但对于用户因账号或密码泄露造成的各种损失，我们不承担任何责任。</p>
          <p>1.7 如用户向我们提出注销账户，经我们审核同意，由我们注销该注册用户，用户即解除与我们的合同关系。但用户注销账号后，我们仍有权：</p>
          <p>（1） 保留该用户的注册数据及以前的行为记录，但属于个人信息的除外；</p>
          <p>（2） 对用户在注销前在使用本服务过程中存在违法行为或违反本协议的行为，行使本协议所约定的权利。</p>
          <p>1.8 如果您的账号有余额，请您在操作注销前将余额全部提出或转出全部。请您知悉，一旦您注销账号，我们有权对该账号下的全部虚拟收益（积分、增资服务等）做清除处理，因此产生的后果由您自行承担。</p>
          <h1>第二条 用户的权利和义务</h1>
          <p>2.1 您有权依据本协议的约定使用本平台提供的服务，我们亦有权根据技术和业务的发展增加或减少服务内容。</p>
          <p>2.2 您充分理解并同意，依据本协议，我们授予您一项个人的、可撤销的、不可转让的、非独占地和非商业的合法使用本平台及相关服务的权利。未经我们书面同意，您无权复制、传播、转让、出售、出租、出借、许可、转让、提供他人使用或以任何商业化方式使用本服务生成的内容（输出内容）。</p>
          <p>2.3 在法律允许的范围，您输入智工的文字、图片、音频、视频等内容（输入内容）的全部权利均仅归您或原作者所有，输入内容均不会因上传、发布等行为发生知识产权等权利的转移。若您输入他人享有知识产权等合法权利的内容，应当取得合法授权，确保不得侵害他人合法权利。</p>
          <p>2.4 您应遵守所有适用的法律法规，遵守本协议、《智工隐私政策》等相关规则、政策的约定，您不得以任何违法、侵害他人权利等方式使用本服务，包括但不限于：</p>
          <p>（1）对我们已标注的人工智能生成标识进行删除、篡改或隐匿；</p>
          <p>（2）对智工反汇编、反编译、反编译、翻译或以其他方式试图发现服务的模型、算法和系统的源代码或底层组件；</p>
          <p>（3）使用任何自动化或程序化方法从本服务中提取数据或输出，包括抓取、收集或提取数据；</p>
          <p>（4）使用本服务的输出来研发与智工相竞争的模型，包括开发或训练任何人工智能或机器学习算法或模型等；</p>
          <p>（5）使用任何技术手段重现或复制本服务或本平台；</p>
          <p>（6）删除、修改、遮掩或以其他任何方式替换提供本服务过程中可能包含的我们相关的商业名称、商标、服务标记、域名或其合作伙伴其他明显的标志，删除本服务平台中包含的任何版权声明、商标声明或其他所有权声明等；</p>
          <p>（7）任何非法侵入、干扰、破坏或限制本平台或本服务正常运行的行为；</p>
          <p>（8）企图规避本平台的安全设置或网络系统，登录未经明确授权的服务器或账号，或采用运行端口扫描等方式窥探其他网络的安全措施；</p>
          <p>（9）利用或针对本软件及相关服务从事诈骗、欺诈、误导或欺骗性活动，包括但不限于假冒或将本服务输出内容表示为人工生成、剽窃或学术不诚实、或网络钓鱼；</p>
          <p>（10）其他任何违反法律法规规定或损害中工互联、智工的行为。</p>
          <p>
            2.5
            您在从事与本服务相关的所有行为(包括但不限于访问浏览、利用、转载、宣传介绍)时，必须以善意且谨慎的态度行事；不得利用本服务从事危害国家安全和社会公共利益、扰乱经济秩序和社会秩序、侵犯他人合法权益等法律、行政法规禁止的活动；您不得输入、诱导本服务生成或传播以下违反法律法规、政治相关、侵害他人合法权益的内容：
          </p>
          <p>（1）反对宪法所确定的基本原则的；</p>
          <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
          <p>（3）损害国家荣誉和利益的；</p>
          <p>（4）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；</p>
          <p>（5）宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</p>
          <p>（6）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
          <p>（7）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
          <p>（8）散布谣言，扰乱经济秩序和社会秩序的；</p>
          <p>（9）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
          <p>（10）侮辱或者诽谤他人，侵害他人名誉、隐私、肖像、知识产权、商业秘密和其他合法权益的；</p>
          <p>（11）含有虚假、有害、胁迫、骚扰、侵害、中伤、粗俗、猥亵或其他道德上令人反感的内容；</p>
          <p>（12）中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其他内容。</p>
          <p>2.6 您在使用本服务中由第三方（比如通过插件、链接等）提供的产品或服务时，请您遵守第三方公布的用户协议、使用规则、隐私政策等。</p>
          <p>
            2.7
            请您知悉，如我们发现您违反本协议的约定或利用本服务从事违法犯罪活动的，我们有权依据自己的合理判断不经通知，采取警示、限制功能、暂停或者终止服务、封禁账号、删除相关内容等处置措施，保存有关记录，并向有关主管部门报告。由此而导致或产生的法律责任或第三方主张的任何索赔、要求或损失，您应当自行承担责任；因此给我们造成任何损失的，您也应当一并赔偿（包括我们维权所支出的诉讼费、公证费、公告费、律师费等费用）。
          </p>
          <p>2.8 我们非常重视未成年人的保护，将持续根据法律法规的要求及行业的发展采取合理措施，帮助未成年人合理使用本服务。若您是未成年人，请监护人合理引导和约束未成年人对本服务的使用，帮助未成年人养成良好上网习惯，避免未成年人过度依赖或者沉迷本服务。</p>
          <h1>第三条 我们的权利和义务</h1>
          <p>3.1 我们将极力保障在现有技术上维护本服务的正常运行，并努力提升和改进技术，使用户的使用活动得以顺利进行；我们将本着诚实信用的原则，向用户提供安全、稳定、持续的服务，保障用户正常使用，不随意中断或停止提供该项服务。</p>
          <p>3.2 我们有权对您的注册申请进行审查，并决定是否接受您成为本服务的用户。我们有权对您违反有关法律法规或本协议规定的行为进行处理，包括但不限于中止服务、限制使用、终止服务、删除信息，对您的违法违规行为采取适当的法律行动，并依据法律法规保存有关信息、向有关部门报告等。</p>
          <p>3.3 我们将积极采取有效措施履行可适用的所有法律法规所规定的义务，包括但不限于：</p>
          <p>（1）输出内容体现社会主义核心价值观，不得含有颠覆国家政权、推翻社会主义制度，煽动分裂国家、破坏国家统一，宣扬恐怖主义、极端主义，宣扬民族仇恨、民族歧视，暴力、淫秽色情信息，虚假信息，以及可能扰乱经济秩序和社会秩序的内容。</p>
          <p>（2）在算法设计、训练数据选择、模型答复和优化、提供服务等过程中，采取措施防止出现种族、民族、信仰、国别、地域、性别、年龄、职业等歧视。</p>
          <p>（3）尊重知识产权、商业道德，不利用算法、数据、平台等优势实施不公平竞争。</p>
          <p>（4）采取有效措施防止输出虚假有害信息。</p>
          <p>（5）尊重他人合法权益，防止伤害他人身心健康，损害肖像权、名誉权和个人隐私，侵犯知识产权。禁止非法获取、披露、利用个人信息和隐私、商业秘密。</p>
          <p>3.4 一经发现上述违法内容的，我们将及时采取停止生成、停止传输、消除等合理处置措施。欢迎您通过投诉与举报渠道向我们反馈！我们将对您的意见高度重视并依法采取处理措施。</p>
          <p>3.5 依据有关规定，智工将对基于用户输入内容而生成的图片在其右下角添加水印标识“AI生成”。</p>
          <h1>第四条 知识产权</h1>
          <p>
            4.1
            与本平台相关的一切著作权、商标权、专利权、商业秘密等知识产权，以及本服务输出的相关所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受法律法规保护，由中工互联及其关联方享有完整的知识产权、所有权等合法权利。用户仅有权依照本协议约定合法使用本服务及本服务相关内容的权利。
          </p>
          <p>
            4.2
            您理解并且同意，除非有相反证明，您使用本平台服务上传、发布或传输内容即代表了您不可撤销地授予中工互联及关联方非排他的、无地域限制的、永久的、免费的许可使用（包括存储、使用、复制、修订、编辑、发布、展示、翻译、分发上述内容或制作派生作品等商业或非商业使用）及可再许可第三方使用的权利，以及可以自身名义对第三方侵权行为取证及提起诉讼的权利。
          </p>
          <p>4.3 除非法律另有规定，本服务运行过程中所产生的一切数据由我们享有合法权利，未经我们事先同意，任何人不得为本协议约定之外的目的获取、使用前述数据，亦不得以任何形式将前述数据提供给他人。</p>
          <p>
            4.4 未经我们许可，请不要擅自使用本平台相关的包括但不限于“智工问答”“智工”等在内的任何商标、服务标记、商号、域名、网站名称、公司徽标 (LOGO) 、
            URL或其他显著品牌特征等，不得将本条款前述标识以单独或结合任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理该些标识的行为。
          </p>
          <h1>第五条 免责声明</h1>
          <p>5.1 您对自己使用本服务的一切行为及由此产生的一切结果负责，包括但不限于您所提供的任何服务、发表的任何内容、以及由此产生的任何后果。您应承担因使用本服务及其相关内容而引起的所有风险，包括因对本服务及其内容的真实性、完整性、准确性、及时性等。本服务提供的内容，应仅供您参考，不构成任何建议或承诺，我们不承担您因使用本服务给您或任何第三方产生的任何损害或责任。</p>
          <p>5.2 本服务是按照现有技术和条件所能达到的现状提供的。我们尽最大努力确保服务的连贯性和安全性，但并不能保证其所提供的服务毫无瑕疵，也无法随时预见和防范法律、技术以及其他风险。不论在何种情况下，本服务均不对由于网络连接故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令，黑客攻击，互联网病毒，网络运营商技术调整，政府临时管制或任何其他不能合理控制的原因而造成的本服务不能访问、服务中断、信息及数据的泄露、延误、停滞或错误，不能提供或延迟提供服务而承担责任。</p>
          <p>5.3 当本服务提供的内容包含来自插件、第三方网站等内容时，我们并不对这些内容的有效性负责，且不保证从这些途径获取的任何内容、产品、服务或其他材料的真实性、合法性、安全性。您应慎重辨别这些内容的合法性、准确性、真实性、适用性、完整性和安全性，并采取谨慎的预防措施。如您不确定这些内容是否合法、准确、真实、实用、完整和安全，建议您先咨询专业人士。在法律允许的范围内，我们不承担您使用任何第三方信息或链接所引致的任何人身和财产损失（包括但不限于因下载而感染电脑病毒、名誉或商誉诽谤、版权或知识产权等权利的损失）。</p>
          <h1>第六条 个人信息保护</h1>
          <p>我们尊重并保护您及所有使用本服务用户的个人隐私、商业秘密。在此提醒您：请您确认使用本服务时输入的内容将不包含您或他人的个人信息、商业秘密等敏感内容。我们理解您输入的文本对话信息、提交的信息反馈中可能包含他人的个人信息，如包含，请您务必取得他人的合法授权，避免造成他人个人信息的不当泄露。关于我们如何收集、保护和使用个人信息的具体规则，请您仔细阅读《智工隐私政策》。</p>
          <h1>第七条 意见反馈（投诉与举报）</h1>
          <p>7.1 本服务所涉及的数据来自法律法规允许的范围，包括但不限于公开互联网等信息积累，并已经过不断地自动及人工敏感数据过滤，但仍不排除其中部分信息具有瑕疵、不合理或引人不适的问题存在。</p>
          <p>7.2 我们尊重知识产权及其他权利人的合法权益，积极采取有效措施保护他人的知识产权等合法权益。如知识产权等权利人认为本平台侵犯其合法权益的，可以通过本平台的邮箱huangxiaohui@ciictec.com或15611444734@163.com 向我们进行如实反馈，并提供相应的证明材料。我们将对您的意见高度重视并依法采取处理措施。</p>
          <h1>第八条 本协议的修改</h1>
          <p>我们有权根据需要不时地制定、修改本协议，如本协议有任何变更，我们将在智工上更新并以合理方式通知用户。如用户不同意相关变更，请停止使用本服务。经修订的协议一经在应用公布后，立即自动生效。用户继续使用本服务将被视为已接受了修改后的协议。</p>
          <h1>第九条 法律与管辖</h1>
          <p>本协议根据中华人民共和国大陆法律法规制定，受中华人民共和国大陆法律法规管辖。因本协议而发生的任何争议应向本协议签订地（北京市丰台区）有管辖权的人民法院提起诉讼。</p>
        </div>
        <div class="agreementBtn">
          <Button @click="disagreementBtn()" style="margin-right: 20px; height: 40px">否，拒绝</Button>
          <Button type="primary" @click="agreementBtn()" style="min-width: 150px; height: 40px">是，同意协议</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { findPhone, registerPhone, gptRegisSms, loginPhone, gptResetSms, gptMemberPasswordGetBack, gptMemberLogin, gptMemberRegist, checkKeyToken } from "@/api/api.js";
  // import { Form,Input } from "view-design";
  import { setStore } from "@/libs/storage";
  import Cookies from "js-cookie";

  export default {
    components: {},
    data() {
      var checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("手机号不能为空"));
        } else {
          const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error("请输入正确的手机号"));
          }
        }
      };
      const validateConfirmPass = (rule, value, callback) => {
        if (value.length < 6) {
          callback(new Error("密码长度不得小于6位"));
        } else if (value !== this.formResetPassword.password) {
          callback(new Error("两次输入的密码不一致"));
        } else {
          callback();
        }
      };
      const validateRegisConfirmPass = (rule, value, callback) => {
        if (value.length < 6) {
          callback(new Error("密码长度不得小于6位"));
        } else if (value !== this.formRegister.password) {
          callback(new Error("两次输入的密码不一致"));
        } else {
          callback();
        }
      };
      return {
        userInfo: {
          id: "682265633886208",
          parentId: "",
          createBy: "",
          createTime: "2022-03-17 10:45:12",
          updateBy: "admin",
          updateTime: "2023-03-23 18:54:47",
          delFlag: 0,
          openStatus: 1,
          sortOrder: null,
          username: "admin",
          password: null,
          nickname: "管理员",
          mobile: "17674164497",
          email: "1ojian410@163.com",
          address: "",
          street: "",
          sex: "男",
          passStrength: "弱",
          avatar: "http://i.loli.net/2019/04/28/5cc5a71a6e3b6.png",
          type: 0,
          status: 0,
          description: "",
          departmentId: "1364138936531816448",
          departmentTitle: "",
          teamId: "1559156235452944384",
          birth: null,
        },
        loadingbtn: false,
        msgLoadingBtn: false,
        showModal: false,
        activeTabName: "login",
        formLogin: {
          phone: "",
          password: "",
          captcha: "",
        },
        phoneList: ["17674164497"],
        captchaUrl: "",
        formRegister: {
          phone: "",
          password: "",
          confirmRegisPassword: "",
          smsCode: "",
          timer: null,
          countDown: 60,
        },
        formResetPassword: {
          phone: "",
          password: "",
          confirmPassword: "",
          smsCode: "",
          timer: null,
          countDown: 60,
        },
        formSmsLogin: {
          phone: "",
          smsCode: "",
          timer: null,
          countDown: 60,
        },
        ruleSmsValidate: {
          phone: [
            { required: true, trigger: "blur", message: "请输入手机号" },
            { validator: checkPhone, trigger: "blur" },
          ],
          smsCode: [{ required: true, trigger: "blur", message: "请输入6位验证码" }],
          password: [{ required: true, trigger: "blur", message: "请输入密码" }],
          confirmPassword: [
            {
              required: true,
              trigger: "blur",
              message: "请确认密码",
            },
            { validator: validateConfirmPass, trigger: "blur" },
          ],
          confirmRegisPassword: [
            {
              required: true,
              trigger: "blur",
              message: "请确认密码",
            },
            { validator: validateRegisConfirmPass, trigger: "blur" },
          ],
        },
        agreeRegister: false,
        showAgreement: false,
        showAgreementType: "",
      };
    },
    methods: {
      agreementBtn() {
        this.showAgreement = false;
        this.agreeRegister = true;
      },
      disagreementBtn() {
        this.showAgreement = false;
        this.agreeRegister = false;
      },
      showLoginBox() {
        this.showModal = true;
      },
      returnLogin() {
        this.initForm();
        this.activeTabName = "login";
      },
      returnRegister() {
        this.initForm();
        this.activeTabName = "register";
      },
      returnForget() {
        this.initForm();
        this.activeTabName = "resetPassword";
      },

      refreshCaptcha() {
        this.captchaUrl = "/api/captcha?" + Date.now();
      },
      getResetSmsCode() {
        // console.log("获取重置密码验证码");
        this.msgLoadingBtn = true;
        this.$refs.formResetPassword.validateField("phone", (v) => {
          if (v) {
            this.msgLoadingBtn = false;
          } else {
            findPhone({ mobile: this.formResetPassword.phone }).then((res) => {
              this.loadingbtn = false;
              this.msgLoadingBtn = false;
              if (res.success) {
                // 获取验证码
                // gptResetSms(this.formResetPassword.phone).then((response) => {
                //   console.log(response, "");
                // });
                this.sumcode("reset");
              } else {
                if (res.request && res.request.timeout === 15000) {
                  // this.$Message.error("请求超时");
                } else {
                  this.$Modal.confirm({
                    title: "该手机号尚未注册",
                    onOk: () => {},
                  });
                }
              }
            });
          }
        });
      },
      getRegisSmsCode() {
        this.msgLoadingBtn = true;
        this.$refs.formRegister.validateField("phone", (v) => {
          if (v) {
            this.msgLoadingBtn = false;
          } else {
            findPhone({ mobile: this.formRegister.phone }).then((res) => {
              this.loadingbtn = false;
              this.msgLoadingBtn = false;
              if (res.success) {
                this.$Message.error("账号已存在");
              } else {
                if (res.request && res.request.timeout === 15000) {
                  // this.$Message.error("请求超时");
                } else {
                  this.sumcode("regis");
                }
              }
            });
          }
        });
      },
      getSmsCode() {
        this.msgLoadingBtn = true;
        this.$refs.formSmsLogin.validateField("phone", (v) => {
          if (v) {
            this.msgLoadingBtn = false;
          } else {
            findPhone({ mobile: this.formSmsLogin.phone }).then((res) => {
              this.loadingbtn = false;
              this.msgLoadingBtn = false;
              if (res.success) {
                //等待接口
                // if(this.phoneList.includes(this.formSmsLogin.phone)){
                // if (true) {
                this.sumcode();
              } else {
                if (res.request && res.request.timeout === 15000) {
                  // this.$Message.error("请求超时");
                } else {
                  this.$Modal.confirm({
                    title: "将使用该手机号注册并登录",
                    onOk: () => {
                      this.sumcode();
                    },
                  });
                }
              }
            });
          }
        });
      },
      sumcode(type) {
        switch (type) {
          case "regis":
            this.formRegister.timer = setInterval(() => {
              this.formRegister.countDown--;
              if (this.formRegister.countDown <= 0) {
                clearInterval(this.formRegister.timer);
                this.formRegister.countDown = 60;
              }
            }, 1000);
            this.phoneList.push(this.formRegister.phone);
            //发送验证码
            gptRegisSms(this.formRegister.phone).then((res) => {
              // console.log(res);
            });
            break;
          case "reset":
            this.formResetPassword.timer = setInterval(() => {
              this.formResetPassword.countDown--;
              if (this.formResetPassword.countDown <= 0) {
                clearInterval(this.formResetPassword.timer);
                this.formResetPassword.countDown = 60;
              }
            }, 1000);
            this.phoneList.push(this.formResetPassword.phone);
            //发送验证码
            gptResetSms(this.formResetPassword.phone).then((res) => {
              // console.log(res);
            });
            break;
          default:
            this.formSmsLogin.timer = setInterval(() => {
              this.formSmsLogin.countDown--;
              if (this.formSmsLogin.countDown <= 0) {
                clearInterval(this.formSmsLogin.timer);
                this.formSmsLogin.countDown = 60;
              }
            }, 1000);
            this.phoneList.push(this.formSmsLogin.phone);
            //发送验证码
            registerPhone(this.formSmsLogin.phone).then((res) => {
              // console.log(res);
            });
            break;
        }
      },
      // 密码登录
      sumPasswordLogin() {
        if (this.agreeRegister == false) {
          this.$Message.error("请先同意智工隐私政策");
          return;
        }
        this.userInfo.mobile = this.formLogin.phone;
        this.$refs.formLogin.validate((valid) => {
          if (valid) {
            //虚拟的
            this.loadingbtn = true;
            gptMemberLogin({
              mobile: this.formLogin.phone,
              password: this.formLogin.password,
            }).then((res) => {
              if (res.success) {
                let dcs = {};
                dcs.accessToken = res.result.token;
                dcs.mobile = this.formLogin.phone;
                // console.log(dcs);
                Cookies.set("userInfo", JSON.stringify(dcs), {
                  expires: 7,
                });
                Cookies.set("accessToken", res.result.token);
                Cookies.set("noticeShowed", "noticeShowed");
                this.loadingbtn = false;
                this.showModal = false;
                this.initForm();
                clearInterval(this.formLogin.timer);
                this.formLogin.countDown = 60;
                this.$emit("loginhome", dcs);
                setStore("accessToken", res.result.token);
                this.$Message.success("登录成功");
                //
                this.checkKey(res.result.token);
                this.initForm();
              } else {
                this.$Message.error(res.message);
                this.loadingbtn = false;
              }
            });
          } else {
            // this.$Message.warning("请检查账号或者密码是否正确");
            // this.loadingbtn = false;
          }
        });
      },
      // 短信登录
      sumCodeLogin() {
        if (this.agreeRegister == false) {
          this.$Message.error("请先同意智工隐私政策");
          return;
        }
        this.userInfo.mobile = this.formSmsLogin.phone;
        this.$refs.formSmsLogin.validate((valid) => {
          if (valid) {
            //虚拟的
            this.loadingbtn = true;
            loginPhone({
              mobile: this.formSmsLogin.phone,
              code: this.formSmsLogin.smsCode,
            }).then((res) => {
              if (res.success) {
                this.$Message.success("登录成功");
                let dcs = {};
                let xbtoken = "XBOOT_TOKEN_MEMBER_PRE:" + res.result.token;
                dcs.accessToken = xbtoken;
                dcs.mobile = this.formSmsLogin.phone;
                // console.log(dcs);
                Cookies.set("userInfo", JSON.stringify(dcs), {
                  expires: 7,
                });
                Cookies.set("accessToken", xbtoken);
                Cookies.set("noticeShowed", "noticeShowed");
                this.loadingbtn = false;
                this.showModal = false;
                this.formSmsLogin.phone = "";
                this.formSmsLogin.smsCode = "";
                clearInterval(this.formSmsLogin.timer);
                this.formSmsLogin.countDown = 60;
                this.$emit("loginhome", dcs);
                setStore("accessToken", xbtoken);

                this.checkKey(xbtoken);
                this.initForm();
              } else {
                this.$Message.warning("请检查数据验证码或手机号是否正确");
                this.loadingbtn = false;
              }
            });
          } else {
            return false;
          }
        });
      },
      // 查询会员信息
      checkKey(token) {
        checkKeyToken(token)
          // fetch(`http://101.200.140.188:38892/check_key/${token}`)
          .then((response) => {
            // infoRes.json().then((response) => {

            if (response.exists !== undefined) {
              this.$emit("changeFlag", {
                vip: response.vip,
                id: response.id,
              });
              setStore("vip", response.vip);
              setStore("id", response.id);
              setStore("uid", response.username);
              setStore("cur", response.cur);
            } else {
              this.$Message.error("登录过期，请重新登录");
              this.vip = "";
              this.isId = "";
              Cookies.remove("userInfo");
              Cookies.remove("accessToken");
              setStore("accessToken", "");
              setStore("vip", "");
              this.$router.go(0);
            }
            // });
          });
      },

      userToKnow(data) {
        this.showAgreement = true;
        this.showAgreementType = data;
      },
      // 注册按钮
      registerBtn() {
        this.$refs.formRegister.validate((valid) => {
          if (valid) {
            let params = {
              mobile: this.formRegister.phone,
              password: this.formRegister.password,
              sendRegistSmsCode: this.formRegister.smsCode,
            };
            gptMemberRegist(params).then((res) => {
              // console.log(res, "注册返回值");
              if (res.success) {
                this.$Message.success(res.message);
                this.returnLogin();
              } else {
                this.$Message.error(res.message);
              }
            });
          }
        });
      },
      // 重置密码
      resetPasswordBtn() {
        this.$refs.formResetPassword.validate((valid) => {
          if (valid) {
            let params = {
              mobile: this.formResetPassword.phone,
              newPassword: this.formResetPassword.password,
              sendGetBackSmsCode: this.formResetPassword.smsCode,
            };
            gptMemberPasswordGetBack(params).then((res) => {
              if (res.success) {
                this.$Message.success(res.message);
                this.returnLogin();
              } else {
                this.$Message.error(res.message);
              }
            });
          }
        });
      },
      SMsubmit() {
        this.$refs.formSmsLogin.validate((valid) => {
          if (valid) {
            this.showModal = false;
            this.activeTabName = "login";
            this.formLogin.username = "";
            this.formLogin.password = "";
            this.formLogin.captcha = "";
            this.refreshCaptcha();
            this.formRegister.username = "";
            this.formRegister.password = "";
            this.formRegister.confirmPassword = "";
            this.formRegister.phone = "";
            this.formSmsLogin.phone = "";
            this.formSmsLogin.smsCode = "";
            clearInterval(this.formSmsLogin.timer);
            this.formSmsLogin.countDown = 60;
          } else {
            return false;
          }
        });
      },
      cancel() {
        this.showModal = false;
        this.activeTabName = "login";
        this.formLogin.username = "";
        this.formLogin.password = "";
        this.formLogin.captcha = "";
        this.refreshCaptcha();
        this.formRegister.username = "";
        this.formRegister.password = "";
        this.formRegister.confirmPassword = "";
        this.formRegister.phone = "";
        this.formSmsLogin.phone = "";
        this.formSmsLogin.smsCode = "";
        clearInterval(this.formSmsLogin.timer);
        this.formSmsLogin.countDown = 60;
      },
      initForm() {
        clearInterval(this.formResetPassword.timer);
        clearInterval(this.formRegister.timer);
        clearInterval(this.formSmsLogin.timer);
        this.formLogin = {
          phone: "",
          password: "",
          captcha: "",
        };
        this.formRegister = {
          phone: "",
          password: "",
          confirmRegisPassword: "",
          smsCode: "",
          timer: null,
          countDown: 60,
        };
        this.formResetPassword = {
          phone: "",
          password: "",
          confirmPassword: "",
          smsCode: "",
          timer: null,
          countDown: 60,
        };
        this.formSmsLogin = {
          phone: "",
          smsCode: "",
          timer: null,
          countDown: 60,
        };
      },
    },
    computed: {
      canGetSmsCode() {
        return this.formSmsLogin.countDown === 60;
      },
      smsCodeText() {
        if (this.canGetSmsCode) {
          return "获取验证码";
        } else {
          return `${this.formSmsLogin.countDown}秒后重试`;
        }
      },
      canGetSmsCodeRegis() {
        return this.formRegister.countDown === 60;
      },
      smsCodeTextRegis() {
        if (this.canGetSmsCodeRegis) {
          return "获取验证码";
        } else {
          return `${this.formRegister.countDown}秒后重试`;
        }
      },
      canGetSmsCodeReset() {
        return this.formResetPassword.countDown === 60;
      },
      smsCodeTextReset() {
        if (this.canGetSmsCodeReset) {
          return "获取验证码";
        } else {
          return `${this.formResetPassword.countDown}秒后重试`;
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .login {
    .btn-login {
      cursor: pointer;
      font-weight: bold;
      color: #1890ff;
    }

    .captcha {
      height: 36px;
      vertical-align: middle;
    }
  }
  .register {
    color: #000;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .ivu-modal-header p,
  /deep/ .ivu-modal-header-inner {
    font-weight: bold;
    text-align: center;
    font-size: 22px;
  }
  .loginButton {
    // background: linear-gradient(135deg, #808bff 0%, #2e67e6 100%);
    background: #663cdc;
    border: 0px;
    border-radius: 50px;
    font-weight: 700;
  }
  .loginButton:hover {
    background: #663cdc;
  }

  .login_modal {
    .ivu-modal {
      .ivu-modal-content {
        .ivu-modal-footer {
          border-top: 0px;
        }
        .ivu-modal-body {
          padding: 26px;
        }
      }
    }
  }
  .login_title {
    width: 100%;

    font-size: large;
    font-weight: bolder;
    margin-bottom: 0.2rem;
  }
  .login_footer {
    text-align: center;
    display: none;
  }
  .btn-sms-code {
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    font-weight: 700;
    color: #004fff;
  }
  .forgetPassword {
    color: #004fff;
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: -22px;
    span {
      cursor: pointer;
    }
  }
  .login-tip {
    max-width: 280px;
    margin-bottom: 10px;
  }
  .agreementBackground {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999999;
    display: flex;
    justify-content: center;
    align-items: center;
    .agreementBox {
      width: 75%;
      height: 80%;
      min-width: 500px;
      min-height: 400px;
      background-color: #fff;
      padding: 50px;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      p {
        margin-top: 10px;
      }
    }
    .agreementTitle {
      font-size: 30px;
      font-weight: bold;
      color: #000;
    }
    .agreementContent {
      overflow: scroll;
      color: #000;
    }
    .agreementBtn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 20px;
    }
  }
</style>
