const defaultQuestions = [
  '和利时LX系列产品的运动控制能力主要有哪些特点？',
  '和利时LX产品可适用于哪些环境条件？',
  '和利时LK系列控制系统可配置哪些类型的网络结构？',
  'LK220控制器可配置多少个POWERLINK主站通信模块？',
  '如何为和利时LK220控制器添加POWERLINK从协议？',
  "和利时LK130主控背板有几个插槽？",
  "和利时LK系列控制器有几种运行方式？",
  "对和利时LK系列控制器进行恢复出厂设置有什么注意事项？",
  "和利时LX系列的CPU模块DeviceNet网络通信具体是如何通讯的？",
  "和利时LK220系统连接LE的远程I/O时，需要注意什么？",
  "当和利时LK系列冗余系统发生错误时,应该怎样去排查故障？",
  "和利时LK220控制器如何添加POWERLINK主协议？ ",
  "如何为和利时LK220控制器添加POWERLINK接口模块？",
  "LK222控制器可配置多少个POWERLINK主站通信模块？",
  "LK224控制器最大可配置多少个POWERLINK主站通信模块？",

  "品质管理部都负责那些工作？",
  "公共设施行业的信贷风险有哪些？",
  "钻头的报废标准是什么？",
  "设备档案的建立要求是什么？",
  "各种机械在使用中应具备的基本条件是什么？",
  "设备使用管理的要求是什么？",
  "设备管理考核指标是什么？",

  "设备预防保养管理的发展过程是什么？",
  "设备维护规定的目的是什么？",
  "设备使用规程必须包括的内容有什么？",
  "设备缺陷的处理办法是什么？",
  "计量设备的校验方法有哪些？",
  "检验设备控制程序的职责是什么？",
  "测量设备的范围是什么？",
  "硬件开发及设备研制程序的工作要求是什么？",
  "车床的常用配备通用工具有哪些？",
  "检验和试验仪器设备的使用和维护方法是什么？",
  "设备管理的意义是什么？",
  "电动车维修保养包括什么？",


  "岗位安全责任的主要作用是什么？",
  "为什么要重视企业文化？",
  "全面质量管理的特点是什么？",
  "镜头的种类有哪些？",
  "禁止吸烟图形符号的设置范围是什么？",
  "我国铁路运输安全现状是什么？",
  "起重机械司机应该遵守什么规则？",
  "台风接近前的预防措施有哪些？",
  "监视器和电视机的区别是什么？",
  "安全标志的使用范围是什么？",
  "什么情况下对电动机进行堵转试验？",
  "什么样的产品可以申请 GS 认证？",
  "个人防护用品在预防职业中毒工作中有什么意义？",
  "生管部职责是什么？",
  "订单首件检验的检验流程是什么？",
  '钥匙保管人应遵守哪些条件？',


  "自动低频减负荷装置的作用是什么？",
  "制定自动低频减负荷方案时，应该遵循什么规定？",
  "火电厂环境监测管理规定火电厂环境监测管理范围是哪些？",
  "什么是电厂侧储能系统？",
  "电力监控系统网络安全防护技术导则中规定了哪些内容？",
  "电力监控系统投运前发生重大变更时，运行单位需要做什么工作？",
  "热电厂智能热网的一级管网应急处理应该符合哪些要求？",
  '储能电站技术监督动态检查应该多久开展一次？',
  "什么是电能质量评估？",
  "什么是“集中式电力市场”？",
  "高压变频器的供电电源应该满足哪些要求？",
  "高压变频器有几种运行方式？分别是什么？",
  "高压变频器安装场所的最低照度是多少？",
  "缺氧环境是指氧气含量低于多少？",
  "电力数据脱敏的目标是什么？",
  "基于温度的反馈控制策略应该包括哪些内容？",
  "机械安全安全防护的实施准则中包含了哪些范围？",
  "电力行业缺氧作业许可人有哪些职责?",
  "在实施安全防护之后，如何识别和处理可能产生的额外或新的危险？",
  "水管式沉降仪使用的测量液体是什么？",
  "安全防护的隔离原则是指什么？",
  "在使用可调式防护装置时，如何确保其安全性和有效性？同时，如何限制非授权人员进行调整？",
  "如何确保活动式防护装置在打开后达到安全要求，以及在无法实现联锁或防止未经授权改装时如何选择和实施合适的固定方式？"
];

export default defaultQuestions