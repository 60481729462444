<template>
  <div class="home">
    <div id="root">
      <div :class="getClass()">
        <div class="XDxYjHlu">
          <div class="fjiYtnUB1">
            <a href="/webapp"
              ><img alt="logo" class="qbUby_dV" src="@/assets/logo.png"
            /></a>
          </div>
          <div class="DDl4VNjw1">
            <ul
              class="ant-menu-overflow ant-menu ant-menu-root ant-menu-horizontal ant-menu-light"
              role="menu"
              tabindex="0"
              data-menu-list="true"
              style="border: 0px; background: transparent"
            >
              <li
                class="ant-menu-overflow-item ant-menu-submenu ant-menu-submenu-horizontal"
                role="none"
                style="opacity: 1; order: 0"
              >
                <div
                  role="menuitem"
                  class="ant-menu-submenu-title"
                  tabindex="-1"
                  aria-expanded="false"
                  aria-haspopup="true"
                  data-menu-id="rc-menu-uuid-32557-1-login"
                  aria-controls="rc-menu-uuid-32557-1-login-popup"
                >
                  <span class="ant-menu-title-content">
                    <div v-if="flag">
                      <div class="Qyj8AoQa" @click="login">登录</div>
                    </div>
                    <div class="A5NuDILa1" v-if="!flag">
                      <Dropdown @on-click="exitbtn">
                        <!-- <Button style="height: 40px">
                          <Avatar
                            style="height: 42px"
                            shape="square"
                            icon="ios-person"
                          />
                        </Button> -->
                        <img class="avatar" src="@/assets/logoFav.png" alt="" />
                        <DropdownMenu slot="list">
                          <DropdownItem name="userinfo">{{
                            userInfo
                          }}</DropdownItem>
                          <DropdownItem name="logout">退出登录</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div> </span
                  ><i class="ant-menu-submenu-arrow"></i>
                </div>
              </li>
              <li
                class="ant-menu-overflow-item ant-menu-overflow-item-rest ant-menu-submenu ant-menu-submenu-horizontal ant-menu-submenu-disabled"
                aria-hidden="true"
                role="none"
                style="
                  opacity: 0;
                  height: 0px;
                  overflow-y: hidden;
                  order: 2147483647;
                  pointer-events: none;
                  position: absolute;
                "
              >
                <div
                  role="menuitem"
                  class="ant-menu-submenu-title"
                  aria-expanded="false"
                  aria-haspopup="true"
                  aria-disabled="true"
                  data-menu-id="rc-menu-uuid-32557-1-rc-menu-more"
                  aria-controls="rc-menu-uuid-32557-1-rc-menu-more-popup"
                >
                  <span
                    role="img"
                    aria-label="ellipsis"
                    class="anticon anticon-ellipsis"
                    ><svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="ellipsis"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z"
                      ></path></svg></span
                  ><i class="ant-menu-submenu-arrow"></i>
                </div>
              </li>
            </ul>
            <div aria-hidden="true" style="display: none"></div>
          </div>
        </div>
      </div>
      <div class="SyBUiXPj" id="productContainer">
        <div>
          <div class="KJWhP3Y0">
            <div class="j6cvrvvX"></div>
            <div class="HjrMd8LI">
              <strong>“你好，智工！”</strong
              ><span class="hR0M32eA"
                >智工·工业大模型是工业领域加强大语言模型系统，为您提供专业的工业领域相关知识，可以提供工程设计、实施、运维、生产管控等各维度专业知识。</span
              ><span
                class="KTFDGqJT"
                @click="experience"
                v-show="vip === '' && isId.length == 0"
                >加入体验</span
              >
              <span
                class="KTFDGqJT"
                @click="experience"
                v-show="vip == '1' && isId.length > 0"
                >立刻体验</span
              >
              <span
                class="KTFDGqJT"
                @click="Add_info"
                v-show="(vip == '0' || vip == '1') && isId.length == 0"
                >填写信息</span
              >
              <span class="waitingPass" v-show="vip == '0' && isId.length > 0"
                ><img
                  class="waitingPassIcon"
                  src="@/assets/success.png"
                />您已在等待体验中，加入成功将收到短信通知。
              </span>
              <span class="waitingPass" v-show="vip == '2'"
                ><img
                  class="waitingPassIcon"
                  src="@/assets/notice.png"
                />会员时间已过期。
              </span>
            </div>
            <div class="banner_person"></div>
          </div>
          <div class="QwpKZ0nk">
            <div class="o3QY36gMbg">
              <div class="o3QY36gM">
                <div class="eEwIcus1">
                  <div class="hc8bJX9E">
                    <!-- <div><strong>“智工”有哪些关键能力</strong></div> -->
                  </div>
                  <div class="TGwmB_Rv">
                    <div class="hc8bJX9E1">“智工”有哪些关键能力</div>
                    <div class="nPTJmMSE">
                      <div class="H5iqHK2R">
                        <div class="cyWAQXAU">
                          <span> 提供对应的专业资料查阅 </span>
                        </div>
                      </div>
                    </div>
                    <div class="nPTJmMSE">
                      <div class="H5iqHK2R">
                        <div class="cyWAQXAU">
                          <span
                            >以自然语言和对话的方式，获取工业领域的专业知识</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="nPTJmMSE">
                      <div class="H5iqHK2R">
                        <div class="cyWAQXAU">
                          <span>帮助你编写工业领域的控制代码</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="dcxsejIF">
            <div class="F7Nvqu1M"></div>
            <div class="Iqv9guQQ">
              <strong class="P_mA8TF61">工业领域加强大语言模型</strong>
              <div class="xjyVk_out">
                <div class="xjyVk_into">
                  <div class="xjyVk_into_icon"></div>
                  <div class="xjyVkKvB">
                    通过大模型的学习能力和上下文语义理解，打造工业领域的多轮智能知识问答能力，形成工业领域行业知识、专业知识、厂内知识、操作指导的生成式检索；
                    同时，提供工业级设备控制程序动态编程支持，实现DCS、PLC、伺服器、变频器、机械臂等设备的控制代码生成，提升设备控制编码与调试的效率
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="o7jNbRcF">
            <div class="o8D9H1v_">
              <div>
                <strong class="wFsEli0R">陪伴“智工”一起成长</strong>
                <div class="RYWGU4QA">
                  你的每一次提问、每一次反馈、每一次需求登记，都能够帮助模型更好的学习和迭代，非常感谢通过“使用反馈”功能登记您的需求，让我们一起见证“智工”的成长
                  <br />
                  <br />
                  届时，我们将推出“智工”诞生记Vlog
                </div>
              </div>
            </div>
          </div>
          <div class="a1JlOgfY">
            <span>
              Copyright 2008-2023   中工互联（北京）科技集团有限公司   版权所有
                
              <a
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402013859"
                style="
                  display: inline-block;
                  text-decoration: none;
                  height: 16px;
                  line-height: 20px;
                "
                ><img src="../../assets/netSafe.png" style="float: left" />
                <p
                  style="
                    float: left;
                    height: 20px;
                    line-height: 20px;
                    margin: 0px 0px 0px 5px;
                    color: #939393;
                  "
                >
                  京公网安备 11011402013859号
                </p></a
              ></span
            >
            <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备20028134号-2</a >
          </div>
        </div>
      </div>
    </div>
    <loginpage
      @loginhome="loginhome"
      ref="loginref"
      @changeFlag="changeFlag"
    ></loginpage>
    <addInfo ref="addInfoRef" @changeUser="changeUser"></addInfo>
  </div>
</template>

<script>
import loginpage from "@/components/loginpage";
import addInfo from "@/components/addInfo.vue";
import Cookies from "js-cookie";
import { setStore, getStore } from "@/libs/storage";
import {  checkKeyToken, checkOutToken  } from "@/api/api.js";

export default {
  components: {
    loginpage,
    addInfo,
  },
  data() {
    return {
      scrollTop: 0,
      flag: true,
      vip: "",
      isId: "",
      userInfo: "",
    };
  },
  methods: {
    getClass() {
      //
      if (this.scrollTop > 50) {
        // 给个名字
        return "NYG3ffBi productBg ";
      } else {
        return "NYG3ffBi productBg B7NL5wM6";
      }
    },
    login() {
      this.$refs.loginref.activeTabName = "login";
      this.$refs.loginref.showModal = true;
      // this.flag = !this.flag
    },

    experience() {
      // console.log(getStore("vip"), "111111");
      if (this.flag) {
        this.login();
      } else {
        if (getStore("vip") == 1 && this.isId.length > 0) {
          this.$router.push("chatWithZGApp");
        } else {
          this.vip = getStore("vip");
        }
      }
    },
    exitbtn(v) {
      console.log(v);
      if (v === "logout") {
        let userInfo = Cookies.get("userInfo");
        // fetch(
        //   `http://101.200.140.188:38892/check_out/${
        //     JSON.parse(userInfo).accessToken
        //   }/${getStore("cur")}`
        // )
        checkOutToken(`${JSON.parse(userInfo).accessToken}/${getStore("cur")}`)
        .then((response) => {
          // infoRes.json().then((response) => {
            console.log("退出登录");
            this.flag = !this.flag;
            this.vip = "";
            this.isId = "";
            Cookies.remove("userInfo");
            Cookies.set("accessToken", "");
            setStore("vip", "");
            setStore("uid", "");
            setStore("accessToken", "");
          // });
        });
      }

      if (v == "userinfo") {
        // this.$refs.addInfoRef.showModal = true;
      }
    },
    loginhome(v) {
      if (v) {
        this.flag = !this.flag;
        let scd = v.mobile;
        const start = scd.substr(0, 3);
        const middle = "*****";
        const end = scd.substr(8, 3);
        this.userInfo = `${start}${middle}${end}`;
        this.accToken = v.accessToken;
      }
    },
    Add_info(v) {
      let userInfo = Cookies.get("userInfo");
      this.$refs.addInfoRef.showModal = true;
      this.$refs.addInfoRef.formValidate.mobile = JSON.parse(userInfo).mobile;
    },

    changeUser(v) {
      console.log(v, "修改xin xi");
      this.isId = v.id;
    },
    changeFlag(v) {
      this.vip = v.vip;
      this.isId = v.id;
    },
  },
  mounted() {
    let userInfo = Cookies.get("userInfo");
    if (!userInfo) {
      this.flag = true;
    } else {
      this.flag = false;
      let scd = JSON.parse(userInfo).mobile;
      const start = scd.substr(0, 3);
      const middle = "*****";
      const end = scd.substr(8, 3);
      this.userInfo = `${start}${middle}${end}`;
      this.accToken = JSON.parse(userInfo).accessToken;

      // fetch(
      //   `http://101.200.140.188:38892/check_key/${
      //     JSON.parse(userInfo).accessToken
      //   }`
      // )
      checkKeyToken(JSON.parse(userInfo).accessToken)
      .then((res) => {
        // infoRes.json().then((res) => {
          if (res.exists !== undefined) {
            setStore("vip", res.vip);
            setStore("uid", res.username);
            setStore("id", res.id);
            setStore("cur", res.cur);
            this.vip = res.vip;
            this.isId = res.id;
            console.log("vip==", this.vip, "isid==", this.isId);
          } else {
            this.$Message.error("登录过期，请重新登录");
            this.vip = '';
            this.isId = '';
            Cookies.remove("userInfo");
            Cookies.remove("accessToken");
            setStore("accessToken", "");
            setStore("vip", "");
            this.$router.go(0);
          }
        // });
      });
    }
  },
};
</script>
<style lang="less" scoped>
.productBg {
}
.B7NL5wM6 {
  backdrop-filter: saturate(180%) blur(20px) !important;
  -webkit-backdrop-filter: saturate(180%) blur(20px) !important;
  background: rgba(228, 237, 250, 0.5) !important;
  border-bottom: 0px solid hsla(0, 0%, 100%, 0.6) !important;
}
.KJWhP3Y0 {
  display: flex;
  justify-content: center;
  align-items: center;
  .j6cvrvvX {
    background: url("@/assets/banner1920.jpg") no-repeat;
    background-position: 50%;
    background-size: auto 100%;
  }
}
.DDl4VNjw1 {
  flex: 1;
  position: relative;
  top: -25px;
}
.DDl4VNjw1,
.YYaIFHTz {
  display: flex;
  justify-content: flex-end;
}
.avatar {
  margin-left: 20px;
  width: 25px;
}
.fjiYtnUB1 {
  max-width: 152px !important;
}
.QwpKZ0nk > div {
  margin: auto;
  padding: 0rem 0 0rem;
  position: relative;
}
.o3QY36gMbg {
  width: 100%;
  // background: url("../assets/pic1.png") no-repeat;
  // background-position: 50% 100%;
  // background-size: auto;
  bottom: 0;
  left: 0;
}
.o3QY36gMbg > div {
  margin: auto;
  padding: 0.9rem 0 0.99rem;
  position: relative;
}

.QwpKZ0nk .eEwIcus1 .TGwmB_Rv {
  border: 0px;
  justify-content: flex-start;
}
.P_mA8TF61 {
  font-size: 0.42rem;
  color: #fff;
}
.dcxsejIF {
  background: url(../../assets/bg1920.jpg);
  background-size: cover;
}
.QwpKZ0nk > div {
  margin: auto;
  // padding: 0rem 0 0rem;
  position: relative;
}
.QwpKZ0nk .eEwIcus1 {
  justify-content: center;
}

.QwpKZ0nk .eEwIcus1 .hc8bJX9E {
  justify-content: flex-start;
  background: url("../../assets/pic1.png") no-repeat;
  background-position: 50% 50%;
  background-size: auto;
  // margin-bottom: 50px;
}
.QwpKZ0nk .eEwIcus1 .hc8bJX9E1 {
  margin-bottom: 50px;
  width: 100%;
  color: #000;
  font-size: 0.3rem;
  font-weight: 600;
  text-align: center;
}
.QwpKZ0nk .eEwIcus1 .hc8bJX9E1 strong {
  color: #000;
  font-size: 0.3rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 0.3rem;
  text-align: center;
  justify-content: flex-start;
  white-space: nowrap;
}
.QwpKZ0nk .eEwIcus1 .TGwmB_Rv {
  background: rgba(0, 0, 0, 0);
}
.nPTJmMSE + .nPTJmMSE {
  border: 0px;
}
.nPTJmMSE + .nPTJmMSE {
  margin-top: 15px;
  border-top: 0px;
}
.H5iqHK2R {
  // padding-left: 1rem;
  width: 454px;
  height: 64px;
  padding-left: 30px;
  background: url("../../assets/bgwenzi.png") no-repeat;
  // background-size: 100% 100%;
}
.xjyVk_out {
  position: relative;
  top: 15%;
  width: 80%;
  height: 228px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  // margin-bottom: 5%;
}
.xjyVk_into {
  position: absolute;
  top: -15%;
  left: 5%;
  padding: 0.5rem;
  text-align: center;
  height: 130%;
  width: 90%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.xjyVk_into_icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.3rem;
  width: 0.3rem;
  background: url(../../assets/pic3.png);
  background-size: cover;
}
.dcxsejIF {
  height: 5.5rem;
}

.dcxsejIF .Iqv9guQQ .xjyVkKvB {
  margin-top: 0rem;
}
.o7jNbRcF {
  background-image: none;
  padding-top: 0.5rem;
}
.o7jNbRcF .o8D9H1v_ {
  background: url("../../assets/pic2.png") no-repeat;
  background-position: 90%;
  // background-size: 40% 60%;
}
.a1JlOgfY {
  background-color: #fcfcfc;
}
.a1JlOgfY .ifyJraSx {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 20px;
}
.a1JlOgfY span {
  text-align: center;
  line-height: 20px;
}
.waitingPass {
  font-weight: 700;
  font-size: 18px;
  line-height: 50px;
  color: #000;
}
.waitingPassIcon {
  width: 15px;
  margin-right: 5px;
  margin-bottom: 3px;
}
@media screen and (max-width: 800px) {
  .dcxsejIF .Iqv9guQQ {
    padding: 0.6rem 0 0.99rem;
    width: 6.5rem;
  }
  .o3QY36gM {
    width: 85%;
  }
  .o3QY36gMbg {
    background: url("../../assets/pic1.png") no-repeat;
    background-position: 50% 100%;
    background-size: auto;
  }
  .dcxsejIF .Iqv9guQQ .P_mA8TF6 {
    font-family: PingFangSC-Semibold;
    font-size: 0.3rem;
    line-height: 0.6rem;
  }
  .P_mA8TF61 {
    text-align: center;
    font-size: 0.3rem;
    color: #fff;
  }
  .waitingPass {
    font-weight: 700;
    font-size: 14px;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .o7jNbRcF .o8D9H1v_ {
    background-position: 50% 90%;
    background-size: 80%;
  }
  .P_mA8TF61 {
    text-align: center;
  }
  .o3QY36gMbg {
    // width: 100%;
    background-size: 130%;
  }
  .o7jNbRcF .o8D9H1v_ {
    background-position: 50% 90%;
    background-size: 80%;
  }
  .banner_person {
    background: url("../../assets/banner1920fen2.png") no-repeat;
    background-size: 100%;
    z-index: 1;
    position: relative;
    left: 100px;
    width: 200px;
    height: 200px;
    margin-top: 20px;
  }
  .H5iqHK2R {
    // padding-left: 1rem;
    width: 100vw;
    padding-left: 30px;
    background-size: 100% 100%;
  }
  .xjyVk_into_icon {
    height: 0.2rem;
    width: 0.2rem;
  }
  .nPTJmMSE {
    position: relative;
    left: -70px;
  }
  .xjyVk_out {
    width: 100%;
    .xjyVk_into {
      padding: 10px;
    }
  }
}
</style>
