const defaultQuestions = [
  '和利时LX系列产品的运动控制能力主要有哪些特点？',
  '和利时LX产品可适用于哪些环境条件？',
  '和利时LK系列控制系统可配置哪些类型的网络结构？',
  'LK220控制器可配置多少个POWERLINK主站通信模块？',
  '如何为和利时LK220控制器添加POWERLINK从协议？',
  "和利时LK130主控背板有几个插槽？",
  "请用ST代码实现红绿灯的控制。",
  "使用ST语言编写代码，实现输入信号的滑动滤波功能。",
  "编写ST代码根据输入的温度控制风扇速度的输出。",
  "编写ST代码获取一个整数除2的余数。",
  "编写ST代码判断输入是否为0。",
  "编写ST代码根据输入的温度控制电压的输出。",

  "品质管理部都负责那些工作？",
  "公共设施行业的信贷风险有哪些？",
  "钻头的报废标准是什么？",
  "设备档案的建立要求是什么？",
  "各种机械在使用中应具备的基本条件是什么？",
  "设备使用管理的要求是什么？",
  "设备管理考核指标是什么？",

  "设备预防保养管理的发展过程是什么？",
  "设备维护规定的目的是什么？",
  "设备使用规程必须包括的内容有什么？",
  "设备缺陷的处理办法是什么？",
  "计量设备的校验方法有哪些？",
  "检验设备控制程序的职责是什么？",
  "测量设备的范围是什么？",
  "硬件开发及设备研制程序的工作要求是什么？",
  "车床的常用配备通用工具有哪些？",
  "检验和试验仪器设备的使用和维护方法是什么？",
  "设备管理的意义是什么？",
  "电动车维修保养包括什么？",


  "安全标志的使用范围是什么？",
  "什么情况下对电动机进行堵转试验？",
  "什么样的产品可以申请 GS 认证？",
  "个人防护用品在预防职业中毒工作中有什么意义？",
  "生管部职责是什么？",
  "订单首件检验的检验流程是什么？",
  '钥匙保管人应遵守哪些条件？',

];

export default defaultQuestions