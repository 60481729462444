<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
   
  },
  mounted() {
    if (this._isMobile()) {
      // this.$router.replace("/webapp");
    } else {
      // this.$router.replace("/chatWithZG");
    }
    var //color = '#1E9FFF'
      color = "#014397";
    
    // 长按
    document.documentElement.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      { passive: false }
    );
    // 禁止双击放大
    let lastTouchEnd = 0;
    document.documentElement.addEventListener(
      "touchend",
      function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      { passive: false }
    );
  },
  beforeDestroy() {},
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  watch: {
   
  },
};
</script>
<style lang="less">
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* 设置滚动条轨道的背景色 */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* 设置滚动条滑块的背景色 */
::-webkit-scrollbar-thumb {
  background-color: #aaa;
}

/* 鼠标悬停在滚动条上时，设置滑块的背景色 */
::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}
</style>
