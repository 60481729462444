<template>
  <div>
   
    <!--  -->
    <div class="tabComponent">
      <Tabs :value="tabList.length > 0 ? tabList[0].name : '暂无文件'">
        <Tab-pane
          v-for="(tabData, fIndex) in tabList"
          :key="fIndex"
          :label="tabData.name"
          :name="tabData.name"
        >
          <div class="tabPaneContainer">
            <MarkdownPro
              :initialValue="answerData"
              :value="answerData"
              :copyCode="true"
              copyBtnText="复制"
              theme="dark"
              :isPreview="false"
              :height="850"
              v-if="tabData.tabDatatype === 'markdown'"
            />
            
            <iframe
              v-else-if="tabData.tabDatatype === 'pdf'"
              class="inlineFramePdf"
              title="Inline Frame Example"
              style="height: calc(100vh - 150px); width: 100vw"
              :src="tabData.src"
            >
            </iframe>
            <iframe
              v-else-if="tabData.tabDatatype === 'html'"
              class="inlineFrameExample"
              title="Inline Frame Example"
              style="height: calc(100vh - 150px); width: 100vw"
              :src="tabData.src"
            >
            </iframe>
          </div>
        </Tab-pane>
        <!-- <Tab-pane label="CvsBtn" name="CVS_TEST">
          <CvsBtn></CvsBtn>
        </Tab-pane> -->
      </Tabs>
    </div>
  </div>
</template>

<script>
import { Tabs } from "view-design";
import { MarkdownPro } from "vue-meditor";
import CvsBtn from "@/components/csv.vue";
// import VueOfficeDocx from "@vue-office/docx";
// import VueOfficeExcel from "@vue-office/excel";
// import VueOfficePdf from "@vue-office/pdf";
// import "@vue-office/docx/lib/index.css";
// import "@vue-office/excel/lib/index.css";

export default {
  components: {
    Tabs,
    CvsBtn,
    MarkdownPro,
    // VueOfficeDocx,
    // VueOfficeExcel,
    // VueOfficePdf,
  },
  data() {
    return {
      answerData: "",
      tabList: [],
    };
  },
  methods: {
    returnTabIcon(type) {
      switch (type) {
        case "html":
          return "social-chrome";
        case "markdown":
          return "code";
        case "pdf":
          return "android-document";
        case "excel":
          return "ios-grid-view";
        case "word":
          return "document-text";

        default:
          break;
      }
    },
    rendered() {
      console.log("渲染完成");
    },
    errorHandler() {
      console.log("渲染失败");
    },
    matchAnswer() {
      console.log("需要在这儿正则匹配地址信息");
      // this.answerData
    },
  },
  mounted() {
    //  setInterval(()=>{
    //    console.log(this.answerData,"改了没")
    // },15000)
  },
};
</script>

<style>
.tabComponent {
  height: calc(100vh - 50px);
  position: relative;
}
.tabPaneContainer {
  /* width: 100%; */
  height: calc(100vh - 50px);
  overflow: auto;
}
</style>
