<template>
  <div class="home">
    <div id="root">
      <div class="home_news_detail animate__animated">
        <div v-show="isOpen" class="mask animate__animated animate__fast">
          <div class="dialog animate__animated animate__faster">
            <div class="closeNewsDialog" @click="closeDialog()">
              <div class="outer">
                <div class="inner">
                  <label>关闭</label>
                </div>
              </div>
            </div>
            <div class="Messaging_News">
              <div class="Messaging_text">
                <div class="Messaging_text_tr">
                  <span class="span">{{ informations[newsIndex].title }}</span>
                  <p class="author">作者:{{ informations[newsIndex].author }}</p>
                  <p class="p">日期:{{ informations[newsIndex].createTime }}</p>
                </div>
              </div>
              <div class="content" style="user-select: none">
                <div v-html="informations[newsIndex].content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="loginBox animate__animated" v-if="showLoginModal == true">
        <div class="closeNewsDialog" @click="showLoginModal = false" style="cursor: pointer"><img src="~@/assets/home/home_close_login.png" style="width: 30px" /></div>
        <div class="login_flex animate__animated animate__faster">
          <div class="login_banner">
            <div key="" class="home_media_content">
              <div class="home_media_content_inner">
                <div class="home_login_media_content">
                  <Carousel :loop="true" :autoplay="true" :autoplay-speed="3000" arrow="hover">
                    <CarouselItem style="overflow: hidden">
                      <img class="carousel_img" src="~@/assets/home/login_banner1.png" alt="" />
                    </CarouselItem>
                    <CarouselItem>
                      <img class="carousel_img" src="~@/assets/home/login_banner2.png" alt="" />
                    </CarouselItem>
                    <CarouselItem>
                      <img class="carousel_img" src="~@/assets/home/login_banner3.png" alt="" />
                    </CarouselItem>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          <div class="login_box">
            <loginpage @loginhome="loginhome" ref="loginref" @changeFlag="changeFlag"></loginpage>
          </div>
        </div>
      </div>
      <!-- <div :class="getClass()">
        <div class="XDxYjHlu">
          <div class="fjiYtnUB1">
            <a href="/"><img alt="logo" class="qbUby_dV" src="@/assets/logo.png" /></a>
          </div>
          <div class="DDl4VNjw1">
            <ul class="ant-menu-overflow ant-menu ant-menu-root ant-menu-horizontal ant-menu-light" role="menu" tabindex="0" data-menu-list="true" style="border: 0px; background: transparent">
              <li class="ant-menu-overflow-item ant-menu-submenu ant-menu-submenu-horizontal" role="none" style="opacity: 1; order: 0">
                <div role="menuitem" class="ant-menu-submenu-title" tabindex="-1" aria-expanded="false" aria-haspopup="true" data-menu-id="rc-menu-uuid-32557-1-login" aria-controls="rc-menu-uuid-32557-1-login-popup">
                  <span class="ant-menu-title-content">
                    <div v-if="flag">
                      <div class="Qyj8AoQa" @click="login">登录</div>
                    </div>
                    <div class="A5NuDILa1" v-if="!flag">
                      <Dropdown @on-click="exitbtn">
                        <img class="avatar" src="@/assets/logoFav.png" alt="" />
                        <DropdownMenu slot="list">
                          <DropdownItem name="userinfo">{{ userInfo }}</DropdownItem>
                          <DropdownItem name="logout">退出登录</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div> </span
                  ><i class="ant-menu-submenu-arrow"></i>
                </div>
              </li>
              <li class="ant-menu-overflow-item ant-menu-overflow-item-rest ant-menu-submenu ant-menu-submenu-horizontal ant-menu-submenu-disabled" aria-hidden="true" role="none" style="opacity: 0; height: 0px; overflow-y: hidden; order: 2147483647; pointer-events: none; position: absolute">
                <div role="menuitem" class="ant-menu-submenu-title" aria-expanded="false" aria-haspopup="true" aria-disabled="true" data-menu-id="rc-menu-uuid-32557-1-rc-menu-more" aria-controls="rc-menu-uuid-32557-1-rc-menu-more-popup">
                  <span role="img" aria-label="ellipsis" class="anticon anticon-ellipsis"
                    ><svg viewBox="64 64 896 896" focusable="false" data-icon="ellipsis" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                      <path d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z"></path></svg></span
                  ><i class="ant-menu-submenu-arrow"></i>
                </div>
              </li>
            </ul>
            <div aria-hidden="true" style="display: none"></div>
          </div>
        </div>
      </div> -->
      <div class="SyBUiXPj" id="productContainer">
        <div>
          <div class="KJWhP3Y0">
            <div class="HjrMd8LI home_title">
              <img src="@/assets/home/home_logo.png" alt="" />
              <div class="home_weChat">
                <div style="margin-right: 30px">
                  <img src="@/assets/home/home_wechat_code_logo.png" alt="" />
                  <span>智工公众号 <img src="@/assets/home/home_wechat_code.png" alt="" class="home_weChat_code" /></span>
                </div>
                <div style="margin-right: 10px">
                  <img src="@/assets/home/home_mini_program_icon.png" alt="" />
                  <span>智工小程序 <img src="@/assets/home/home_mini_program.png" alt="" class="home_weChat_code" /></span>
                </div>
                <div class="Qyj8AoQa" @click="login" v-if="flag == true">登录</div>

                <ul class="ant-menu-overflow ant-menu ant-menu-root ant-menu-horizontal ant-menu-light" role="menu" tabindex="0" data-menu-list="true" style="border: 0px; background: transparent" v-if="!flag">
                  <li class="ant-menu-overflow-item ant-menu-submenu ant-menu-submenu-horizontal" role="none" style="opacity: 1; order: 0">
                    <div role="menuitem" class="ant-menu-submenu-title" tabindex="-1" aria-expanded="false" aria-haspopup="true" data-menu-id="rc-menu-uuid-32557-1-login" aria-controls="rc-menu-uuid-32557-1-login-popup">
                      <span class="ant-menu-title-content">
                        <div class="A5NuDILa1" v-if="!flag">
                          <Dropdown @on-click="exitbtn">
                            <img class="avatar" src="@/assets/logoFav.png" alt="" />
                            <DropdownMenu slot="list">
                              <DropdownItem name="userinfo">{{ userInfo }}</DropdownItem>
                              <DropdownItem name="logout">退出登录</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div> </span
                      ><i class="ant-menu-submenu-arrow"></i>
                    </div>
                  </li>
                  <!-- <li class="ant-menu-overflow-item ant-menu-overflow-item-rest ant-menu-submenu ant-menu-submenu-horizontal ant-menu-submenu-disabled" aria-hidden="true" role="none" style="opacity: 0; height: 0px; overflow-y: hidden; order: 2147483647; pointer-events: none; position: absolute">
                    <div role="menuitem" class="ant-menu-submenu-title" aria-expanded="false" aria-haspopup="true" aria-disabled="true" data-menu-id="rc-menu-uuid-32557-1-rc-menu-more" aria-controls="rc-menu-uuid-32557-1-rc-menu-more-popup">
                      <span role="img" aria-label="ellipsis" class="anticon anticon-ellipsis"
                        ><svg viewBox="64 64 896 896" focusable="false" data-icon="ellipsis" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                          <path d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z"></path></svg></span
                      ><i class="ant-menu-submenu-arrow"></i>
                    </div>
                  </li> -->
                </ul>
              </div>
            </div>
            <div class="j6cvrvvX"></div>
            <div class="HjrMd8LI">
              <strong>首个工业领域</strong>
              <strong>垂直大模型</strong>
              <span class="KTFDGqJT" @click="experience" v-show="vip === '' && isId.length == 0">加入体验</span>
              <span class="KTFDGqJT" @click="experience" v-show="vip == '1' && isId.length > 0">立刻体验</span>
              <span class="KTFDGqJT KTFDGqJT1" @click="Add_info" v-show="((vip == '0' || vip == '1') && isId.length == 0) || vip === null">填写申请信息</span>
              <div class="home_poster" v-if="showPosterModal">
                <addInfo ref="addInfoRef" @changeUser="changeUser"></addInfo>
              </div>
              <div class="home_loading" v-show="vip == '0' && isId.length > 0">
                <div class="box">
                  <div class="one"></div>
                  <div class="two"></div>
                  <div class="three"></div>
                  <div class="four"></div>
                  <div class="five"></div>
                  <div class="six"></div>
                  <div class="seven"></div>

                  <div id="loading-bar">
                    <h2>正在审核中</h2>
                    <div id="bar">
                      <div id="progress"></div>
                    </div>
                  </div>
                </div>
              </div>

              <span class="waitingPass" v-show="vip == '0' && isId.length > 0"><img class="waitingPassIcon" src="@/assets/success.png" />您已在等待体验中，加入成功将收到短信通知。 </span>
              <span class="waitingPass" v-show="vip == '2'"><img class="waitingPassIcon" src="@/assets/notice.png" />会员时间已过期。 </span>
            </div>
          </div>
          <!-- <div class="QwpKZ0nk">
            <div class="o3QY36gMbg">
              <div class="o3QY36gM">
                <div class="eEwIcus1">
                  <div class="TGwmB_Rv">
                    <div class="hc8bJX9E1">“智工”有哪些关键能力</div>
                    <div class="nPTJmMSE">
                      <div class="H5iqHK2R">
                        <div class="cyWAQXAU">
                          <span> 提供对应的专业资料查阅 </span>
                        </div>
                      </div>
                    </div>
                    <div class="nPTJmMSE">
                      <div class="H5iqHK2R">
                        <div class="cyWAQXAU">
                          <span>以自然语言和对话的方式，获取工业领域的专业知识</span>
                        </div>
                      </div>
                    </div>
                    <div class="nPTJmMSE">
                      <div class="H5iqHK2R">
                        <div class="cyWAQXAU">
                          <span>帮助你编写工业领域的控制代码</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="dcxsejIF">
            <div class="F7Nvqu1M"></div>
            <div class="Iqv9guQQ">
              <strong class="P_mA8TF61">工业领域加强大语言模型</strong>
              <div class="xjyVk_out">
                <div class="xjyVk_into">
                  <div class="xjyVk_into_icon"></div>
                  <div class="xjyVkKvB">
                    通过大模型的学习能力和上下文语义理解，打造工业领域的多轮智能知识问答能力，形成工业领域行业知识、专业知识、厂内知识、操作指导的生成式检索； 同时，提供工业级设备控制程序动态编程支持，实现DCS、PLC、伺服器、变频器、机械臂等设备的控制代码生成，提升设备控制编码与调试的效率
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="o7jNbRcF">
            <div class="o8D9H1v_">
              <div>
                <strong class="wFsEli0R">陪伴“智工”一起成长</strong>
                <div class="RYWGU4QA">
                  你的每一次提问、每一次反馈、每一次需求登记，都能够帮助模型更好的学习和迭代，非常感谢通过“使用反馈”功能登记您的需求，让我们一起见证“智工”的成长
                  <br />
                  <br />
                  届时，我们将推出“智工”诞生记Vlog
                </div>
              </div>
            </div>
          </div> -->

          <!-- v2版本官网 -->
          <div style="position: relative; top: -50px">
            <div class="home_block_title">
              <div class="home_block_title_en">MEDIA REPORTS</div>
              <div class="home_block_title_cn">
                <div class="home_block_title_line"></div>
                媒体报道
                <div class="home_block_title_line"></div>
              </div>
            </div>
          </div>

          <div class="home_media_tabs">
            <div class="home_media_tabs_inner">
              <div :class="chosenTab == 0 ? 'home_media_tab home_media_tab_selected' : 'home_media_tab'" @click="chosenTab = 0">新闻咨询</div>
              <div :class="chosenTab == 1 ? 'home_media_tab home_media_tab_selected' : 'home_media_tab'" @click="chosenTab = 1">功能介绍</div>
            </div>
          </div>
          <div class="home_media_content fade-transition" v-if="chosenTab == 0">
            <div class="home_media_content_inner">
              <div class="home_media_content_inner_title">
                <div class="home_media_news" v-for="(item, index) in informations" :key="index">
                  <div class="home_media_news_time">
                    <div>{{ item.createDate }}</div>
                    {{ item.createTime }}
                  </div>
                  <div class="home_media_news_content">
                    <div class="home_media_news_content_img" :style="`background-image: url(${item.pic}); background-size: cover; background-position: center center;`"></div>
                    <div class="home_media_news_content_source">
                      <div class="home_media_news_content_title">{{ item.title }}</div>
                      <div class="home_media_news_content_content">{{ item.sub }}</div>
                      <span class="home_media_news_content_more" @click="openDialog(item, index)">查看详情</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div key="" class="home_media_content" v-else>
            <div class="home_media_content_inner">
              <div class="home_media_content_inner_title">
                <Carousel :loop="true" :autoplay="true" :autoplay-speed="3000" arrow="hover" type="card">
                  <CarouselItem style="overflow: hidden">
                    <img class="carousel_img" src="~@/assets/home/zhigong_other1.png" alt="" />
                  </CarouselItem>
                  <CarouselItem>
                    <img class="carousel_img" src="~@/assets/home/zhigong_other2.png" alt="" />
                  </CarouselItem>
                  <CarouselItem>
                    <img class="carousel_img" src="~@/assets/home/zhigong_other3.png" alt="" />
                  </CarouselItem>
                </Carousel>
              </div>
            </div>
          </div>

          <div class="home_block_title">
            <div class="home_block_title_en">MORE FUNCTION</div>
            <div class="home_block_title_cn">
              <div class="home_block_title_line"></div>
              更多功能
              <div class="home_block_title_line"></div>
            </div>
          </div>
          <div class="home_more_function">
            <div v-for="(item, index) in moreFunction" class="home_more_function_item" :key="index">
              <div class="home_more_function_item_icon">
                <img :src="item.image" alt="" />
              </div>
              <div class="home_more_function_item_text">
                <div class="home_more_function_item_text_title">{{ item.title }}</div>
              </div>
            </div>
          </div>
          <div class="home_stay_tuned">
            <div class="home_block_title" style="margin-top: 50px">
              <div class="home_block_title_en">STAY TUNED</div>
              <div class="home_block_title_cn">
                <div class="home_block_title_line"></div>
                智工大事记
                <div class="home_block_title_line"></div>
              </div>
            </div>
            <div class="home_big_functions">
              <div v-for="(item, index) in bigFunctions" :key="index" class="home_big_functions_item">
                <div class="home_big_functions_item_text">
                  <div class="home_big_functions_item_text_title">{{ item.title }}</div>
                  <div class="home_big_functions_item_text_address"><img src="../assets/home/home_icon_address.png" alt="" /> {{ item.address }}</div>
                  <div class="home_big_functions_item_text_content">{{ item.content }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="home_footer">
            <div class="a1JlOgfY">
              <span> Copyright 2008-2023   中工互联（北京）科技集团有限公司   版权所有    </span>
            </div>
            <div data-v-577e7b14="" class="ciicWechat"></div>
            <div class="a1JlOgfY" style="text-align: center; padding: 15px auto; padding-bottom: 20px">
              <a href="https://beian.miit.gov.cn/" target="_blank" style="margin-right: 10px; height: 16px; line-height: 20px">京ICP备20028134号-2</a
              ><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402013859" style="display: inline-block; text-decoration: none; height: 16px; line-height: 20px"
                ><img src="../assets/netSafe.png" style="float: left" />
                <p style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px; color: #939393">京公网安备 11011402013859号</p></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="login_banner" v-if="hideBanner">
      <div key="" class="home_media_content">
        <div class="home_media_content_inner">
          <div class="home_login_media_content">
            <Carousel :loop="true" :autoplay="true" :autoplay-speed="3000" arrow="hover">
              <CarouselItem style="overflow: hidden">
                <img class="carousel_img" src="~@/assets/home/login_banner1.png" alt="" />
              </CarouselItem>
              <CarouselItem>
                <img class="carousel_img" src="~@/assets/home/login_banner2.png" alt="" />
              </CarouselItem>
              <CarouselItem>
                <img class="carousel_img" src="~@/assets/home/login_banner3.png" alt="" />
              </CarouselItem>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import loginpage from "@/components/loginpage";
  import addInfo from "@/components/addInfo.vue";
  import Cookies from "js-cookie";
  import axios from "axios";
  import { setStore, getStore } from "@/libs/storage";
  import { checkKeyToken, checkOutToken, getNewsType, getNews } from "@/api/api";
  import zhigongOther1 from "@/assets/home/zhigong_other1.png";
  import zhigongOther2 from "@/assets/home/zhigong_other2.png";
  import zhigongOther3 from "@/assets/home/zhigong_other3.png";

  import "swiper/swiper-bundle.css";
  import "./homeView.less";
  import Swiper, { Pagination, Navigation, Autoplay } from "swiper";
  Swiper.use([Pagination, Navigation, Autoplay]);

  export default {
    components: {
      loginpage,
      addInfo,
    },
    data() {
      return {
        scrollTop: 0,
        flag: true,
        vip: "",
        isId: "",
        userInfo: "",
        showLoginModal: false,
        showPosterModal: false,
        chosenTab: 0,
        isOpen: false,
        newsIndex: 0,
        carouselData: {
          value: 0,
          images: [
            {
              images: zhigongOther1,
            },
            {
              images: zhigongOther2,
            },
            {
              images: zhigongOther3,
            },
          ],
        },
        moreFunction: [
          {
            image: require("@/assets/home/home_codeplus.png"),
            title: "涵盖了工业级代码开发全过程的主要阶段，包括场景理解、语言指定、代码编写、代码调试和代码理解，创新性的在开发过程中引入智能方法，提高代码开发效率",
          },
          {
            image: require("@/assets/home/home_knowledge.png"),
            title: "基于大模型的工业知识问答向工业生产链条的多环节快速渗透，帮助企业整合和利用各种生产数据、设备数据、质量数据等方面的专业知识，为企业提供生产优化和质量控制的决策支持。",
          },
          {
            image: require("@/assets/home/home_scada.png"),
            title: "通过统一交互入口，实现基于自然语言的指令理解和操作响应，支持SCADA相关的点位趋势、流程图查看、点位报警、点位配置等操作，基于设备运行模型和知识库，提供设备故障预测和报警处理建议等智能分析功能。",
          },
          {
            image: require("@/assets/home/home_xmaas.png"),
            title: "提供模型训练服务、模型部署服务和模型调用服务。模型训练服务根据用户提供的数据和配置参数来训练模型模型部署服务将训练好的模型部署到云端或本地环境。模型调用服务通过API接口调用已部署的模型。",
          },
        ],
        bigFunctions: [
          {
            title: "2023 年 6 月 4 日",
            address: "北京国家会议中心",
            content: "中工互联发布中国首个工业领域大模型---智工·工业大模型",
          },
          {
            title: "2023 年 9 月 20 日",
            address: "上海国际会展中心",
            content: "中工互联发布智工·工业大模型 2.0 版本",
          },
        ],
        informations: [
          {
            title: "",
            time: "2023/6",
            createDate: "4",
            author: "中工互联",
            createTime: "2023/6/4",
            id: "1234567890",
            sub: "",
            content: "",
            image: require("@/assets/home/zhigong_other3.png"),
          },
          {
            title: "",
            time: "2023/6",
            createDate: "4",
            author: "中工互联",
            id: "0987654321",
            sub: "",
            content: "",
            image: require("@/assets/home/zhigong_other1.png"),
          },
        ],
        hideBanner: false,
        swiperOptions: {
          pagination: {
            el: ".swiper-pagination",
          },
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          effect: "coverflow",
          slidesPerView: 1.2,
          spaceBetween: 2,
          loopedSlides: 5,
          centeredSlides: true,
          centeredSlidesBounds: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          loop: true,
        },
        loadingProgram: null,
      };
    },
    methods: {
      getClass() {
        //
        if (this.scrollTop > 50) {
          // 给个名字
          return "NYG3ffBi productBg ";
        } else {
          return "NYG3ffBi productBg B7NL5wM6";
        }
      },
      login() {
        this.showLoginModal = true;
        setTimeout(() => {
          this.$refs.loginref.activeTabName = "login";
          this.$refs.loginref.showModal = true;
        }, 200);

        // this.flag = !this.flag
      },

      experience() {
        if (this.flag) {
          this.login();
        } else {
          if (getStore("vip") == 1 && this.isId.length > 0) {
            this.$router.push("chatWithZG");
          } else {
            this.vip = getStore("vip");
          }
        }
      },
      exitbtn(v) {
        console.log(v);
        if (v === "logout") {
          let userInfo = Cookies.get("userInfo");
          checkOutToken(`${JSON.parse(userInfo).accessToken}/${getStore("cur")}`).then((response) => {
            console.log("退出登录");
            this.flag = !this.flag;
            this.vip = "";
            this.isId = "";
            Cookies.remove("userInfo");
            Cookies.set("accessToken", "");
            setStore("vip", "");
            setStore("uid", "");
            setStore("accessToken", "");
            this.showPosterModal = false;
          });
        }

        if (v == "userinfo") {
          // this.$refs.addInfoRef.showModal = true;
        }
      },
      loginhome(v) {
        if (v) {
          this.flag = !this.flag;
          let scd = v.mobile;
          const start = scd.substr(0, 3);
          const middle = "*****";
          const end = scd.substr(8, 3);
          this.userInfo = `${start}${middle}${end}`;
          this.accToken = v.accessToken;
          setTimeout(() => {
            this.showLoginModal = false;
            if (this.vip == "0" && this.isId.length == 0) {
              this.showPosterModal = true;
            }
          }, 200);
        }
      },
      Add_info(v) {
        this.showPosterModal = !this.showPosterModal;
        if (this.showPosterModal == true) {
          setTimeout(() => {
            let userInfo = Cookies.get("userInfo");
            this.$refs.addInfoRef.showModal = true;
            this.$refs.addInfoRef.formValidate.mobile = JSON.parse(userInfo).mobile;
          }, 100);
        }
      },

      changeUser(v) {
        this.isId = v.id;
        this.showPosterModal = false;
        setTimeout(() => {}, 100);
      },
      changeFlag(v) {
        this.vip = v.vip;
        this.isId = v.id;
      },
      getNews() {
        let newsTypeArray = [];
        let newsArray = [];

        getNewsType().then((response) => {
          // console.log(response, "获取新闻类型");
          response.result.forEach((typeItem, typeIndex) => {
            if (typeItem.title == "公司新闻") {
              newsTypeArray.push(typeItem);
            }
          });
          newsTypeArray.forEach((typeItem, typeIndex) => {
            let params = {
              pageNumber: 1,
              pageSize: 10,
              parentTypeId: typeItem.id,
              publicTime: "",
              isPublish: true,
            };
            getNews(params).then((newsRes) => {
              // console.log(newsRes, "新闻列表");
              if (newsRes.success) {
                if (newsRes.result.records.length !== 0) {
                  newsRes.result.records.forEach((e) => {
                    e.createTime = e.createTime.substring(0, e.createTime.indexOf(" "));
                    const dateStr = e.createTime;
                    const date = new Date(dateStr);
                    const year = date.getFullYear();
                    const month = date.getMonth() + 1;
                    const day = date.getDate();
                    e.createTime = year + "-" + month;
                    e.createDate = day;
                    newsArray.push(e);
                  });
                } else {
                  // this.getDelete(1, "pic");
                }
              }
              // console.log(newsArray, "新闻列表");

              // let
              this.informations = newsArray.slice(-2);
            });
          });
        });
      },

      // 展示新闻
      openDialog(newsData, newsIndex) {
        if (newsData.content !== undefined) {
          this.newsIndex = newsIndex;
          this.isOpen = true;
          let oMask = document.getElementsByClassName("mask")[0];
          let oDialog = document.getElementsByClassName("dialog")[0];
          oMask.classList.remove("animate__fadeOut");
          oDialog.classList.remove("animate__zoomOut");
          oMask.classList.add("animate__fadeIn");
          oDialog.classList.add("animate__zoomIn");

          const top = document.documentElement.scrollTop || document.body.scrollTop;
          document.body.style.cssText += `
            position: fixed;
            width: 100vw;
            left: 0;
            top: ${-top}px;
        `;
        }
      },
      closeDialog(e) {
        let oMask = document.getElementsByClassName("mask")[0];
        let oDialog = document.getElementsByClassName("dialog")[0];
        oMask.classList.remove("animate__fadeIn");
        oMask.classList.add("animate__fadeOut");
        oDialog.classList.remove("animate__zoomIn");
        oDialog.classList.add("animate__zoomOut");
        setTimeout(() => {
          this.isOpen = false;
        }, 330);
        const top = document.body.style.top;
        document.body.style.cssText += `
            position: static;
        `;
        window.scrollTo(0, Math.abs(parseFloat(top)));
      },
      renderHomeLoading() {
        let _this = this;
        function progressBar() {
          let progress = document.getElementById("progress");
          let width = 1;
          let loadingProgram = setInterval(frame, 30);
          function frame() {
            if (width >= 100) {
              clearInterval(loadingProgram);
              progressBar();
            } else {
              if (_this.vip == "0" && _this.isId.length > 0) {
                width++;
                progress.style.width = width + "%";
              } else {
              }
            }
            // } else {
            //   clearInterval(id);
            // }
          }
        }
        progressBar();
      },
    },
    mounted() {
      let userInfo = Cookies.get("userInfo");
      if (!userInfo) {
        this.flag = true;
      } else {
        this.flag = false;
        let scd = JSON.parse(userInfo).mobile;
        const start = scd.substr(0, 3);
        const middle = "*****";
        const end = scd.substr(8, 3);
        this.userInfo = `${start}${middle}${end}`;
        this.accToken = JSON.parse(userInfo).accessToken;

        checkKeyToken(JSON.parse(userInfo).accessToken).then((res) => {
          if (res.exists !== undefined) {
            setStore("vip", res.vip);
            setStore("uid", res.username);
            setStore("id", res.id);
            setStore("cur", res.cur);
            this.vip = res.vip;
            this.isId = res.id;
            // console.log("vip==", this.vip, "isid==", this.isId);
          } else {
            this.$Message.error("登录过期，请重新登录");
            this.vip = "";
            this.isId = "";
            Cookies.remove("userInfo");
            Cookies.remove("accessToken");
            setStore("accessToken", "");
            setStore("vip", "");
            this.$router.go(0);
          }
        });
      }
      setTimeout(() => {
        this.getNews();
        this.renderHomeLoading();
      }, 100);
    },
  };
</script>
<style lang="less" scoped>
  .B7NL5wM6 {
    backdrop-filter: saturate(180%) blur(20px) !important;
    -webkit-backdrop-filter: saturate(180%) blur(20px) !important;
    background: rgba(228, 237, 250, 0.5) !important;
    border-bottom: 0px solid hsla(0, 0%, 100%, 0.6) !important;
  }
  .KJWhP3Y0 {
    height: 1050px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #fff;
    .j6cvrvvX {
      // background: url("@/assets/banner1920.jpg") no-repeat;
      background: url("@/assets/home/home_back_image.png") no-repeat;
      background-position: 50% 0;
      // height: 100vh;
      background-size: 100% 100%;
    }
  }
  .home_weChat {
    display: flex;
    justify-content: space-between;
    font-size: 26px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    div {
      position: relative;
    }
    .home_weChat_code {
      width: 1.3rem;
      height: 1.3rem;
      position: absolute;
      top: 35px;
      left: 30px;
      display: none;
      border-radius: 5px;
    }
  }
  .KJWhP3Y0 .HjrMd8LI {
    color: #4486c4;
    position: relative;
    top: -140px;
    // width: 13.4rem;
  }
  .KJWhP3Y0 .HjrMd8LI strong {
    color: #4486c4;
    margin-bottom: 30px;
    font-size: 56px;
    font-weight: bold;
  }
  .KJWhP3Y0 .HjrMd8LI .KTFDGqJT {
    background-image: linear-gradient(180deg, #5072ff 5%, #6a58ff 100%);
    border-radius: 40px;
    font-size: 48px;
    height: 80px;
    min-width: 280px;
    max-width: 350px;
    box-shadow: #869df7 0px 0px 25px 2px;
  }
  .KTFDGqJT1 {
    width: 390px !important;
  }
  .KJWhP3Y0 .home_title {
    max-width: 100%;
    color: #000;
    // line-height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50px;
    span {
      cursor: default;
    }
    span:hover {
      // color: rgb(34, 178, 255);
      .home_weChat_code {
        display: block;
      }
    }
  }
  .DDl4VNjw1 {
    flex: 1;
    position: relative;
    top: -25px;
  }
  .DDl4VNjw1,
  .YYaIFHTz {
    display: flex;
    justify-content: flex-end;
  }
  .avatar {
    margin-left: 20px;
    width: 25px;
  }
  .fjiYtnUB1 {
    max-width: 152px !important;
  }
  .QwpKZ0nk > div {
    margin: auto;
    padding: 0rem 0 0rem;
    position: relative;
  }
  .o3QY36gMbg {
    width: 100%;
    // background: url("../assets/pic1.png") no-repeat;
    // background-position: 50% 100%;
    // background-size: auto;
    bottom: 0;
    left: 0;
  }
  .o3QY36gMbg > div {
    margin: auto;
    padding: 0.9rem 0 0.99rem;
    position: relative;
  }

  .QwpKZ0nk .eEwIcus1 .TGwmB_Rv {
    border: 0px;
    justify-content: flex-start;
  }
  .P_mA8TF61 {
    font-size: 0.42rem;
    color: #fff;
  }
  .dcxsejIF {
    background: url(../assets/bg1920.jpg);
    background-size: cover;
  }
  .QwpKZ0nk > div {
    margin: auto;
    // padding: 0rem 0 0rem;
    position: relative;
  }
  .QwpKZ0nk .eEwIcus1 {
    justify-content: center;
  }

  .QwpKZ0nk .eEwIcus1 .hc8bJX9E {
    justify-content: flex-start;
    background: url("../assets/pic1.png") no-repeat;
    background-position: 50% 50%;
    background-size: auto;
    // margin-bottom: 50px;
  }
  .QwpKZ0nk .eEwIcus1 .hc8bJX9E1 {
    margin-bottom: 50px;
    width: 100%;
    color: #000;
    font-size: 0.3rem;
    font-weight: 600;
    text-align: center;
  }
  .QwpKZ0nk .eEwIcus1 .hc8bJX9E1 strong {
    color: #000;
    font-size: 0.3rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 0.3rem;
    text-align: center;
    justify-content: flex-start;
    white-space: nowrap;
  }
  .QwpKZ0nk .eEwIcus1 .TGwmB_Rv {
    background: rgba(0, 0, 0, 0);
  }
  .nPTJmMSE + .nPTJmMSE {
    border: 0px;
  }
  .nPTJmMSE + .nPTJmMSE {
    margin-top: 15px;
    border-top: 0px;
  }
  .H5iqHK2R {
    // padding-left: 1rem;
    width: 454px;
    height: 64px;
    padding-left: 30px;
    background: url("../assets/bgwenzi.png") no-repeat;
    // background-size: 100% 100%;
  }
  .xjyVk_out {
    position: relative;
    top: 15%;
    width: 80%;
    height: 228px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    // margin-bottom: 5%;
  }
  .xjyVk_into {
    position: absolute;
    top: -15%;
    left: 5%;
    padding: 0.5rem;
    text-align: center;
    height: 130%;
    width: 90%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .xjyVk_into_icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 0.3rem;
    width: 0.3rem;
    background: url(../assets/pic3.png);
    background-size: cover;
  }
  .dcxsejIF {
    height: 5.5rem;
  }

  .dcxsejIF .Iqv9guQQ .xjyVkKvB {
    margin-top: 0rem;
  }
  .o7jNbRcF {
    background-image: none;
    padding-top: 0.5rem;
  }
  .o7jNbRcF .o8D9H1v_ {
    background: url("../assets/pic2.png") no-repeat;
    background-position: 90%;
    // background-size: 40% 60%;
  }
  .a1JlOgfY {
    background: #373d41;
    color: #ffffff;
  }
  .a1JlOgfY .ifyJraSx {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 20px;
  }
  .a1JlOgfY span {
    text-align: center;
    line-height: 20px;
  }
  .waitingPass {
    font-weight: 700;
    font-size: 18px;
    line-height: 50px;
    color: #000;
  }
  .waitingPassIcon {
    width: 15px;
    margin-right: 5px;
    margin-bottom: 3px;
  }
  .home_news_detail {
    background-color: #ffffff;
    z-index: 9999 !important;
  }

  .home_block_title {
    color: #000;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    // width: 11.4rem;
    z-index: 1;

    .home_block_title_en {
      font-size: 40px;
      margin-top: 2px;
      color: #aaa;
      font-weight: 600;
    }
    .home_block_title_cn {
      width: 2.4rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      font-size: 26px;
      line-height: 90px;
      color: #2b76bc;
    }
    .home_block_title_line {
      width: 0.5rem;
      height: 0px;
      border: 1px solid #acacac;
    }
  }
  .home_media_tabs {
    width: 100vw;
    height: 1rem;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    .home_media_tabs_inner {
      width: 3rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .home_media_tab {
        width: 120px;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        height: 50px;
        line-height: 50px;
        cursor: pointer;
      }
      .home_media_tab_selected {
        background-color: #2b76bc;
        color: #fff;
        border-radius: 30px;
      }
    }
  }
  .home_media_content_inner_title {
    width: 11.4rem;
    height: 500px;
    margin: 10px auto;
  }

  .carousel_img {
    border-radius: 20px;
    width: 99.8%;
    height: 100%;
  }
  .home_more_function {
    width: 11.4rem;
    margin: 20px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-between;
    flex-wrap: wrap;
    .home_more_function_item {
      width: 42%;
      img {
        width: 100%;
        height: 400px;
      }
    }
    .home_more_function_item_text_title {
      margin: 15px auto;
      font-size: 18px;
      font-weight: 600;
      color: #000;
    }
  }
  .home_stay_tuned {
    position: relative;
    height: 1080px;
    width: 100%;
    background: url("~@/assets/home/home_run_back.png") no-repeat;
    background-position: 50% 0;
    background-size: 100% 100%;
    padding-top: 60px;
  }
  .home_big_functions {
    width: 80%;
    margin: 0 auto;
    margin-top: 230px;
    overflow: scroll;
    display: flex;
    flex-direction: row;
    overflow: auto;
    /* 隐藏滚动条 */
    ::-webkit-scrollbar {
      display: none;
    }

    .home_big_functions_item {
      max-width: 3rem;
      min-width: 2.5rem;
      height: 100%;
      border: 2px solid #4486c4;
      border-radius: 20px;
      color: #4486c4;
      font-size: 20px;
      font-weight: 600;
      padding: 15px;
      img {
        width: 33px;
        height: 30px;
      }
    }
    .home_big_functions_item:nth-child(odd) {
      margin-bottom: 300px;
    }
    .home_big_functions_item:nth-child(even) {
      margin-top: 300px;
    }
    .home_big_functions_item_text_title {
      font-size: 24px;
      margin-bottom: 10px;
    }
    .home_big_functions_item_text_address {
      margin-bottom: 10px;
    }
  }
  .home_media_news {
    display: flex;
    justify-content: center;
    align-items: center;
    // min-width: 100%;
    height: 50%;
    .home_media_news_time {
      min-width: 1rem;
      max-width: 1rem;
      height: 1rem;
      background-color: #2b76bc;
      color: #ffffff;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .home_media_news_content {
      height: 2rem;
      min-width: 8rem;
      max-width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .home_media_news_content_img {
        min-width: 2.5rem;
        height: 2rem;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .home_media_news_content_source {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        background-color: #f0f0f0;
        padding: 20px;
        .home_media_news_content_title {
          font-size: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 7.1rem;
        }
        .home_media_news_content_content {
          max-height: 1rem;
          overflow: scroll;
          text-overflow: ellipsis;
          white-space: normal;
        }
        .home_media_news_content_more {
          background: #2b76bc;
          border-radius: 50px;
          width: 1rem;
          text-align: center;
          padding: 5px;
          color: #fff;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
  .newsDialog {
    width: 90vw;
    height: 90vh;
    position: fixed;
    top: 5vh;
    left: 5vw;
    background-color: #ffffff;
    overflow: auto;
    padding: 40px;
    z-index: 9999;
  }
  .closeNewsDialog {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .outer {
    position: relative;
    margin: auto;
    width: 70px;
    cursor: pointer;
  }
  .inner {
    width: inherit;
    text-align: center;
  }
  label {
    font-size: 0.8em;
    line-height: 4em;
    text-transform: uppercase;
    // color: #fff;
    transition: all 0.3s ease-in;
    opacity: 0;
  }
  .inner:before,
  .inner:after {
    position: absolute;
    content: "";
    height: 1px;
    width: inherit;
    background: #ffc107;
    left: 0;
    transition: all 0.3s ease-in;
  }
  .inner:before {
    top: 50%;
    transform: rotate(45deg);
  }
  .inner:after {
    bottom: 50%;
    transform: rotate(-45deg);
  }
  .outer:hover label {
    opacity: 1;
  }
  .outer:hover .inner:before,
  .outer:hover .inner:after {
    transform: rotate(0);
  }
  .outer:hover .inner:before {
    top: 0;
  }
  .outer:hover .inner:after {
    bottom: 0;
  }
  .Messaging_News {
    margin: auto;
    padding: 0 35px;
    overflow: scroll;
    height: 100vh;
    // background-color: #333;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
    .Messaging_img {
      // width: calc(100% / 2);
      // float: left;
      width: 100%;
      .img {
        width: 100%;
      }
    }
    .Messaging_text {
      .Messaging_text_tr {
        // float: right;
        padding: 35px 0;
        text-align: center;
        .span {
          font-size: 30px;
        }
        .p {
          font-size: 16px;
        }
      }
      .Messaging_text_td {
        padding-top: 35px;
        font-size: 16px;
        .pp {
          font-size: 16px;
        }
      }
    }
    .Messaging_titel {
      .Messaging_titel_tr {
        // clear: both;
        padding: 10px 0;
        .ps {
          font-size: 20px;
          font-weight: 700;
          display: inline-block;
        }
        &:nth-child(-n + 2) {
          width: 0;
          height: 0;
          padding: 0;
        }
      }
    }
    .spansd {
      clear: both;
      text-align: center;
      .spans {
        display: inline-block;
        margin: auto;
        padding: 30px;
        font-size: 20px;
        // background-color: rgb(197, 190, 190);
        &:first-child {
          padding: 0px;
        }
      }
    }
  }
  .content {
    width: 100%;
    padding: 12px 0;
    background: #fff;
    font-size: 16px;
    color: #333;
    line-height: 30px;
    h3 {
      margin: 5px 0 0;
      padding-bottom: 5px;
    }
    p {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
    img {
      width: 100%;
    }
  }
  .home_footer {
    background: #373d41;
  }
  .ciicWechat {
    width: 100px;
    height: 100px;
    background: url("../assets//home/home_wechat_code.png");
    background-size: 100% 100%;
    margin: 20px auto;
  }
  .loginBox {
    background: url("../assets/home/login_back.png") no-repeat;
    backface-visibility: hidden;
    background-size: 100% 100%;
    background-position: 50% 0;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  .login_flex {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .home_login_media_content {
    width: 60vw;
    height: 60vw;
    max-width: 700px;
    max-height: 700px;
    margin: 10px auto;
    margin-right: 100px;
  }
  .login_box {
    border: 1px solid rgba(187, 187, 187, 1);
    padding: 20px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
  }
  .home_poster {
    z-index: 9999 !important;
    min-width: 340px;
    position: absolute;
    left: 380px;
    border: 1px solid rgba(187, 187, 187, 1);
    padding: 20px 20px 20px 30px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
  }
  .ant-menu-horizontal {
    line-height: 5px;
  }
  @media screen and (max-width: 1920px) {
    .KJWhP3Y0 .j6cvrvvX {
      background-size: auto 100%;
    }
    .home_stay_tuned {
      background-size: auto 100%;
    }
  }
  @media screen and (max-width: 800px) {
    .dcxsejIF .Iqv9guQQ {
      padding: 0.6rem 0 0.99rem;
      width: 6.5rem;
    }
    .o3QY36gM {
      width: 85%;
    }
    .o3QY36gMbg {
      background: url("../assets/pic1.png") no-repeat;
      background-position: 50% 100%;
      background-size: auto;
    }
    .dcxsejIF .Iqv9guQQ .P_mA8TF6 {
      font-family: PingFangSC-Semibold;
      font-size: 0.3rem;
      line-height: 0.6rem;
    }
    .P_mA8TF61 {
      text-align: center;
      font-size: 0.3rem;
      color: #fff;
    }
    .waitingPass {
      font-weight: 700;
      font-size: 14px;
      text-align: center;
    }
    .KJWhP3Y0 .home_title {
      flex-direction: column;
    }
    .home_login_media_content {
      display: none;
      width: 320px;
      height: 320px;
      margin: 10px auto;
    }
    .home_media_news {
      justify-content: flex-start;
    }
    .home_more_function {
      width: 4rem;
      flex-direction: column;
      .home_more_function_item {
        width: 100%;
      }
    }
    .home_media_content_inner_title {
      width: 100%;
      height: 100%;
    }
    .home_media_news {
      flex-direction: column;
    }
    .home_weChat {
      width: 100%;
      justify-content: center;
    }
    .login_flex {
      justify-content: center;
    }
  }
  @media screen and (max-width: 500px) {
    .o7jNbRcF .o8D9H1v_ {
      background-position: 50% 90%;
      background-size: 80%;
    }
    .P_mA8TF61 {
      text-align: center;
    }
    .o3QY36gMbg {
      // width: 100%;
      background-size: 130%;
    }
    .o7jNbRcF .o8D9H1v_ {
      background-position: 50% 90%;
      background-size: 80%;
    }
    .banner_person {
      background: url("../assets/banner1920fen2.png") no-repeat;
      background-size: 100%;
      z-index: 1;
      position: relative;
      left: 100px;
      width: 200px;
      height: 200px;
      margin-top: 20px;
    }
    .H5iqHK2R {
      // padding-left: 1rem;
      width: 100vw;
      padding-left: 30px;
      background-size: 100% 100%;
    }
    .xjyVk_into_icon {
      height: 0.2rem;
      width: 0.2rem;
    }
    .nPTJmMSE {
      position: relative;
      left: -70px;
    }
    .xjyVk_out {
      width: 100%;
      .xjyVk_into {
        padding: 10px;
      }
    }
    .login_flex {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      overflow: auto;
    }
    .home_poster {
      left: 0;
      width: 100%;
    }
    .home_weChat {
      font-size: 18px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .KJWhP3Y0 .HjrMd8LI strong {
      font-size: 40px;
    }
    .KJWhP3Y0 .HjrMd8LI .KTFDGqJT {
      font-size: 34px;
      height: 62px;
    }
  }
  .fade-transition {
    transition: opacity 0.5s;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .Qyj8AoQa {
    cursor: pointer;
    background-image: linear-gradient(-19deg, #62a1f3 3%, #aaafff);
  }
</style>
