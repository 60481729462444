<template>
  <div style="width: 100%">
    <div class="PLC_Pages" v-show="cItem.loading == false && cItem.answerList.length > 1">
      <span
        >已为您生成 <span style="font-weight: bolder; margin: 0">{{ cItem.answerList.length }}</span> 条回答</span
      >
      <Page v-model="currentPage" :total="cItem.answerList.length" simple :page-size="1" @on-change="changePage" :show-sizer="false" />
    </div>

    <div class="chatList-answer-box">
      <Col class="chatList-answer chatList-answer1" v-show="cItem.answerList.length > 2"></Col>
      <Col class="chatList-answer chatList-answer2" v-show="cItem.answerList.length > 1"></Col>
      <Col class="chatList-answer chatList-answer3" v-show="cItem.loading == false">
        <MarkdownPreview :initialValue="answer" :copyCode="true" copyBtnText="复制" theme="dark" v-if="cItem.answerList.length > 0" />
        <MarkdownPreview :initialValue="'很抱歉，暂时无法回答这个问题。'" :copyCode="true" copyBtnText="复制" theme="dark" v-if="cItem.answerList.length == 0" />
        <div class="chatFileSource" v-if="keywordList.length > 0 && cItem.answerList.length == keywordList.length">
          <p style="font-size: 18px; font-weight: bolder">信息来源：</p>
          <div :class="'chatFileList chatFileList'">
            <Button @click="previewFile2(keyword, path)">
              <div class="previewFileLink" :title="keyword">
                {{ keyword }}
              </div>
            </Button>
          </div>
        </div>
        <div class="divider" v-show="cItem.loading == false && cItem.status !== -1"></div>
        <div class="footerButtonGroup" v-show="cItem.loading == false && cItem.status !== -1">
          <div class="voiceGroup">
            <div class="speak-footer-button" @click="playTTS(answer, cIndex, 'speak')" v-show="audioNum !== cIndex" title="语音播放">
              <img src="@/assets/playSound.png" alt="" />
            </div>
            <div class="speak-footer-button" @click="stopTTS(cItem, cIndex, 'cancel')" v-show="audioNum === cIndex" title="取消播放">
              <img src="@/assets/stopPlay.png" alt="" />
              <AudioLine />
            </div>
          </div>
          <div class="footer-button-group">
            <div v-show="cIndex == currentListHistory.length - 1" @click="reAskZG" class="reAskZG_text">
              <span> <img src="@/assets/chat/reload1.png" alt="" style="width: 14px; margin-right: 5px" /> 重新生成</span>
            </div>
            <div @click="clickFooterButton(cItem, cIndex, 'copy', answer)" class="anwser-footer-button" title="复制内容">
              <img src="@/assets/copy.png" alt="" />
            </div>
          </div>
        </div>
      </Col>
      <Col class="chatList-answer chatList-answer3" v-show="cItem.loading == true">
        <div class="loadingBox">
          <div>{{ loadingText }}</div>
          <div class="loading-blocks">
            <div class="loading-block loading-orange"></div>
            <div class="loading-block loading-blue"></div>
          </div>
        </div>
      </Col>
      <Col class="chatList-footer" v-show="cItem.loading == false">
        <div class="footer-time">
          <span>{{ cItem.time }}</span>
        </div>
        <div @click="agreeOrDisagree(cItem, cIndex, 'agree')">
          <img v-if="cItem.agree" class="agreeImg" src="@/assets/like_blue.png" alt="" />
          <img v-else class="agreeImg" src="@/assets/like_gray.png" alt="" />
        </div>
        <div @click="agreeOrDisagree(cItem, cIndex, 'disagree')">
          <img v-if="cItem.disagree" class="agreeImg" src="@/assets/unlike_blue.png" alt="" />
          <img v-else class="agreeImg" src="@/assets/unlike_gray.png" alt="" />
        </div>
      </Col>
    </div>

    <Modal v-model="previewPdfModal" fullscreen title="文档预览" :footer-hide="true">
      <bookLoading v-show="pdfLoading == true" style="width: 100%; height: 100%" />
      <!-- <MarkdownPreview :initialValue="textFormatValue" theme="dark" v-show="pdfLoading == false" /> -->
      <div id="formatTextBox" v-show="pdfLoading == false">
        <div id="formatTextCatalog">
          <div style="margin: 0px 0 0 180px; font-size: 18px; font-weight: bold"></div>
          <div class="CatalogList">
            <div @click="handleClick(item.className)" :name="'tab' + index" :class="activeName == item.className ? `activeCatalog ${item.lev}` : item.lev" v-for="(item, index) in navList" :key="index" :title="item.name">{{ item.name }}</div>
          </div>
        </div>
        <div v-html="textFormatValue" id="formatTextContent"></div>
      </div>
      <!-- <iframe width="100%" height="100%" frameborder="0" id="knowledgeIframe1" :srcdoc="srcdoc" v-show="srcdoc.length > 0"></iframe> -->
      <!-- <embed src="http://www.ciigpt.com/LK220_system_controler.pdf" style="width: 100%;height: 100%;" /> -->
    </Modal>
    <div id="contentMain" v-show="false">{{ keyword }}</div>
    <Modal v-model="previewKIMIPdfModal" fullscreen title="文档预览" :footer-hide="true">
      <iframe width="100%" height="100%" frameborder="0" id="knowledgeIframe1" :src="srcdoc" v-show="srcdoc.length > 0"></iframe>
    </Modal>
    <!-- <Modal v-model="previewImageModal.visible"  :title="previewImageModal.title" :footer-hide="true">
      <img :src="previewImageModal.link" alt="" style=" max-width: 70%;  margin:0 auto;" />
    </Modal> -->
  </div>
</template>

<script>
  import { Button, Col, Input, Row, Page } from "view-design";
  import bookLoading from "./bookLoading.vue";
  import { MarkdownPreview, MarkdownPro } from "vue-meditor";
  import AudioLine from "@/components/audioLine/index.vue";
  import "./AnswerBox.less";
  export default {
    components: {
      Row,
      Col,
      Input,
      Button,
      MarkdownPreview,
      MarkdownPro,
      AudioLine,
      Page,
      bookLoading,
    },
    props: ["cItem", "audioNum", "cIndex", "currentListHistory", "currentId"],
    data() {
      return {
        loadingText: "",
        answerList: [],
        pathList: [],
        keywordList: [],
        answer: "",
        path: "",
        keyword: "",
        currentPage: 1,

        previewPdfModal: false,
        previewKIMIPdfModal: false,
        previewImageModal:{
          // 是否展示
          value: false,
          title: '图片预览',
          link:"",
          width: '100%',
          height: '100%',
          visible: false,
          // 是否显示遮罩层
          mask: true,
          // 是否显示关闭按钮
          closable: true,
          // 点击遮罩层是否允许关闭
          maskClosable: true,
        },
        srcdoc: "",
        numberOfPages: 0,
        pageNumber: 0,
        pdfLoading: false,
        fileName: "",

        textFormatValue: ``,
        // 目录树
        activeName: "tab0",
        tabPosition: "right",
        scroll: "",
        navList: [],
        textFormatCatalogValue: "",
      };
    },
    created() {
      this.answerList = this.cItem.answerList; // 在 created 钩子中将 props 赋值给 data
      this.pathList = this.cItem.pathList; // 在 created 钩子中将 props 赋值给 data
      this.keywordList = this.cItem.keywordList; // 在 created 钩子中将 props 赋值给 data
      this.currentPage = 1;
    },

    mounted() {
      this.answer = this.answerList[0];
      this.path = this.pathList[0];
      this.keyword = this.keywordList[0];
      this.currentPage = 1;
      // scroll代表滚动条距离页面顶部距离
      window.addEventListener("scroll", this.dataScroll);
    },
    watch: {
      "cItem.answerList"(newValue, oldValue) {
        this.answerList = newValue;
        if (newValue.length > 0) {
          this.answer = newValue[0];
          this.cItem.answer = newValue[0];
          this.currentPage = 1;
        } else {
          this.answer = "很抱歉，暂时无法回答这个问题。";
        }
      },
      "cItem.status"(newValue, oldValue) {
        this.cItem.status = newValue;
      },
      "cItem.pathList"(newValue, oldValue) {
        this.pathList = newValue;
        if (newValue.length > 0) {
          this.path = newValue[0];
          this.cItem.path = newValue[0];
          this.currentPage = 1;
        } else {
          this.path = "";
        }
      },
      "cItem.keywordList"(newValue, oldValue) {
        this.keywordList = newValue;
        if (newValue.length > 0) {
          this.keyword = newValue[0];
          this.cItem.keyword = newValue[0];
          this.currentPage = 1;
        } else {
          this.keyword = "";
        }
      },
      cIndex(newValue, oldValue) {},
      audioNum(newValue, oldValue) {},
      currentId(newV, oldV) {
        this.currentPage = 1;
      },
      previewPdfModal: {
        handler(nval, oval) {
          if (nval == false) {
            this.srcdoc = "";
            this.textFormatValue = "";
            this.activeName = "";
          } else {
            // console.log("previewPdfModal",nval)
            // setTimeout(()=>{
            //   initFindWin(false, "contentMain");
            // },500)
          }
        },
      },
    },

    methods: {
      reAskZG() {
        this.$emit("reAskZG");
      },
      clickFooterButton(data, dItem, key, answer) {
        this.$emit("clickFooterButton", data, dItem, key, answer);
      },
      playTTS(data, index, type) {
        this.$emit("playTTS", data, index, type);
      },
      stopTTS(data, index, type) {
        this.$emit("stopTTS", data, index, type);
      },
      agreeOrDisagree(data, index, key) {
        this.$emit("agreeOrDisagree", data, index, key);
      },
      changePage(num) {
        this.currentPage = num;
        this.stopTTS(this.cItem, this.cIndex, "cancel");
        this.answer = this.cItem.answerList[num - 1];
        this.path = this.cItem.pathList[num - 1];
        this.keyword = this.cItem.keywordList[num - 1];
      },
      renderLoadingText() {
        let _this = this;
        const array = ["智工正在解析您的问题", "正在翻阅查找知识库", "正在整合全部相关知识", "正在生成回答中"];
        let index = 0;
        function loopArray() {
          _this.loadingText = array[index];
          index++;
          if (index < array.length) {
            setTimeout(loopArray, 800); // 5秒后再执行下一次循环
          } else {
            // _this.loadingText = ""
          }
        }
        loopArray();
      },
      // 预览文件
      previewFile(data, path) {
        let _this = this;
        path = path.split(" ")[0];
        this.previewPdfModal = true;
        this.fileName = path;
        this.navList = [];
        async function fetchData() {
          try {
            const response = await fetch(`https://qa.ciigpt.com:5078/api/example/KnowledgeBase/previewTxt/${path}.pdf`, {
              method: "get",
            });
            const resData = await response.json();

            if (response.status === 200) {
              // return;
              if (resData.code === 200) {
                // 处理文本
                let result = _this.formatTextContent(resData.data.content, data);
                _this.textFormatValue = result;
                const element = document.getElementById("formatTextContent");
                if (element) {
                  element.innerHTML = result;
                  _this.$nextTick(() => {
                    // 在 DOM 更新后执行需要的操作
                    _this.selectAllTitle();
                    // 这里可以对 contentDiv 进行操作
                    // _this.selectImages();
                  });
                }

                // 跳转高亮
                setTimeout(() => {
                  _this.scrollToKeyword();
                }, 1000);
                // const div = document.querySelector("div");

                _this.pdfLoading = false;
              } else {
                _this.srcdoc = "";
                _this.pdfLoading = false;
                _this.$Message.warning(resData.msg);
              }
            } else {
              _this.pdfLoading = false;
              _this.$Message.warning(resData.msg);
            }
          } catch (error) {
            console.log(error, " 文档预览接口err ");
            _this.pdfLoading = false;
          }
        }
        this.pdfLoading = true;
        fetchData();
      },
      previewFile2(keyword, data) {
        // console.log(keyword,"===>>>")
        this.srcdoc = "http://www.ciigpt.com/" + keyword;
        this.previewKIMIPdfModal = true;
      },
      // 监听 pdf 滚动
      listenPDFScroll() {
        let _this = this;

        if (this.pdfLoading == true) {
          return;
        }
        // let reviewPDF = this.$store.state.chat.reviewPDF;

        var iframe = document.getElementById("knowledgeIframe1");
        if (iframe == null) {
          return;
        } else {
          // if(iframe.)
          iframe.onload = function () {
            iframe.contentDocument.documentElement.scrollTop = 10;
            iframe.contentDocument.addEventListener("scroll", () => {
              var scrollTop = iframe.contentWindow.pageYOffset || iframe.contentDocument.documentElement.scrollTop;
              var scrollHeight = iframe.contentDocument.documentElement.scrollHeight;
              var clientHeight = iframe.contentDocument.documentElement.clientHeight;
              // 滚动到顶部判断条件
              if (scrollTop === 0) {
                if (_this.pageNumber <= 1) {
                  _this.Message.info("已经是第一页了");
                  return;
                }
                if (_this.pdfLoading == true) {
                  return;
                }
                let params = {
                  fileName: _this.fileName + ".pdf",
                  keyWord: "",
                  pageNumber: parseInt(_this.pageNumber) - 1,
                };
                _this.srcdoc = "";
                _this.previewPDF(params);
              }
              // 滚动到底部判断条件（考虑到可能有小范围的误差，这里使用 1px 的容差）
              if (scrollTop + clientHeight >= scrollHeight - 1) {
                if (_this.pageNumber >= _this.numberOfPages) {
                  _this.$Message.info("已经是最后一页了");
                  return;
                }
                if (_this.pdfLoading == true) {
                  return;
                }
                let params = {
                  fileName: _this.fileName + ".pdf",
                  keyWord: "",
                  pageNumber: parseInt(_this.pageNumber) + 1,
                };
                _this.srcdoc = "";
                _this.previewPDF(params);
              }
            });
          };
        }
      },
      previewPDF(params2) {
        let _this = this;
        if (this.pdfLoading == true) {
          return;
        }
        async function fetchData() {
          try {
            // 113.207.49.230
            const response = await fetch(`https://qa.ciigpt.com:5078/api/example/KnowledgeBase/Preview/html3`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(params2),
            });
            const resData = await response.json();

            if (response.status === 200) {
              if (resData.code === 200) {
                _this.pageNumber = resData.data.pageNumber;
                _this.numberOfPages = resData.data.numberOfPages;
                _this.srcdoc = resData.data.html;
                _this.pdfLoading = false;
              } else {
                _this.srcdoc = "";
                _this.pdfLoading = false;
                _this.$Message.warning(resData.msg);
              }
            } else {
              _this.pdfLoading = false;
              _this.$Message.warning(resData.msg);
            }
          } catch (error) {
            console.log(error, " 文档预览接口err ");
            _this.pdfLoading = false;
          }
        }
        this.pdfLoading = true;
        fetchData();
      },
      // 格式化 text 文本内容
      formatTextContent(data, keywordFormat) {
        function escapeRegExp(keywordFormat) {
          return keywordFormat.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
        }
        // 将关键字中的特殊字符替换为可转义的形式
        const escapedKeyword = escapeRegExp(keywordFormat);
        // 将字符串根据换行符号分割成数组，取出里面最长的元素
        const lines = escapedKeyword.split("\n");
        // 取出 lines 数组里面length 最长的元素
        const longestLine = lines.reduce((a, b) => (a.length > b.length ? a : b));
        // console.log(longestLine, "最长的元素")

        let dataFormat = data;
        const replacement = `<span class="highlightText" style="background:yellow ">${keywordFormat}</span>`;
        dataFormat = dataFormat.replace(new RegExp(longestLine, "ig"), replacement);

        if (dataFormat.indexOf("====<table-json>====") > 0) {
          //  删掉 ====<table>==== 之间的数据
          dataFormat = dataFormat.replace(/====<table>====([\s\S]*?)====<\/table>====/g, "");
        } else {
          dataFormat = this.formatTextTableMd(dataFormat);
        }
        // 处理表格样式
        dataFormat = this.formatTextTable(dataFormat);
        // 关键次处理

        // 1.处理标题（多级到一级）
        let h1_index = 0;
        let h2_index = 0;
        let h3_index = 0;
        let h4_index = 0;
        let h5_index = 0;
        let h6_index = 0;

        dataFormat = dataFormat.replace(/====######/g, (match, index) => {
          const className = `h6-${h6_index}`;
          h6_index++;
          return ` <h6 class="${className}">`;
        });
        dataFormat = dataFormat.replace(/====#####/g, (match, index) => {
          const className = `h5-${h5_index}`;
          h5_index++;
          return ` <h5 class="${className}">`;
        });
        dataFormat = dataFormat.replace(/====####/g, (match, index) => {
          const className = `h4-${h4_index}`;
          h4_index++;
          return ` <h4 class="${className}">`;
        });
        dataFormat = dataFormat.replace(/====###/g, (match, index) => {
          const className = `h3-${h3_index}`;
          h3_index++;
          return ` <h3 class="${className}">`;
        });
        dataFormat = dataFormat.replace(/====##/g, (match, index) => {
          const className = `h2-${h2_index}`;
          h2_index++;
          return ` <h2 class="${className}">`;
        });
        dataFormat = dataFormat.replace(/====#/g, (match, index) => {
          const className = `h1-${h1_index}`;
          h1_index++;
          return ` <h1 class="${className}">`;
        });

        dataFormat = dataFormat.replace(/######====/g, "</h6>");
        dataFormat = dataFormat.replace(/#####====/g, "</h5>");
        dataFormat = dataFormat.replace(/####====/g, "</h4>");
        dataFormat = dataFormat.replace(/###====/g, "</h3>");
        dataFormat = dataFormat.replace(/##====/g, "</h2>");
        dataFormat = dataFormat.replace(/#====/g, "</h1>");

        // 2.处理 table  ====<table>====   ====</table>====
        // dataFormat = dataFormat.replace(/====<table>====|====<\/table>====/g, '');

        // 3. 处理图片格式
        dataFormat = dataFormat.replace(/<img src="imgs/g, `<div align=center class="formatTextImage"><img style="max-width:70%;display: block; margin: 20px auto;" src="https://qa.ciigpt.com:5078/resources/imgs`);
        dataFormat = dataFormat.replace(/title="">/g, `title=""></div>`);

        // 处理 title 样式
        dataFormat = dataFormat.replace(/====<title>/g, `<div style="font-size: 26px; text-align:center; font-weight:bold;  ">`);
        dataFormat = dataFormat.replace(/<\/title>====/g, `</div>`);

        // 处理 contents 样式
        dataFormat = dataFormat.replace(/====<contents>/g, `<div style="font-size: 26px; text-align:center; ">`);
        dataFormat = dataFormat.replace(/<\/contents>====/g, `</div>`);

        //4. 处理换行效果
        // dataFormat = dataFormat.replace(/\r\n/g, `<br><div class="formatNull" style="height:15px"></div>  `);
        // dataFormat = dataFormat.replace(/\r/g, `<br><div class="formatNull" style="height:15px"></div> `);
        dataFormat = dataFormat.replace(/\n/g, `\r`);
        return dataFormat;
      },
      // 处理 table json 格式
      formatTextTable(jsonString) {
        const modifiedString = jsonString.replace(/(====<table-json>====([\s\S]*?)====<\/table-json>====)/g, function (match, content) {
          const stringToExtract = match;
          const regex = /====<table-json>====([\s\S]*?)====<\/table-json>====/;
          const extractedData = stringToExtract.match(regex);

          let matchString1 = extractedData[1];
          matchString1 = matchString1.replace(/\n/g, "");
          matchString1 = matchString1.replace(/\s/g, "");
          let tableHtmlString = ``;
          // 遍历 json
          function traverseObject(obj) {
            for (let key in obj) {
              if (typeof obj[key] === "object" && obj[key] !== null) {
                if (key == "display") {
                  switch (obj["type"]) {
                    case "TABLE":
                      tableHtmlString += `<table class="formatTable" cellspacing='5' cellpadding='5'  class='el-table__body' style="width: 80%; min-height:100px; margin:20px auto;border: 3px solid ;" table-layout="fixed" rules='all'> <colgroup>`;
                      // 循环加 colgroup
                      for (let i = 0; i <= obj["children"].length; i++) {
                        tableHtmlString += `<col name="el-table_1_column_2" width="100">`;
                      }
                      tableHtmlString += " </colgroup><tbody>";
                      break;

                    case "children":
                      // 循环加 colgroup
                      for (let i = 0; i <= obj["children"].length; i++) {
                        tableHtmlString += `<col name="el-table_1_column_2" width="100">`;
                      }
                      break;
                    default:
                      break;
                  }
                } else if (key === "children") {
                  obj[key].forEach((child) => {
                    tableHtmlString += `<tr style='min-height:30px'>`; // 添加<tr>标签
                    child.forEach((children) => {
                      tableHtmlString += `<td class="formatTableTd" rowspan=${children["rowSpan"]} colspan=${children["colSpan"]}><div class="cell" style="padding:10px">${children.text}</div></td>`; // 添加<td>标签
                    });
                    tableHtmlString += "</tr>"; // 添加</tr>标签
                  });
                }
                traverseObject(obj[key]);
              } else {
                if (key == "type") {
                  switch (obj[key]) {
                    case "TABLE":
                      break;
                    // case "text":
                    //   tableHtmlString += "</>";
                    default:
                      break;
                  }
                }
              }
              if (key == "children") {
                tableHtmlString += `<tbody">`;
              }
            }
          }

          traverseObject(JSON.parse(matchString1), tableHtmlString);
          tableHtmlString += "</table>";
          let ttt = tableHtmlString;

          return ttt;
        });
        return modifiedString;
      },
      // 处理 table markdown 格式
      formatTextTableMd(jsonString) {
        const modifiedString = jsonString.replace(/(====<table>====([\s\S]*?)====<\/table>====)/g, function (match, content) {
          const stringToExtract = match;

          const regex = /====<table>====([\s\S]*?)====<\/table>====/;
          const extractedData = stringToExtract.match(regex);

          let matchString1 = extractedData[1];
          // matchString1 = matchString1.replace(/\n/g, "");
          // matchString1 = matchString1.replace(/\s/g, "");
          let tableHtmlString = ``;
          // 遍历 json
          function markdownToHtmlTable(markdownText) {
            // 将 md 表格数据转换成 HTML 格式
            var htmlText = markdownText.replace(/^\|(.*?)\|$/gm, function (match, p1) {
              var cells = p1
                .split("|")
                .map(function (cell) {
                  return cell.trim();
                })
                .join(`</td><td class="formatTableTd">`);
              return `<tr style='min-height:30px'><td class="formatTableTd">` + cells + "</td></tr>";
            });
            htmlText = `<table class="formatTable" cellspacing='5' cellpadding='5'  class='el-table__body' style="width: 80%; min-height:100px; margin:20px auto; border: 3px solid ;" table-layout="fixed" rules='all'>` + htmlText + "</table>";
            tableHtmlString += htmlText;
          }

          markdownToHtmlTable(matchString1, tableHtmlString);
          // tableHtmlString += "</table>";
          let ttt = tableHtmlString;

          return ttt;
        });
        return modifiedString;
      },

      // 滚动到高亮
      scrollToKeyword() {
        const elements = document.getElementsByClassName("highlightText");
        if (elements.length > 0) {
          elements[elements.length - 1].scrollIntoView({ behavior: "instant", block: "center" });
        }
      },
      handleClick(name) {
        this.jump(name);
      },
      dataScroll: function () {
        this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
      },
      jump(name) {
        this.activeName = name;
        const elements = document.getElementsByClassName(name);
        if (elements.length > 0) {
          elements[elements.length - 1].scrollIntoView({ behavior: "instant" });
        }
      },
      // 摘目录树
      selectAllTitle() {
        this.navList = [];
        let title = document.querySelector("#formatTextContent").querySelectorAll("h1,h2,h3");
        this.navList = Array.from(title);
        this.navList.forEach((item) => {
          item.name = item.innerHTML;
        });
        this.navList.forEach((el) => {
          let index = el.localName.indexOf("h");
          el.lev = "lev" + el.localName.substring(index + 1, el.localName.length);
        });
      },
      // 图片点击事件
      selectImages() {
        let _this = this;
        let divElements = document.querySelectorAll(".formatTextImage");
        divElements.forEach(function (divElement) {
          divElement.addEventListener("click", function () {
            let imgElement = divElement.querySelector("img");
            let imgSrc = imgElement.getAttribute("src");
            console.log(imgSrc);
            _this.previewImageModal = {
              ..._this.previewImageModal,
              link:imgSrc,
              visible:true,
            }
          });
        });
      },
     
    },
  };
</script>

<style lang="less" scoped>
  .PLC_Pages {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    span {
      margin-right: 20px;
      font-size: 16px;
      font-weight: 400;
    }
  }
  #formatTextBox {
    width: 100%;
    // max-width: 1000px;
    display: flex;
    height: 100%;
    overflow-y: scroll;
  }

  #formatTextContent::-webkit-scrollbar {
    width: 10px !important;
  }

  #formatTextContent::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  #formatTextContent::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  #formatTextContent {
    // width: 70%;
    // max-width: 1000px;
    white-space: pre-wrap;
    min-width: 1000px;
    font-size: 16px;
    font-weight: 500;
    color: #454d64;
    // margin: 30px auto;
    padding: 50px;
    // box-shadow: 0px 16px 20px 0px rgba(166, 184, 222, 0.1);
    // border: 2px solid #eee;
    // line-height: 1.6;
    // letter-spacing: 0.1em;
    overflow: auto;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-variant: tabular-nums;
    font-feature-settings: "tnum", "tnum";
    .formatTextImage {
      max-width: 80%;
    }
  }
  .formatNull {
    height: 20px;
    // margin: 30px auto;
  }
  .formatTable {
    margin-top: 20px;
  }
  .formatTableTr {
    min-height: 40px;
  }
  .highlightText {
    background-color: yellow;
  }
  .ivu-modal-body {
    scrollbar-width: 10px;
    padding: 0;
  }
  .ivu-modal-body::-webkit-scrollbar {
    width: 10px !important;
  }
  .ivu-modal-body::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  .ivu-modal-body::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  #formatTextCatalog {
    min-width: 300px;
    max-width: 300px;
    border-right: 1px solid #eee;

    .CatalogList {
      height: 100%;
      padding: 30px 0;
      overflow-y: auto;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;

      padding: 0px 0px 0px 60px;
      font-size: 14px;
      font-weight: 400;

      div {
        min-height: 25px;
        cursor: pointer;
        margin-top: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      div:hover {
        color: #4569d4;
      }
      .activeCatalog {
        color: #4569d4;
        background-color: #fcfcfc;
        border-right: 5px solid #4569d4;
      }
      .lev2 {
        margin: 0px 0px 0px 20px;
      }
      .lev1 {
        font-size: 16px;
        font-weight: 500;
        line-height: 35px;
        min-height: 35px;
      }
    }
  }
</style>
