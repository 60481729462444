<template>
  <div class="chat_history_containner">
    <div class="chat_history_list_header">
      <div class="chat_history_list_footer">
        <Button class="newChatBtn" @click="openInfoModal($event)"><img src="@/assets/chat/my_info.png" style="width: 20px" alt="" /> 我的</Button>
        <Button class="newChatBtn" @click="openNewChat"><img src="@/assets/chat/new_chat.png" style="width: 20px" alt="" /> 新的对话</Button>
      </div>
      <Divider style="border-bottom: 1px solid #dddddd" />
      <div class="chat_history_box">
        <div class="chat_history_list_title">
          <span>历史对话</span>
        </div>
        <div style="border-bottom: 1px solid #dddddd"></div>
        <div class="chat_history_list" ref="scrollContainer" @scroll="handleScroll">
          <div :class="current.index == cIndex ? 'chat_history_item chat_history_item_selected' : 'chat_history_item'" @click="showHistory(cItem, cIndex)" v-for="(cItem, cIndex) in historyList" :key="cIndex">
            <div class="chat_history_item_title">
              <img src="@/assets/talk.png" alt="" />
              {{ getParamValue(cItem, "title") }}
            </div>

            <!-- <img src="../../assets/export_history.png" title="导出记录" class="exportHistory" @click.stop="convertArrayToMd(cItem, cIndex)" /> -->

            <img src="../../assets/delete.png" title="删除记录" class="deleteHistory" @click.stop="openDeleteModal(cItem, cIndex, $event)" />
          </div>
        </div>
      </div>
    </div>

    <div class="chat_history_logo">
      <img src="@/assets/chat/zhigong_logo_white.png" alt="" />
    </div>
    <Modal v-model="deleteModal" :styles="{ top: deleteModalPositionY + 'px', left: deleteModalPositionX + 'px' }" :mask="false" width="240" :closable="false">
      <p>确认删除本条对话吗？</p>
      <template #footer>
        <Button @click="deleteModal = false">取消</Button>
        <Button type="error" :loading="deleteLoading" @click="deleteHistory">确定删除</Button>
      </template>
    </Modal>
    <Modal v-model="infoModal" :styles="{ top: infoModalPositionY + 'px', left: infoModalPositionX + 'px' }" :mask="false" width="240" :closable="false">
      <div class="flexDiv">
        <div class="flexDiv">
          <img src="../../assets/logoFav.png" alt="" class="favicon" />
          <span>{{ userMobile }}</span>
        </div>
        <Button type="text" @click="cancelAccountModal = !cancelAccountModal">注销</Button>
      </div>
      <template #footer>
        <div class="flexDiv" style="justify-content: center">
          <Button @click="infoModal = false">取消</Button>
          <Button type="error" @click="existAccount" ghost>退出登录</Button>
        </div>
      </template>
    </Modal>
    <Modal v-model="cancelAccountModal" :mask="false" width="440" class-name="vertical-center-modal" :footer-hide="true" :closable="false">
      <div class="flexDivCenter" style="height: 160px">
        <h1>警告</h1>
        <div>
          <p>确认注销？</p>
          <p>注销后，账号和对话记录将永久清除。</p>
        </div>
        <div class="flexDiv" style="justify-content: space-around; width: 200px">
          <Button @click="cancelAccountModal = false">取消</Button>
          <Button type="error"  @click="cancelAccount">确定注销</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
  import { Card, Button, Divider } from "view-design";
  import { getGptHistory2, deleteHistory, checkOutToken, deleteAccount } from "@/api/api_1";
  import { setStore, getStore } from "@/libs/storage";
  import Cookies from "js-cookie";
  export default {
    components: {
      Card,
      Button,
    },
    data() {
      return {
        historyList: [],
        current: {
          index: null,
        },
        hasMore: false, // 是否还有更多数据
        page: 1, // 当前页码
        pageSize: 20, // 每页显示数量

        deleteId: "",
        deleteLoading: false,
        deleteModal: false,
        deleteModalPositionX: 0,
        deleteModalPositionY: 0,

        userMobile: "",
        infoModal: false,
        infoModalPositionX: 0,
        infoModalPositionY: 0,

        cancelAccountModal: false,
      };
    },
    methods: {
      initHistory() {
        this.historyList = [];
        // this.current.index = null;
        this.hasMore = false;
        (this.page = 1), (this.pageSize = 20);
        this.getGptHistory();
      },
      openNewChat() {
        this.current = {
          index: 0,
        };
        this.deleteModal = false;
        this.$emit("setCurrentListHistory", { name: "currentId", value: "" });
        this.$emit("setCurrentListHistory", { name: "historyList", value: [] });
        if (this.historyList.length == 0 || this.historyList[0].historys.length == 0) {
          // this.$Message.info("当前已是新的对话");
        } else {
          this.historyList.unshift({
            id: "newchat",
            historys: [],
          });
        }
        // 触发父组件的清空方法，回到defaultList；
      },
      showHistory(data, index) {
        this.current.index = index;
        // 处理好数据后，添加到父组件的 currentListHistory
        let array = [];
        data.historys.forEach((element) => {
          array.push({
            question: element.question,
            answer: element.answer,
            status: 2,
            agree: false,
            disagree: false,
            // speak: false,
            // pause: false,
            loading: false,
            time: this.protoTime(element.time),
          });
        });

        this.$emit("setCurrentListHistory", {
          name: "historyList",
          value: array,
        });
        this.$emit("setCurrentListHistory", {
          name: "currentId",
          value: data.id,
        });
      },

      // 打开删除聊天记录弹窗
      openDeleteModal(data, index, e) {
        // console.log(data, index, e, e.offsetY, window.innerWidth, "需要获取点击的位置信息");
        this.showHistory(data, index);
        this.deleteModalPositionX = -window.innerWidth / 2 + 400;
        this.deleteModalPositionY = e.y - 50;
        setTimeout(() => {
          this.deleteId = data.id;
          this.deleteModal = true;
          this.infoModal = false;
        }, 100);
      },
      // 删除聊天纪录
      deleteHistory() {
        if (this.deleteId == "newchat") {
          this.$Message.info("当前对话为空，无法删除");
          return;
        }
        this.deleteLoading = true;

        let params = { id: this.deleteId };
        deleteHistory(params).then((res) => {
          if (res.success) {
            setTimeout(() => {
              this.deleteLoading = false;
              this.deleteModal = false;
              this.$Message.info(res.message);
              this.openNewChat();
              this.initHistory();
            }, 1000);
          }
        });
      },
      // 导出md文件
      convertArrayToMd(chatHistory) {
        let mdContent = "# 对话记录\n\n";
        chatHistory.historys.forEach((chat) => {
          mdContent += `Role: ${chat.question}\n\nZhiGong: ${chat.answer}\n\n`;
        });
        console.log(mdContent, "");
        const blob = new Blob([mdContent], { type: "text/plain;charset=utf-8" });
        let time = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDay()}_${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`;
        // FileSaver.saveAs(blob, `ZhiGong${time}.md`);
        // this.$Message.success("导出成功")
      },
      // 获取title
      getParamValue(data, type) {
        switch (type) {
          case "title":
            if (data.historys.length > 0) return data.historys[0]["question"];
            else return "新的对话";
          case "time":
            if (data.historys.length > 0) return this.protoTime(data.historys[0]["time"]);
            else return this.protoTime(new Date().valueOf());
          case "pice":
            if (data.historys) return data.historys.length;
            else return 0;
          default:
            break;
        }
      },
      // 查询历史记录
      getGptHistory() {
        let params = {
          order: "desc",
          pageNumber: this.page,
          pageSize: this.pageSize,
          classification: 1,
        };
        getGptHistory2(params).then((res) => {
          if (res.success) {
            if (res.result.content !== undefined && res.result.content.length >= 20) {
              this.page = this.page + 1;
              this.hasMore = true;
            } else {
              this.hasMore = false;
            }
            let array = [];
            res.result.content.forEach((item) => {
              array.push({
                id: item.id,
                historys: JSON.parse(item.historys),
              });
            });
            this.historyList = this.historyList.concat(array);
          }
        });
      },
      // 时间戳转 标准时间
      protoTime(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        const hours = ("0" + date.getHours()).slice(-2);
        const minutes = ("0" + date.getMinutes()).slice(-2);
        const seconds = ("0" + date.getSeconds()).slice(-2);

        const standardTime = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
        return standardTime;
      },
      handleScroll() {
        const container = this.$refs.scrollContainer;
        const scrollTop = container.scrollTop;
        const containerHeight = container.clientHeight;
        const scrollHeight = container.scrollHeight;
        // 判断是否滚动到底部
        if (scrollTop + containerHeight >= scrollHeight) {
          this.loadMore();
        }
      },
      loadMore() {
        if (this.hasMore) {
          this.getGptHistory();
        }
      },
      openInfoModal(e) {
        this.infoModalPositionX = -window.innerWidth / 2 + 250;
        this.infoModalPositionY = e.y - 50;
        setTimeout(() => {
          this.infoModal = true;
          this.deleteModal = false;
        }, 100);
      },
      // 退出登录
      existAccount() {
        let userInfo = Cookies.get("userInfo");
        checkOutToken(`${JSON.parse(userInfo).accessToken}/${getStore("cur")}`).then((response) => {
          Cookies.remove("userInfo");
          Cookies.set("accessToken", "");
          setStore("vip", "");
          setStore("uid", "");
          setStore("accessToken", "");
          this.$router.push("/homeView_1");
        });
      },
      cancelAccount() {
        let userInfo = Cookies.get("userInfo");
        let params = new FormData();

        params.append("mobile", JSON.parse(userInfo).mobile);
       
        deleteAccount(params).then((response) => {
          if (response.code == 200) {
            this.$Message.info(response.message);
            Cookies.remove("userInfo");
            Cookies.set("accessToken", "");
            setStore("vip", "");
            setStore("uid", "");
            setStore("accessToken", "");
            this.$router.push("/homeView_1");
          }
        });
      },
      getUserINfo() {
        let userInfo = Cookies.get("userInfo");
        if (userInfo) {
          let user = JSON.parse(userInfo);
          if (user.mobile) {
            this.userMobile = user.mobile.substring(0, 3) + "****" + user.mobile.substring(7, 11);
          } else {
            this.userMobile = "当前用户未绑定手机号";
          }
        }
      },
    },
    mounted() {
      this.getGptHistory();
      this.getUserINfo();
    },
  };
</script>

<style lang="less">
  .chat_history_containner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // overflow: hidden;
    .chat_history_box {
      min-height: 100px;
      background-color: rgba(255, 255, 255, 0.4);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 5px;
      border-radius: 15px;
      border: 1px solid rgba(206, 206, 223, 1);
      .chat_history_list_title {
        color: rgba(106, 97, 173, 1);
        font-size: 20px;
        font-weight: bolder;
        text-align: center;
      }
    }
    .chat_history_list {
      height: 100%;
      overflow-y: auto;
    }
    .chat_history_item {
      padding: 10px;
      margin: 5px 0;
      border-radius: 10px;
      // height: 70px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: relative;
      cursor: pointer;

      .chat_history_item_title {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        img {
          width: 14px;
        }
      }
      p {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
      }
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .deleteHistory {
        // border-radius: 50% 50%;
        background-color: #fff;
        position: absolute;
        right: 10px;
        // bottom: 20px;
        width: 20px;
        // height: 20px;
        display: none;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .history_delete_box {
        width: 100px;
        height: 20px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 10px;
        position: absolute;
        right: -80px;
        z-index: 9999;
      }
      .exportHistory {
        border-radius: 50% 50%;
        background-color: #fff;
        position: absolute;
        right: 30px;
        // bottom: 10px;
        width: 20px;
        display: none;
        img {
          // position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }
    .chat_history_item:hover {
      padding-right: 50px;
      .deleteHistory {
        display: block;
      }
      .exportHistory {
        display: block;
      }
      background-color: rgba(255, 255, 255, 0.9);
    }
    .chat_history_item_selected {
      // border: 2px solid #4773ee;
      background-color: rgba(255, 255, 255, 0.9);
    }
    .chat_history_list_header {
      height: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .chat_history_list_footer {
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
    .newChatBtn {
      width: 45%;
      min-height: 40px;
      margin: 3px;
      font-size: 16px;
      color: #fff;
      background-color: #2b76bc;
      border: 0px;
      // border: 1px solid rgba(187, 187, 187, 1);
      font-weight: bold;
      border-radius: 10px;
      // line-height: 30px;
      text-align: center;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
    }
    .newChatBtn:hover {
      border: 0px;
      background-color: #2b76bc;
      color: #fff;
    }
    .chat_history_logo {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100px;
      img {
        width: 60%;
      }
    }
  }
  .vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vertical-center-modal .ivu-modal {
    top: 0;
  }
  .favicon {
    margin-right: 10px;
  }
</style>
